import { FC, useEffect, useState } from "react";
import FeeSelector from "../../../components/Reusable/FeeSelector";
import { FeeObject, FeeRateOptions, Offer } from "../../../types";
import StepButtons from "../../components/StepButtons";
import { useModalStore } from "../..";
import NFTInfo from "../../../components/Reusable/NFTInfo/NFTInfo";
import InscribeButton from "../../../components/InscribeButton";
import Loading from "../../../components/Reusable/Loading";
import useGetEscrowBrc20Balance from "../../../hooks/api/useGetEscrowBrc20Balance";

interface ClaimOrdinalModalProps {
  request: Offer;
  onSubmit: Function;
  setLoadingRow: Function;
  handleUnlock: Function;
  claimType: string;
}

const ClaimOrdinalModal: FC<ClaimOrdinalModalProps> = ({
  request,
  onSubmit,
  setLoadingRow,
  handleUnlock,
  claimType,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedFeeRate, setSelectedFeeRate] = useState<FeeObject>({
    label: FeeRateOptions.Medium,
    value: 0,
  } as FeeObject);
  const { close } = useModalStore();

  const submit = async () => {
    setLoading(true);
    try {
      await handleUnlock(
        request,
        selectedFeeRate?.value,
        claimType,
        async () => {
          await onSubmit?.();
          setLoadingRow(null);
          setLoading(false);
        },
        () => setLoadingRow(null),
      );
    } catch (e) {
      console.log(e);
      setLoading(false);
    } finally {
      close();
    }
  };

  const {
    isLoading: isLoadingBalance,
    error: errorBalance,
    escrowAddress,
    needsTransfer,
    fetchEscrowBalance,
  } = useGetEscrowBrc20Balance({
    id: request.id,
  });

  useEffect(() => {
    if (request.collateralType === "BRC20") {
      fetchEscrowBalance();
    }
  }, [request.collateralType]);

  return (
    <div className="sm:w-[85vw] w-[100vw] max-w-[550px] bg-gray-800 sm:p-7 p-5 rounded-2xl flex flex-col sm:mx-3 mx-0 border border-white/20 gap-6">
      <p className="sm:text-2xl text-xl font-black text-gray-50">
        Claim
        {request.collection.collateralType === "BRC20"
          ? " BRC20 Tokens"
          : " Ordinal"}
      </p>
      <NFTInfo
        img={request.collection.imageURI}
        collectionName={request.collection.name}
        NFTindex={request.number}
      />
      {loading || (request.collateralType === "BRC20" && isLoadingBalance) ? (
        <Loading />
      ) : errorBalance ? (
        <p className="text-white text-center w-full font-bold">
          Error fetching escrow balance.
        </p>
      ) : needsTransfer ? (
        <InscribeButton request={request} address={escrowAddress} />
      ) : (
        <FeeSelector
          setSelectedFeeRate={setSelectedFeeRate}
          selectedFeeRate={selectedFeeRate}
        />
      )}
      <div className="mt-1">
        <StepButtons
          leftText="Cancel"
          rightText={"Unlock"}
          rightOnClick={submit}
          isSubmitting={loading}
          leftOnClick={() => {
            setLoadingRow(null);
            close();
          }}
          disabled={
            selectedFeeRate.value === 0 ||
            (request.collateralType === "BRC20" &&
              (!!errorBalance || needsTransfer))
          }
        />
      </div>
    </div>
  );
};

export default ClaimOrdinalModal;
