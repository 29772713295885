import { useEffect, useState } from "react";
import { Notification, SortType } from "../../types";
import { useUserStore } from "../../store";
import { useGetMyOffers } from "../liquidium/useGetMyOffers";
import { checkExpiryAndDaysRemaining } from "../../utils";

const useMyNotifications = (): Notification[] => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const ordAddress = useUserStore((state: any) => state.ordAddress);
  const { pending, borrowings, lendings, fetchMyOffers } = useGetMyOffers();
  const STATUSES = ["active", "activating", "repaying"];

  useEffect(() => {
    fetchMyOffers({
      take: 20,
      sortType: SortType.CreatedAt_DESC,
      userId: ordAddress,
    });
  }, [fetchMyOffers, ordAddress]);

  useEffect(() => {
    let tempNotifications: Notification[] = [];

    [...pending, ...borrowings, ...lendings].forEach((loan: any) => {
      const { daysRemaining, isExpired } = checkExpiryAndDaysRemaining(
        loan.endDate,
      );

      const assetMeta = loan.collateral.assets.find(
        (asset: any) => asset.type === "btc.utxo",
      )?.content?.meta;
      if (!assetMeta) return;

      let title = "";
      let message = "";
      if (loan.status === "pending" && loan.lender.id === ordAddress) {
        title = `#${assetMeta.number}: AWAITING YOUR SIGNATURE`;
        message = "Sign the transaction to activate the loan.";
      } else if (isExpired && STATUSES.filter((item => item !== "repaying")).includes(loan.status)) {
        title = `#${assetMeta.number}: ${
          loan.borrower.id === ordAddress ? "BORROWING" : "LOAN"
        } EXPIRED`;
        message = `Loan expired! ${
          loan.borrower.id === ordAddress ? "Ordinal lost." : "Claim Ordinal!"
        }`;
      } else if (daysRemaining <= 4 && STATUSES.includes(loan.status)) {
        title = `#${assetMeta.number}: ${
          loan.borrower.id === ordAddress ? "BORROWING" : "LENDING"
        } EXPIRES IN ${
          daysRemaining > 0 ? daysRemaining.toFixed(0) : "< 1"
        } DAYS`;
        message = `${
          loan.borrower.id === ordAddress ? "Borrowing" : "Loan"
        } expires soon!`;
      } else if (loan.status === "paid" && loan.borrower.id === ordAddress) {
        title = `#${assetMeta.number}: AWAITING UNLOCK.`;
        message = "Loan is paid off, awaiting unlock.";
      }

      if (!title || !message) return;
      tempNotifications.push({
        id: assetMeta.id,
        content_type: assetMeta.content_type,
        title,
        message,
      });
    });

    if (JSON.stringify(tempNotifications) !== JSON.stringify(notifications)) {
      setNotifications(tempNotifications);
    }
  }, [ordAddress, notifications, pending, borrowings, lendings]);

  return notifications;
};

export default useMyNotifications;
