import { useState, useEffect } from "react";
import axios from "axios";
import Config from "../../../config";

const useFees = (): {
  fees: { low: number; medium: number; high: number };
  loading: boolean;
  error: any;
} => {
  const [fees, setFees] = useState({ low: 0, medium: 0, high: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFees = async () => {
      try {
        const response = await axios.get(
          `https://mempool.space/${
            Config.NetworkName === "mainnet" ? "" : "testnet/"
          }api/v1/fees/recommended`
        );
        let { fastestFee, halfHourFee, hourFee } = response.data;

        if (hourFee === halfHourFee) {
          halfHourFee = hourFee + 2;
        }
        if (halfHourFee >= fastestFee) {
          fastestFee = halfHourFee + 2;
        }

        setFees({
          low: hourFee,
          medium: halfHourFee,
          high: fastestFee,
        });
        setLoading(false);
      } catch (err) {
        // @ts-ignore
        setError(err);
        setLoading(false);
      }
    };

    fetchFees();
  }, []);

  return { fees, loading, error };
};

export default useFees;
