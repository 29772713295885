import { useEffect } from "react";
import { create } from "zustand";
import styles from "./Notification.module.css";

type NotificationState = {
  content: () => JSX.Element;
  close: () => void;
  isVisible: boolean;
};

export const useNotificationStore = create<NotificationState>((set) => ({
  content: () => <></>,
  isVisible: false,
  close: () => set({ content: () => <></>, isVisible: false }),
}));

export const setNotificationState = (content: () => JSX.Element) => {
  useNotificationStore.setState({
    content,
    isVisible: true,
  });
};

const Notification = () => {
  const { content, close, isVisible } = useNotificationStore();

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => close(), 6000);
    }
  }, [isVisible, close]);

  if (!isVisible) return <></>;
  return (
    <div
      className={
        styles.container +
        "  blurBackgroundXL border border-white/20 rounded-xl"
      }
    >
      <div className={styles.content}>{content()}</div>
    </div>
  );
};

export default Notification;
