import { FC } from "react";
import {
  ActiveBorrowingLendingsStats,
  BorrowingStats,
  LendingStats,
  StatItem,
} from "../../../types";
import btcSymbol from "../../../assets/assets/btcSymbol.svg";
import Tooltip from "../Tooltip/Tooltip";

interface StatsSectionProps {
  statItems:
    | BorrowingStats
    | LendingStats
    | ActiveBorrowingLendingsStats
    | undefined;
  size: "small" | "large";
  cardMinWidth?: number;
  isLoading?: boolean;
}

const StatsSection: FC<StatsSectionProps> = ({
  statItems,
  size,
  cardMinWidth,
  isLoading,
}) => {
  const padding = size === "small" ? "p-3" : "p-3 sm:p-4";

  const getGridTemplateColumns = (minWidth: number): string => {
    return `repeat(auto-fit, minmax(${minWidth}px, 1fr))`;
  };

  const gridTemplateColumns = getGridTemplateColumns(
    cardMinWidth ? cardMinWidth : 150
  );

  if (!statItems) return null;

  function isStatItem(obj: any): obj is StatItem {
    return typeof obj === "object" && "name" in obj && "value" in obj;
  }

  const statsArray: StatItem[] = [];
  Object.entries(statItems).forEach(([key, value]) => {
    if (isStatItem(value)) {
      statsArray.push(value);
    }
  });

  return (
    <div
      className="w-full"
      style={{ display: "grid", gridGap: "10px", gridTemplateColumns }}
    >
      {statsArray.map((item) => (
        <Tooltip key={item.name} content={item.toolTip}>
          <div
            key={item.name}
            className={`${padding} rounded-xl border border-white/20`}
          >
            <p className="text-gray-400 font-medium sm:text-sm text-xs">
              {item.name}
            </p>
            <div className="flex font-bold text-sm sm:text-base mt-1">
              {item.btcDenomination && (
                <img
                  src={btcSymbol} // Make sure this variable is imported or defined in this file
                  alt="BTC Symbol"
                  className="mr-1 h-5 sm:h-6"
                />
              )}
              <p className="text-white">{item.value}</p>
              <p className={`text-gray-400 ${item.duration ? "ml-1" : ""}`}>
                {item.percent ? "%" : item.duration ? "Days" : ""}
              </p>
            </div>
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default StatsSection;
