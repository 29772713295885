import { httpsCallable } from 'firebase/functions'
import { useState } from 'react'
import { functions } from '../../../firebaseConfig'

const useGetEscrowBrc20Balance = ({
  id,
}:{
  id: string
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [availableBalance, setAvailableBalance] = useState<number>(0)
  const [transferBalance, setTransferBalance] = useState<number>(0)
  const [escrowAddress, setEscrowAddress] = useState<string>("");
  const [needsTransfer, setNeedsTransfer] = useState(false);
  const getEscrowBrc20Balance = httpsCallable(functions, "getEscrowBrc20Balance");

  const fetchEscrowBalance = async () => {
    setIsLoading(true)
    try {
      const response: any = await getEscrowBrc20Balance({ loanId: id })
      setAvailableBalance(response.data.availableBalance);
      setTransferBalance(response.data.transferBalance);
      setEscrowAddress(response.data.escrowAddress);
      setNeedsTransfer(response.data.needsTransfer);
      setError(null)
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      } else {
        setError('An unexpected error occurred.')
      }
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    error,
    availableBalance,
    transferBalance,
    escrowAddress,
    needsTransfer,
    fetchEscrowBalance,
  }

}

export default useGetEscrowBrc20Balance