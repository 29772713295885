import { FC } from "react";
import { Offer } from "../../../../types";
import TitleWithSearchSort from "../../../../components/Reusable/TitleWithSearchSort/TitleWithSearchSort";
import Loading from "../../../../components/Reusable/Loading";
import RowCard from "../../../../components/Reusable/PortfolioRowCard/PortfolioRowCard";
interface PendingSignatureProps {
  items: Offer[];
  loading?: boolean;
  onCancel: Function;
  onApprove: Function;
  loadingRowId: number | null;
}

const PendingSignature: FC<PendingSignatureProps> = ({
  loading,
  items,
  onCancel,
  onApprove,
  loadingRowId,
}) => {
  // const [searchInput, setSearchInput] = useState<string>("");

  return (
    <div className="">
      <TitleWithSearchSort
        title="Pending Signature"
        onSortToggle={() => {}}
        ascending={true}
        // onSearchInput={setSearchInput}
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          {items.length === 0 ? (
            <p className="text-xl text-gray-400 font-bold w-full text-center pt-4">
              No pending loan offers
            </p>
          ) : (
            <div className="grid grid-flow-row gap-[10px] sm:gap-3 ">
              {items.map((item, i) => (
                <RowCard
                  offer={item}
                  key={"pendingSignature" + i}
                  type={item.status === "pending" ? "pending" : "rebuilt"}
                  onAcceptPendingSignatureClick={onApprove}
                  onClick={onCancel}
                  isLoading={loadingRowId === i}
                  index={i}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PendingSignature;
