import React, { FC, useCallback, useEffect, useState } from "react";
import Profile from "../Profile/Profile";
import Notifications from "../Notifications/Notifications";
import EarlyAccessInvites from "../EarlyAccessInvites/EarlyAccessInvites";
import Deactivate from "../Deactivate/Deactivate";
import LoadingBar from "../../../components/Reusable/LoadingBar/Loading";
import { useUserStore } from "../../../store";
import { useGetUserData } from "../../../hooks/liquidium/useGetUserData";
import LoanHistory from "../LoanHistory/LoanHistory";
import { Offer } from "../../../types";

interface SettingsSectionProps {
  page: string;
  loadingHistoricalLoans: boolean;
  historicalLoans: Offer[];
}

//Displays the selected settings page
const SettingsSection: FC<SettingsSectionProps> = ({
  page,
  loadingHistoricalLoans,
  historicalLoans,
}) => {
  const [selectedPageBody, setSelectedPageBody] = useState<{
    subHeading: string;
    html: React.ReactNode;
  }>({ subHeading: "", html: <></> });
  const [fetchingData, setFetchingData] = useState(true);
  const ordAddress = useUserStore((state) => state.ordAddress);
  const { fetchUserData, loadingUserData, userData, errorUserData } =
    useGetUserData();

  const refreshUserData = useCallback(() => {
    fetchUserData(ordAddress);
  }, [fetchUserData, ordAddress]);

  useEffect(() => {
    if (!loadingUserData) {
      setFetchingData(false);
    } else if (!userData) {
      // Only trigger when userData is not populated yet
      setFetchingData(true);
    }
  }, [userData, loadingUserData]);

  useEffect(() => {
    fetchUserData(ordAddress);
  }, [fetchUserData, ordAddress]);

  //Set subheading and body based on the page
  useEffect(() => {
    switch (page) {
      case "Profile":
        setSelectedPageBody({
          subHeading: "Update your profile information",
          html: <Profile userData={userData} />,
        });
        break;
      case "Notifications":
        setSelectedPageBody({
          subHeading:
            "Set your preferences to receive essential updates about your loans",
          html: (
            <Notifications
              userData={userData}
              refreshUserData={refreshUserData}
            />
          ),
        });
        break;
      case "Early Access Invitations":
        setSelectedPageBody({
          subHeading: "Invite people to let them know about Liquidium",
          html: (
            <EarlyAccessInvites
              userData={userData}
              refreshUserData={refreshUserData}
            />
          ),
        });
        break;
      case "Deactivate Account":
        setSelectedPageBody({
          subHeading: "Deactivate your account and delete your profile data",
          html: <Deactivate />,
        });
        break;
      case "Loan History":
        setSelectedPageBody({
          subHeading: "View your loan history",
          html: (
            <LoanHistory
              loading={loadingHistoricalLoans}
              historicalLoans={historicalLoans}
            />
          ),
        });
        break;
    }
  }, [page, userData, loadingHistoricalLoans, historicalLoans]);

  return (
    <div className="p-2 lg:min-w-[516px] w-full">
      <p className="text-white sm:text-xl text-lg font-extrabold">{page}</p>
      <p className="text-xs sm:text-sm font-semibold text-gray-400/80">
        {selectedPageBody.subHeading}
      </p>
      <div className="h-[1px] bg-white/20 sm:my-6 my-5 w-full"></div>
      {fetchingData && <LoadingBar />}
      <div className={`${fetchingData ? "hidden" : "block"} w-full`}>
        {selectedPageBody.html}
      </div>
    </div>
  );
};

export default SettingsSection;
