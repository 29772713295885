import { useState } from "react";

const InscriptionContent = ({ asset }: { asset: any }) => {
  const [error, setError] = useState<string | null>(null);
  const [useIframe, setUseIframe] = useState(false);

  const handleError = () => {
    setUseIframe(true);
  };

  return (
    <div className={` w-full h-full relative rounded-lg overflow-clip`}>
      {useIframe ? (
        <iframe
          title="content"
          src={
            asset?.collectionSymbol === "runestone"
              ? asset.collection.imageURI
              : "https://ord-mirror.magiceden.dev/content/" + asset.id
          }
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          loading="lazy"
          sandbox="allow-scripts allow-same-origin"
        />
      ) : (
        <img
          onLoad={() => {}}
          onError={handleError}
          src={"https://ord-mirror.magiceden.dev/content/" + asset.id}
          alt="content"
          className="w-full h-full object-cover rounded-lg"
          loading="lazy"
        />
      )}
    </div>
  );
};

export default InscriptionContent;
