import React, { useState } from "react";

interface NumberIncrementerProps {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  min: number;
  max: number;
}

const NumberIncrementer: React.FC<NumberIncrementerProps> = ({
  value,
  setValue,
  min,
  max,
}) => {
  const [bounce, setBounce] = useState(false);

  const increment = () => {
    if (value < max) {
      setValue(value + 1);
      setBounce(true);
      setTimeout(() => setBounce(false), 120); // adjust delay as needed
    }
  };

  const decrement = () => {
    if (value > min) {
      setValue(value - 1);
      setBounce(true);
      setTimeout(() => setBounce(false), 120); // adjust delay as needed
    }
  };

  return (
    <div className="flex items-center justify-between bg-gray-600 border-2 border-white/30 p-2 rounded-xl font-extrabold hover:border-white/50 transition-colors">
      <button
        onClick={decrement}
        className={`transform transition-transform duration-200 ease-in-out bg-white/80  hover:bg-white p-2 px-4 rounded-md transition-all  ${
          value === min && "bg-white/40  hover:bg-white/40"
        }`}
        style={{
          transform: value === min ? "scale(0.9)" : "scale(1.1)",
        }}
      >
        -
      </button>
      <span
        className={`sm:text-lg text-md text-gray-50 ${
          bounce ? "animate-ping" : ""
        }`}
      >
        {value}
      </span>
      <button
        onClick={increment}
        className={`transform transition-transform duration-200 ease-in-out bg-white/80 hover:bg-white p-2 px-4 rounded-md transition-all  ${
          value === max && "bg-white/40 hover:bg-white/40"
        }`}
        style={{ transform: value === max ? "scale(0.9)" : "scale(1.1)" }}
      >
        +
      </button>
    </div>
  );
};

export default NumberIncrementer;
