import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import copyIcon from "../../../assets/icons/copy_icon.svg";
import Text from "../../../components/Reusable/Text";
import {
  faCircleCheck,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import styles from "./GeneralNotification.module.css";

type Variants = "general" | "success" | "error";

const GeneralNotification = ({
  text,
  link,
  variant = "general",
  sameTab = false,
}: {
  text: string | JSX.Element;
  link?: string;
  variant?: Variants;
  sameTab?: boolean;
}) => {
  const centerTextStyles: React.CSSProperties = {
    textAlign: "left",
    fontSize: "0.8rem",
    marginTop: "0.5rem",
    textDecoration: "underline",
    fontWeight: "bold",
  };

  return link ? (
    sameTab ? (
      <a href={link}>
        <p className="w-full font-semibold text-zinc-100 sm:text-base text-sm flex flex-col">
          <div className="flex justify-center">
            <img src={copyIcon} className={styles.icon} alt="" />
          </div>
          {text}
          <div style={centerTextStyles}>
            Click for more
          </div>
        </p>
      </a>
    ) : (
      <a href={link} target="_blank" rel="noopener noreferrer">
        <p className="w-full font-semibold text-zinc-100 sm:text-base text-sm flex flex-col">
          <div className="flex justify-center">
            <img src={copyIcon} className={styles.icon} alt="" />
          </div>
          {text}
          <div style={centerTextStyles}>
            Click for more
          </div>
        </p>
      </a>
    )
  ) : (
    <p className="w-full font-semibold text-zinc-100 sm:text-base text-sm flex flex-col">
      <div className="flex justify-center">
        {variant === "error" || variant === "success" ? (
          <FontAwesomeIcon
            icon={
              variant === "error"
                ? (faExclamationCircle as IconProp)
                : (faCircleCheck as IconProp)
            }
            className={styles.icon}
          />
        ) : (
          <img src={copyIcon} className={styles.icon} alt="" />
        )}
      </div>
      {text}
    </p>
  );
};

export default GeneralNotification;
