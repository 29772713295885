import React, { FC, useEffect, useState } from "react";
import { StatusTagType } from "../../../types";
import Tooltip from "../Tooltip/Tooltip";

interface StatusTagProps {
  type: StatusTagType;
  small?: boolean;
}

const StatusTag: FC<StatusTagProps> = ({ type, small }) => {
  const [styles, setStyles] = useState<string>("");
  const [tooltip, setTooltip] = useState<string>("");

  useEffect(() => {
    switch (type) {
      case "Active":
        setStyles("bg-green-800 ");
        break;
      case "Disabled":
        setStyles("bg-red-700");
        break;
      case "Not Activated":
        setStyles("bg-yellow-700");
        break;
      case "Bot Not Linked":
        setStyles("bg-yellow-700");
        setTooltip(
          "Open and activate the @Liquidium_Loan_Updates_bot to link the bot to your telegram username"
        );
        break;
      case "Used":
        setStyles("bg-orange-700");
        break;
      case "Available":
        setStyles("bg-green-800");
        break;
    }
  }, [type]);

  return (
    <Tooltip content={tooltip}>
      <div
        className={`${styles} ${
          small
            ? " sm:text-[11px] text-[10px] items-center flex px-2 h-[16px] mx-2 translate-y-[1px] "
            : " text-xs sm:text-sm px-2 mx-3 "
        } font-semibold text-white/80 rounded-2xl  border-white/20 border `}
      >
        {type}
      </div>
    </Tooltip>
  );
};

export default StatusTag;
