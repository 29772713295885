import { FC, useState, ReactNode } from "react";
import infoIcon from "../../assets/icons/info_icon_filled.svg";

interface ISectionTitle {
  title: string;
  infoText?: ReactNode;
  learnMore?: string;
  alwaysShowInfo?: boolean;
}

const SectionTitle: FC<ISectionTitle> = ({
  title,
  infoText,
  learnMore,
  alwaysShowInfo,
}) => {
  const [showTitleSubText, setShowTitleSubText] = useState<boolean>(false);

  return (
    <div className="sm:mt-0 mt-4">
      <p className="text-gray-50 font-extrabold sm:text-2xl text-xl flex items-center gap-2">
        {title}
        {infoText && !alwaysShowInfo && (
          <button
            className="bg-gray-600 border border-white/20 text-xs  flex justify-center items-center pl-1 pr-2 py-[2px] font-extrabold rounded-2xl gap-1 hover:bg-opacity-80 transition-color -mb-[2px]"
            onClick={() => setShowTitleSubText(!showTitleSubText)}
          >
            <img alt="" src={infoIcon} className="w-[16px] h-[16px]"></img>
            Info
          </button>
        )}
      </p>
      {(showTitleSubText || alwaysShowInfo) && (
        <>
          {infoText}
          {learnMore && (
            <a
              className="sm:text-sm text-xs text-gray-400/60 hover:text-gray-300 cursor-pointer hover:underline font-extrabold"
              href={learnMore}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More...
            </a>
          )}
        </>
      )}
    </div>
  );
};

export default SectionTitle;
