import React, { useState, useEffect, useRef } from "react";

interface TooltipProps {
  content?: string;
}

const Tooltip: React.FC<TooltipProps> = ({ children, content }) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });

  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleMouseOver = (e: React.MouseEvent) => {
    setCursorPos({ x: e.clientX, y: e.clientY });
    hoverTimeout.current = setTimeout(() => {
      setVisible(true);
    }, 500);
  };

  const handleMouseOut = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    setVisible(false);
  };

  useEffect(() => {
    if (visible && tooltipRef.current) {
      const tooltipWidth = tooltipRef.current.offsetWidth;
      const tooltipHeight = tooltipRef.current.offsetHeight;

      let x = cursorPos.x - tooltipWidth - 10;
      let y = cursorPos.y + 10;

      // Check if tooltip would render outside the boundary of the window
      if (x < 0) {
        x = cursorPos.x + 10; // Move it to the right of the cursor if it would go off the left side
      }
      if (x + tooltipWidth > window.innerWidth) {
        x = window.innerWidth - tooltipWidth - 10;
      }
      if (y + tooltipHeight > window.innerHeight) {
        y = cursorPos.y - tooltipHeight - 10;
      }

      setPosition({
        x: x,
        y: y,
      });
    }
  }, [visible, cursorPos]);

  const clone = React.cloneElement(children as React.ReactElement, {
    onMouseOver: handleMouseOver,
    onMouseOut: handleMouseOut,
  });

  return (
    <>
      {clone}
      {visible && content && (
        <div
          ref={tooltipRef}
          style={{
            top: `${position.y}px`,
            left: `${position.x}px`,
            position: "absolute",
            zIndex: 1000,
            opacity: visible ? 1 : 0,
            transition: "opacity 1s ease-in-out",
          }}
          className="bg-gray-700 text-white text-xs font-semibold p-2 rounded shadow-md blurBackground mr-5 w-auto max-w-[300px] text-left"
        >
          {content}
        </div>
      )}
    </>
  );
};

export default Tooltip;
