import { Tooltip } from "@material-ui/core";
import { FC } from "react";

interface IPercentageBar {
  startDate: any;
  endDate: any;
}
const PercentageBar: FC<IPercentageBar> = ({ startDate, endDate }) => {
  // Convert the dates to milliseconds
  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();
  const now = new Date().getTime();

  // Calculate the percentage of time that remains
  const duration = end - start;
  const timePassed = now - start;
  const percentage = 100 - (timePassed / duration) * 100;

  // Define the start and end colors
  const startColor = [22, 221, 25];
  const endColor = [220, 38, 38];

  // Calculate the remaining time in milliseconds
  const remainingTime = duration - timePassed;

  // Convert the remaining time to hours and minutes
  const remainingHours = Math.floor(remainingTime / (1000 * 60 * 60));
  const remainingMinutes = Math.floor(
    (remainingTime % (1000 * 60 * 60)) / (1000 * 60),
  );

  // Compute the color using a grad between startColor and endColor
  const color = startColor.map(
    (c, i) => c + ((100 - percentage) * (endColor[i] - c)) / 100,
  );

  const bgColor = `rgba(${color[0]}, ${color[1]}, ${color[2]}, 1)`;

  return (
    <Tooltip title={`Remaining time: ${percentage.toFixed(2)}%`}>
      <div className="w-full h-5 bg-gray-500 rounded">
        <div
          className="h-full rounded"
          style={{ width: `${percentage}%`, backgroundColor: bgColor }}
        />
      </div>
    </Tooltip>
  );
};

export default PercentageBar;
