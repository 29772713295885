import { FC, useState } from "react";
import InputSubmit from "../../../components/Reusable/InputSubmit/InputSubmit";
import { setNotificationState } from "../../../notification";
import GeneralNotification from "../../../notification/Notifications/GeneralNotification";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebaseConfig";
import { useUserStore } from "../../../store";
import { UserData } from "../../../types";

interface ProfileProps {
  userData: UserData | null;
}

const Profile: FC<ProfileProps> = ({ userData }) => {
  const [name, setName] = useState("");
  const [isNameSubmitting, setIsNameSubmitting] = useState(false);
  const [isNameSubmitted, setIsNameSubmitted] = useState(false);
  const ordAddress = useUserStore((state) => state.ordAddress);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsNameSubmitting(true);
    setIsNameSubmitted(false);
    try {
      const updateUser = httpsCallable(functions, "updateUser");
      const response: any = await updateUser({
        id: ordAddress,
        name: name,
        isEmailNotif: true,
        isWhiteList: true,
      });
      if (response.data.success) {
        setNotificationState(() => (
          <GeneralNotification
            text={"Name successfully updated."}
            variant="success"
          />
        ));
        setIsNameSubmitting(false);
        setIsNameSubmitted(true);
      }
    } catch {
      setNotificationState(() => (
        <GeneralNotification text={"Error adding name."} variant="error" />
      ));
      setIsNameSubmitting(false);
      setIsNameSubmitted(false);
    } finally {
      setIsNameSubmitting(false);
    }
  };

  return (
    <div className="w-full lg:w-[500px]">
      <InputSubmit
        key={"1name"}
        id={1}
        title={"Name"}
        value={name}
        onEmailSubmit={handleSubmit}
        placeholder={userData?.name ? userData.name : "Name"}
        type={"text"}
        isSubmitted={isNameSubmitted}
        isSubmitting={isNameSubmitting}
        handleSetInput={setName}
        submittedMessage={"Successfully updated"}
        description="This is the name that will be displayed on your profile and in communications"
      />
    </div>
  );
};

export default Profile;
