import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faListDots } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUserStore } from "../../../store";
import Text from "../Text";
import styles from "./NoContentMessage.module.css";

const NoContentMessage = ({
  contentName,
  small,
  icon,
}: {
  contentName: string | JSX.Element;
  small?: boolean;
  icon?: boolean;
}) => {
  const theme = useUserStore((state) => state.theme);
  return (
    <div className={styles.component}>
      {icon && (
        <FontAwesomeIcon
          icon={faListDots as IconProp}
          id={styles[`${theme}Icon`]}
        />
      )}

      <p className="sm:text-xl text-lg text-gray-400 font-extrabold">
        {" "}
        No {contentName}
      </p>
    </div>
  );
};

export default NoContentMessage;
