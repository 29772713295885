import { FC, useEffect } from "react";
import { useGetUserAnalytics } from "../../../../hooks/liquidium/Analytics/useGetUserAnalytics";
import { useUserStore } from "../../../../store";
import ValueCard from "../../../Analytics/components/ValueCard/ValueCard";
import useBtcPrice from "../../../../hooks/api/useBtcPrice";
import leaderboardIcon from "../../../../assets/icons/leaderboard_icon.svg";
import { useNavigate } from "react-router";

const UserStats: FC = () => {
  const { singleValues, fetchUserAnalytics, loading, error } =
    useGetUserAnalytics();
  const ordAddress = useUserStore((state) => state.ordAddress);
  const { btcPrice, isLoading } = useBtcPrice();
  const navigate = useNavigate();

  const valueCardData = [
    {
      title: "Interest Earned",
      value: singleValues?.totalInterestEarned,
      denomination: "₿",
      tooltip: "The net interest earned from your completed loans",
    },
    {
      title: "Completed Loans",
      value: singleValues?.completedLoansCount,
      tooltip: "Number of completed loans, repaid or liquidated",
    },
    {
      title: "Active Loans",
      value: singleValues?.activeLoansCount,
      tooltip: "Number of active lendings or borrowings",
    },
    {
      title: "Active Borrowings Value",
      value: singleValues?.activeBorrowingLoansValue,
      tooltip: " BTC value of all your active borrowings",
      denomination: "₿",
    },
    {
      title: "Active Lendings Value",
      value: singleValues?.activeLendingLoansValue,
      tooltip: " BTC value of all your active lendings",
      denomination: "₿",
    },
  ];

  useEffect(() => {
    if (ordAddress) {
      fetchUserAnalytics(ordAddress);
      return;
    }
  }, [ordAddress]);

  return (
    <div>
      <div
        className={`p-3 rounded-lg border border-white/20 blurBackgroundXL mt-7 ${
          loading ? "animate-pulse" : ""
        }`}
      >
        <div className="flex justify-between w-full">
          <p className="font-semibold text-lg sm:text-xl text-zinc-100">
            Your Score
          </p>
          <p
            className=" gap-1  hover:text-zinc-50  font-medium sm:text-sm text-xs text-zinc-400 transition-all flex items-center  hover:bg-zinc-900  cursor-pointer"
            onClick={() => navigate("/leaderboard")}
          >
            <img
              src={leaderboardIcon}
              alt="leaderboardIcon"
              className="w-[18px] opacity-70"
            ></img>
            View Leaderboard
          </p>
        </div>
        <p className="font-extrabold text-3xl sm:text-4xl text-orange-400 mt-1">
          {loading ? (
            <>--</>
          ) : singleValues?.totalPoints ? (
            singleValues?.totalPoints
          ) : (
            "0"
          )}
        </p>
        <p className="font-medium sm:text-sm text-xs text-zinc-500 mt-1">
          Ranked #{singleValues?.rank ? singleValues.rank : "0"}/
          {singleValues?.totalLeaderboardEntries}
        </p>
      </div>
      <div
        className={`w-full flex ${
          loading ? "animate-pulse" : ""
        } mt-6  grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 gap-3`}
      >
        {valueCardData.map((card, index) => (
          <ValueCard
            key={index}
            title={card.title}
            value={
              loading
                ? "-"
                : card.value && card.value > 0
                ? card.value
                : "0"
            }
            denomination={card.denomination}
            secondValue={
              !isLoading && btcPrice && card.value && card.denomination
                ? "$" + (btcPrice * card.value).toFixed(2)
                : undefined
            }
            tooltip={card.tooltip}
          />
        ))}
      </div>
    </div>
  );
};

export default UserStats;
