import footerLogo from "../../assets/logo-text-right-new.svg";
import footerLogoBottom from "../../assets/logo-text-bottom-new.svg";
import discord from "../../assets/icons/discord.svg";
import twitter from "../../assets/icons/twitter.svg";

// TODO: add media kit link
const linkData = [
  {
    href: "https://liquidium.link/terms",
    text: "Terms of Service",
  },
  {
    href: "https://liquidium.link/privacy",
    text: "Privacy Policy",
  },
  {
    href: "https://liquidium.link/faq",
    text: "FAQ",
  },
];

const socialIcons = [
  { src: discord, alt: "Discord logo", link: "https://liquidium.link/discord" },
  {
    src: twitter,
    alt: "Twitter logo",
    link: "https://twitter.com/LiquidiumFi",
  },
];

const openInNewTab = (url: string) => {
  window.open(url, "_blank");
};

const Footer = () => (
  <div className="w-full sticky  top-full pb-4 mt-6  flex flex-col items-center ">
    <div className=" lg:flex grid grid-flow-row lg:justify-between justify-center w-full  lg:gap-0 gap-7 max-w-[1400px] ">
      <div className="lg:justify-start justify-center flex lg:w-[22%] w-auto">
        <img
          src={footerLogo}
          alt="Liquidium Footer Logo"
          className="w-44 lg:block hidden"
        />
        <img
          src={footerLogoBottom}
          alt="Liquidium Footer Logo"
          className="2xl:w-40 w-40 lg:w-40 lg:hidden block"
        />
      </div>
      <div className="grid grid-flow-row justify-center sm:grid-flow-col sm:gap-5 gap-3 items-center justify-self-center  lg:w-[56%] w-auto">
        {linkData.map((link, index) => (
          <div
            className="hover:opacity-80 text-center sm:text-left"
            key={index + "footerLink"}
          >
            <a
              className="text-gray-500 font-extrabold sm:text-base text-sm hover:text-gray-400"
              href={link.href}
              target="_blank"
            >
              {link.text}
            </a>
          </div>
        ))}
      </div>
      <div className="cursor-pointer grid grid-flow-col gap-5 items-center lg:justify-end justify-center mt-4 lg:mt-0 lg:w-[22%] w-auto">
        {socialIcons.map((icon, index) => (
          <img
            key={index + "social"}
            src={icon.src}
            alt={icon.alt}
            className="hover:opacity-80"
            onClick={() => openInNewTab(icon.link)}
          />
        ))}
      </div>
    </div>
    <p className="text-xs text-gray-500 font-medium w-full text-center lg:mt-5 mt-9">
      @2023 Liquidium (Fungus Inc.)
    </p>
  </div>
);

export default Footer;
