import { Typography } from "@material-ui/core";
import { useUserStore } from "../../../store";
import styles from "./Text.module.css";

type Variants =
  | "Body1"
  | "Body2"
  | "Title1"
  | "Title2"
  | "Subtitle"
  | "Footer1"
  | "Footer2";
type Color =
  | "Black"
  | "Grey"
  | "White"
  | "Red"
  | "Green"
  | "DarkGray"
  | "LightGray"
  | "MediumGray"
  | "OffWhite"
  | "FadedWhite";

const Text = ({
  variant,
  children,
  color,
  constantColor,
  href,
  style,
  onClick,
  className,
}: {
  variant: Variants;
  children: React.ReactNode;
  color: Color;
  constantColor?: boolean;
  href?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  className?: string;
}) => {
  const theme = useUserStore((state) => state.theme);

  if (variant === "Title1")
    return (
      <Typography
        component="div"
        style={style && style}
        variant="h5"
        className={`${styles.title1} ${className ? ` ${className}` : ""}`}
        id={styles[`${theme}Theme${color}Text`]}
        onClick={onClick}
      >
        {children}
      </Typography>
    );
  else if (variant === "Title2")
    return (
      <Typography
        component="div"
        style={style && style}
        variant="h4"
        className={`${styles.title2} ${className ? ` ${className}` : ""}`}
        id={styles[`${theme}Theme${color}Text`]}
        onClick={onClick}
      >
        {children}
      </Typography>
    );
  else if (variant === "Body1")
    return (
      <Typography
        component="div"
        style={style && style}
        variant="h6"
        className={`${styles.body1} ${className ? ` ${className}` : ""}`}
        id={
          !constantColor
            ? styles[`${theme}Theme${color}Text`]
            : styles[`${theme}Theme${color}Constant`]
        }
        onClick={onClick}
      >
        {children}
      </Typography>
    );
  else if (variant === "Body2")
    // type Variants =
    // | "Body1" // h1
    // | "Body2" // p1
    return (
      <Typography
        component="div"
        style={style && style}
        variant="body1"
        className={`${styles.body2} ${className ? ` ${className}` : ""}`}
        id={
          !constantColor
            ? styles[`${theme}Theme${color}Text`]
            : styles[`${theme}Theme${color}Constant`]
        }
        onClick={onClick}
      >
        {children}
      </Typography>
    );
  else if (variant === "Subtitle")
    return (
      <Typography
        component="div"
        style={style && style}
        variant="body2"
        className={`${styles.subtitle} ${className ? ` ${className}` : ""}`}
        id={
          !constantColor
            ? styles[`${theme}Theme${color}Text`]
            : styles[`${theme}Theme${color}Constant`]
        }
        onClick={onClick}
      >
        {children}
      </Typography>
    );
  else if (variant === "Footer1")
    return (
      <Typography
        component="div"
        style={style && style}
        variant="body2"
        className={`${styles.footer1} ${className ? ` ${className}` : ""}`}
        onClick={onClick}
      >
        {children}
      </Typography>
    );
  else if (variant === "Footer2")
    return (
      <a href={href} target="_blank" rel="noreferrer" className={styles.link}>
        <Typography
          component="div"
          style={style && style}
          variant="body2"
          className={`${styles.footer2} ${className ? ` ${className}` : ""}`}
          onClick={onClick}
        >
          {children}
        </Typography>
      </a>
    );
  else return <></>;
};

export default Text;
