import { FC } from "react";
import {
  PendingStats,
  ActiveBorrowingLendingsStats,
  OfferStats,
  LendingStats,
  BorrowingStats,
} from "../../../types";
import btcSymbol from "../../../assets/assets/btcSymbol.svg";
import Tooltip from "../Tooltip/Tooltip";

interface IInlineCardStats {
  small?: boolean;
  stretch?: boolean;
  stats:
    | PendingStats
    | ActiveBorrowingLendingsStats
    | OfferStats
    | LendingStats
    | BorrowingStats
    | undefined;
}

const formatStat = (
  value: string | number,
  btcDenomination: boolean | undefined,
): string => {
  let num = typeof value === "string" ? parseFloat(value) : value;

  // Handle NaN, in case parseFloat fails
  if (isNaN(num) || !num) return "N/A";

  return parseFloat(num.toFixed(btcDenomination ? 9 : 2)).toString();
};

const InlineCardStats: FC<IInlineCardStats> = ({ stats, small, stretch }) => {
  const renderStats = () => {
    if (!stats) return null;
    const statsArray = Object.entries(stats);
    return statsArray.map(([key, value], index) => {
      let html = <></>;
      if (value) {
        html = (
          <Tooltip content={value.toolTip || ""} key={"statItem" + index}>
            <div
              className={` ${
                small ? "text-xs" : ""
              } text-center text-sm min-w-[75px]`}
            >
              <p className="text-gray-400 font-bold">{value.name}</p>{" "}
              <div className="flex justify-center font-bold">
                {value.btcDenomination && value.value !== 0 && (
                  <img
                    src={btcSymbol}
                    alt="BTC Symbol"
                    className={` ${
                      small ? "h-4 mr-[2px] mt-[1px]" : "mr-1 h-5"
                    }`}
                  />
                )}

                <p className="text-white font-bold">
                  {value.remainingHoursWithinDay !== undefined
                    ? value.value === 1 && value.remainingHoursWithinDay === 0
                      ? "24 Hours"
                      : value.value === 0
                      ? value.remainingHoursWithinDay < 1
                        ? "< 1 Hour"
                        : `${value.remainingHoursWithinDay} Hour${
                            value.remainingHoursWithinDay > 1 ? "s" : ""
                          }`
                      : value.remainingHoursWithinDay < 1
                      ? `${value.value}D 0H`
                      : `${value.value}D ${value.remainingHoursWithinDay}H`
                    : value.duration
                    ? formatStat(value.value, value.btcDenomination)
                    : value.value === 0 || !value.value
                    ? "N/A"
                    : formatStat(value.value, value.btcDenomination)}
                </p>

                <p className={`text-gray-400 ${value.duration ? "ml-1" : ""}`}>
                  {value.hoursRemaining && value.hoursRemaining > 0
                    ? `Hours`
                    : value.duration
                    ? "Days"
                    : value.value !== 0 &&
                      value.value &&
                      (value.percent ? "%" : "")}
                </p>
              </div>
            </div>
          </Tooltip>
        );
      }
      if (value && !value.showOnMobile) {
        return (
          <div key={"StatItems" + index} className="hidden sm:block">
            {html}
          </div>
        );
      } else {
        return html;
      }
    });
  };

  return (
    <div
      className={`lg:grid lg:grid-flow-col lg:gap-3 xl:gap-8 2xl:gap-14 flex justify-between gap-1 ${
        stretch ? "w-full" : ""
      } "}`}
    >
      {renderStats()}
    </div>
  );
};

export default InlineCardStats;
