import { FC } from "react";

interface TabSelectorProps {
  tabs: string[];
  selectedTab: string;
  setSelectedTab: Function;
  horizontal?: boolean;
}

const TabSelector: FC<TabSelectorProps> = ({
  tabs,
  selectedTab,
  setSelectedTab,
  horizontal,
}) => {
  return (
    <div
      className={`flex p-2 w-full sm:-translate-x-0 -translate-x-2 ${
        horizontal
          ? "flex-row w-full"
          : "flex-col sm:max-w-[250px] lg:min-w-[220px]"
      }`}
    >
      {tabs.map((tab) => (
        <button
          key={tab}
          onClick={() => setSelectedTab(tab)}
          className={`py-2 sm:px-4 px-2 mb-2 text-gray-400 rounded-lg text-left font-semibold text-xs md:text-sm hover:underline ${
            tab === selectedTab && "bg-gray-800 text-gray-50 hover:no-underline"
          }`}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default TabSelector;
