import { useState, useCallback } from "react";
import { httpsCallable } from "@firebase/functions";
import { functions } from "../../../../firebaseConfig";
import { HttpsCallableResult } from "@firebase/functions-types";

type SingleValues = {
  totalInterestEarned: number;
  completedLoansCount: number;
  activeLoansCount: number;
  activeBorrowingLoansValue: number;
  activeLendingLoansValue: number;
  totalPoints?: number;
  rank?: number;
  totalLeaderboardEntries?: number;
};

export const useGetUserAnalytics = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [singleValues, setSingleValues] = useState<SingleValues | null>(null);

  const fetchUserAnalyticsData = httpsCallable(
    functions,
    "fetchUserAnalyticsData",
  );

  const fetchUserAnalytics = useCallback(
    async (userId: string) => {
      if (!userId || userId === "") {
        return;
      }
      setLoading(true);
      try {
        const response: HttpsCallableResult = await fetchUserAnalyticsData({
          userId,
        });
        setSingleValues(response.data.singleValues);
        setError(null);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unexpected error occurred.");
        }
        setSingleValues(null);
      } finally {
        setLoading(false);
      }
    },
    [fetchUserAnalyticsData],
  );

  return {
    fetchUserAnalytics,
    loading,
    singleValues,
    error,
  };
};
