import { useCallback } from "react";
import { getWallets } from "@wallet-standard/core"
import { AppConfig, UserSession, showConnect } from "@stacks/connect";
import { Storage } from "@stacks/storage";
import { AddressPurpose, BitcoinNetworkType, BitcoinProvider, getAddress } from "sats-connect";
import CryptoJS from 'crypto-js';
import Config from "./config";
import { useUserStore } from "./store";
import { setNotificationState } from "./notification";
import GeneralNotification from "./notification/Notifications/GeneralNotification";
import { signMessage } from "sats-connect";
import { httpsCallable } from "firebase/functions";
import { functions } from "./firebaseConfig";
import { getAuth, signInWithCustomToken, signOut } from "firebase/auth";
import { WalletType } from "./types";

const appConfig = new AppConfig(["store_write", "publish_data"]);
export const userSession = new UserSession({ appConfig });
export const storage = new Storage({ userSession });

async function fetchUTCTime() {
  const apis = [
    'https://worldtimeapi.org/api/timezone/Etc/UTC',
    'https://timeapi.io/api/Time/current/coordinate?latitude=0&longitude=0'
  ];

  for (const apiUrl of apis) {
    try {
      const response = await fetch(apiUrl);
      if (response.ok) {
        const data = await response.json();
        const utcDateTime = data.utc_datetime || data.time || data.datetime; // Adjust based on API response format
        const utcDate = new Date(utcDateTime);
        const minutes = 5 * Math.round(utcDate.getUTCMinutes() / 5);
        utcDate.setUTCMinutes(minutes, 0, 0); // Reset seconds and milliseconds to 0
        return utcDate.toISOString();
      }
    } catch (error) {
      console.error(`Error fetching UTC time from ${apiUrl}:`, error);
    }
  }

  // Fallback to system's datetime if all APIs fail
  let now = new Date();
  let minutes = 5 * Math.round(now.getUTCMinutes() / 5);
  now.setUTCMinutes(minutes, 0, 0); // Reset seconds and milliseconds to 0
  return now.toISOString();
}

const hashMessage = async (userId: string) => {
  // Get the current UTC time, rounded to the nearest 5 minutes
  const utcTimestamp = await fetchUTCTime();

  // Construct the message with the UTC timestamp
  const message = `Sign this message to confirm it's you. User: ${userId}, Timestamp: ${utcTimestamp}`;

  // Hash the message using SHA-256
  const hash = CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex);
  return hash;
}

let provider: BitcoinProvider | undefined = undefined;
const getProvider = async () => {
  if (provider) {
    return provider;
  }

  const magicEden = getWallets()
    .get()
    .find((wallet) => wallet.name === "Magic Eden");

  if (!magicEden) {
    return undefined;
  }

  if (!("sats-connect:" in magicEden.features)) {
    return undefined;
  }

  const newProvider = (magicEden.features["sats-connect:"] as any)
    .provider as BitcoinProvider;

  provider = newProvider;

  return newProvider;
}

const useAuthentication = () => {
  const setWallet = useUserStore((state) => state.setWallet);
  const setBtcAddress = useUserStore((state) => state.setBtcAddress);
  const setBtcPubKey = useUserStore((state) => state.setBtcPubKey);
  const setOrdAddress = useUserStore((state) => state.setOrdAddress);
  const setOrdPubKey = useUserStore((state) => state.setOrdPubKey);
  const setAuthenticatingAccount = useUserStore(
    (state) => state.setAuthenticatingAccount
  );

  const handleError = useCallback(() => {
    setAuthenticatingAccount(false);
    setNotificationState(() => (
      <GeneralNotification text={"Error logging in."} variant="error" />
    ));
  }, [setAuthenticatingAccount]);

  const authenticateUser = useCallback(
    async (btc, ord, wallet, btcPubKey, ordPubKey, provider) => {
      setAuthenticatingAccount(true);

      if (!provider) {
        provider = getProvider();
        if (!provider) {
          throw new Error("could not get provider");
        }
      }

      // Generate message to sign
      const message = await hashMessage(ord);

      // Grab authenticateUser function from Firebase
      const authUser = httpsCallable(functions, "authenticateUser");

      // Prepare options for signing BTC message
      const signBtcMessageOptions = {
        getProvider: wallet === "magic_eden" ? async () => provider : undefined,
        payload: {
          address: btc,
          message,
          network: { type: BitcoinNetworkType.Mainnet },
        },
        onFinish: async (btcSignature: string) => {

          // Prepare options for signing ORD message
          const signOrdMessageOptions = {
            getProvider: wallet === "magic_eden" ? async () => provider : undefined,
            payload: {
              address: ord,
              message,
              network: { type: BitcoinNetworkType.Mainnet },
            },
            onFinish: async (ordSignature: string) => {
              try {
                // Verify signatures
                const verifyResponse: any = await authUser({
                  btcSignature,
                  ordSignature,
                  btcAddress: btc,
                  ordAddress: ord,
                });
                
                // Handle the verification response from Firebase
                if (verifyResponse.data.valid) {
                  const auth = getAuth();
                  await signInWithCustomToken(auth, verifyResponse.data.token);
                  setWallet(wallet);
                  setOrdAddress(ord);
                  setOrdPubKey(ordPubKey);
                  setBtcAddress(btc);
                  setBtcPubKey(btcPubKey);
                  setAuthenticatingAccount(false);
                  window.location.reload();
                } else {
                  handleError();
                }
              } catch (error) {
                handleError();
              };
            },
            onError: () => handleError(),
            onCancel: () => handleError(),
          };

          // @ts-ignore
          await signMessage(signOrdMessageOptions);
        },
        onError: () => handleError(),
        onCancel: () => handleError(),
      };

      // Authenticate user based on wallet type
      switch (wallet) {
        case "magic_eden":
        case "xverse":
          // Sign messages
          await signMessage(signBtcMessageOptions);
          break;
        case "leather":
          try {
            const response = await (window as any).btc.request("signMessage", {
              message: message,
              paymentType: "p2wpkh",
            });

            if (response.result.address !== btc) {
              setNotificationState(() => (
                <GeneralNotification
                  text={"Error logging in. Wallet addresses do not match"}
                  variant="error"
                />
              ));
              return;
            }
            setAuthenticatingAccount(true);
            const verifyResponse: any = await authUser({
              id: ord,
              address: btc,
              message,
              signature: response.result.signature,
              bip322: true,
            });

            if (verifyResponse.data.valid) {
              const auth = getAuth();
              signInWithCustomToken(auth, verifyResponse.data.token)
                .then(() => {
                  setWallet(wallet);
                  setOrdAddress(ord);
                  setOrdPubKey(ordPubKey);
                  setBtcAddress(btc);
                  setBtcPubKey(btcPubKey);
                  setAuthenticatingAccount(false);
                  window.location.reload();
                })
                .catch((error) => {
                  setNotificationState(() => (
                    <GeneralNotification
                      text={"Error logging in."}
                      variant="error"
                    />
                  ));
                  setAuthenticatingAccount(false);
                });
            } else {
              setNotificationState(() => (
                <GeneralNotification
                  text={"Error logging in."}
                  variant="error"
                />
              ));
              setAuthenticatingAccount(false);
            }
          } catch (error) {
            setNotificationState(() => (
              <GeneralNotification text={"Error logging in."} variant="error" />
            ));
            setAuthenticatingAccount(false);
          }
          break;
      }
    },
    [setBtcAddress, setBtcPubKey, setOrdAddress, setOrdPubKey, setWallet, setAuthenticatingAccount, handleError]
  );
  return { authenticateUser };
};

const useOnConnectClick = () => {
  const { authenticateUser } = useAuthentication();
  const setDerivationPath = useUserStore((state) => state.setDerivationPath);

  const onConnectClick = useCallback(
    async (wallet: WalletType) => {
      const provider = getProvider();
      if (!provider) {
        throw new Error("could not get provider");
      }

      const response = await new Promise(async (resolve, reject) => {
        let btcAddress;
        let ordAddress;
        let ordPubKey;
        let btcPubKey;
        console.log("wallet", wallet)
        switch (wallet) {
          //LEATHER
          case "leather":
            if (!(window as any).LeatherProvider) {
              setNotificationState(() => (
                <GeneralNotification
                  text={"Leather wallet not installed."}
                  variant="error"
                />
              ));
              return;
            }
            const userAddresses = await (window as any).btc?.request(
              "getAddresses"
            );
            try {
              btcAddress = userAddresses.result.addresses[0].address;
              btcPubKey = userAddresses.result.addresses[0].publicKey;
              ordAddress = userAddresses.result.addresses[1].address;
              ordPubKey = userAddresses.result.addresses[1].publicKey;
              setDerivationPath(
                userAddresses.result.addresses[0].derivationPath
              ); //for the leather address making signature
            } catch (error) {
              console.error("Error during authentication:", error);
            }
            break;

          //XVERSE
          case "xverse":
          case "magic_eden":
            const getAddressOptions = {
              getProvider: wallet === "magic_eden" ? async () => provider : undefined,
              payload: {
                purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
                message: "Address for receiving Ordinals",
                network: {
                  type: Config.Network.isMainnet() ? BitcoinNetworkType.Mainnet : BitcoinNetworkType.Testnet
                },
              },
              onFinish: async (response: any) => {
                try {
                  btcAddress = response.addresses[1].address;
                  btcPubKey = response.addresses[1].publicKey;
                  ordAddress = response.addresses[0].address;
                  ordPubKey = response.addresses[0].publicKey;
                } catch (error) {
                  console.error("Error during authentication:", error);
                }
              },
            };
            try {
              await getAddress(getAddressOptions as any);
            } catch (error) {
              setNotificationState(() => (
                <GeneralNotification
                  text={"Error on wallet connect."}
                  variant="error"
                />
              ));
            }
            break;

        }
        if (ordAddress && btcAddress && ordPubKey && btcPubKey) {
          await authenticateUser(
            btcAddress,
            ordAddress,
            wallet,
            btcPubKey,
            ordPubKey,
            provider
          );
        }
      });
      return response;
    },
    [authenticateUser, setDerivationPath]
  );

  return { onConnectClick };
};

export const authOptions = {
  appDetails: {
    name: "Liquidium",
    icon: "https://media.discordapp.net/attachments/987551093611442227/1091079780625027194/Logo_v2_circle-compressed.png?width=1112&height=1112",
  },
  userSession,
};

const authenticate = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      showConnect({
        ...authOptions,
        onFinish: () => {
          resolve();
        },
      });
    } catch (error) {
      reject(error);
    }
  });
};

const useIsisCurrentUser = (userId?: string) => {
  const ordAddress = useUserStore((state) => state.ordAddress);
  return userId && ordAddress === userId;
};

const useIsUserSignedIn = () => {
  const btcAddress = useUserStore((state) => state.btcAddress);
  const btcPubKey = useUserStore((state) => state.btcPubKey);
  const ordAddress = useUserStore((state) => state.ordAddress);
  const ordPubKey = useUserStore((state) => state.ordPubKey);
  return (
    Boolean(btcAddress) &&
    Boolean(btcPubKey) &&
    Boolean(ordAddress) &&
    Boolean(ordPubKey)
  );
};

const useLogout = () => {
  const setBtcAddress = useUserStore((state) => state.setBtcAddress);
  const setBtcPubKey = useUserStore((state) => state.setBtcPubKey);
  const setOrdAddress = useUserStore((state) => state.setOrdAddress);
  const setOrdPubKey = useUserStore((state) => state.setOrdPubKey);
  const setWallet = useUserStore((state) => state.setWallet);

  const logout = useCallback(() => {
    setBtcAddress("");
    setOrdAddress("");
    setBtcPubKey("");
    setOrdPubKey("");
    setWallet(undefined);

    const auth = getAuth();
    signOut(auth)
      .then(() => {
        setNotificationState(() => (
          <GeneralNotification
            text={"Successfully logged out!"}
            variant="success"
          />
        ));
      })
      .catch((error) => {
        console.error("Error signing out from Firebase:", error);
      });

    userSession.signUserOut();
    document.location.href = "/ordinals/borrow";
  }, [setBtcAddress, setOrdAddress, setBtcPubKey, setOrdPubKey, setWallet]);

  return logout;
};

const useAuth = () => {
  const { onConnectClick } = useOnConnectClick();
  const { authenticateUser } = useAuthentication();
  const isCurrentUser = useIsisCurrentUser;
  const isUserSignedIn = useIsUserSignedIn();
  const logout = useLogout();
  return {
    authenticateUser,
    getProvider,
    onConnectClick,
    isUserSignedIn,
    isCurrentUser,
    logout,
    authenticate,
  };
};

export default useAuth;
