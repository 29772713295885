import { LazyExoticComponent } from "react";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { DocumentSnapshot, Timestamp } from "firebase/firestore";
import { SignatureHash } from "@stacks/connect";

export type WalletType = "xverse" | "leather" | "unisat" | "magic_eden";

export type BorrowLendItemType = {
  //just see whats being used in the current offer row
  //   principal
  // offer.term
  // offer.interestRate
  // floorPrices ???
  // imageURI
  // collection

  borrower?: any;
  collateral?: { id: string };
};

export type StatusTagType =
  | "Active"
  | "Disabled"
  | "Not Activated"
  | "Bot Not Linked"
  | "Used"
  | "Available";

// Stats for row card items
// this can be optimized using extends

export type StatItem = {
  value: number | string;
  percent?: boolean;
  name: string;
  btcDenomination?: boolean;
  showOnMobile?: boolean;
  duration?: boolean;
  hideNA?: boolean;
  toolTip?: string;
  remainingHoursWithinDay?: number;
  hoursRemaining?: number;
  daysRemaining?: number;
};

export type PendingStats = {
  borrowed: StatItem;
  floorPrice?: StatItem;
  value?: StatItem;
  LTV: StatItem;
  duration: StatItem;
  APY: StatItem;
};

export type ActiveBorrowingLendingsStats = {
  borrowed: StatItem;
  floorPrice?: StatItem;
  value?: StatItem;
  duration: StatItem;
  remaining?: StatItem;
  interest: StatItem;
};

export type OfferStats = {
  APY: StatItem;
  duration: StatItem;
  floorPrice?: StatItem;
  value?: StatItem;
  bestOffer: StatItem;
  LTV: StatItem;
};

export type LendingStats = {
  APY?: StatItem;
  duration: StatItem;
  floorPrice?: StatItem;
  value?: StatItem;
  LTV?: StatItem;
  bestOffer?: StatItem;
};

export type BorrowingStats = {
  bestOffer: StatItem;
  floorPrice: StatItem;
  LTV?: StatItem;
  duration: StatItem;
  interest: StatItem;
};

export type userStats = {
  avg_borrowed: number;
  avg_lent: number;
  default_rate: number;
  total_borrowed: number;
  total_lent: number;
  total_liquidated: number;
  total_loans: number;
  total_paid: number;
};

// ------------------------------

export interface RouteGroupType {
  name: string;
  routes: RouteType[];
}

export interface RouteType {
  name: string;
  href: string;
  component: LazyExoticComponent<() => JSX.Element> | (() => JSX.Element);
  icon: IconDefinition;
}

export interface Nft {
  project: string;
  tokenId: number;
  rarity: number;
  image: string;
  borrowedAmount: number; // TODO: nevermind... i think put it all in here? or remove this? idk, think about it.
}

export interface Loan {
  id: number;
  nft: Nft;
  borrowerAccount: string;
  principal: number;
  interest: number;
  debt: number;
  endAt: number;
  termInterestRate: number;
}

export interface Auction {
  id: number;
  nft: Nft;
  endAt: number;
  lastBidderAccount: string | null;
  lastBidAmount: number;
  reserveAmount: number;
}

export interface Notification {
  id: string;
  content_type: string;
  title: string;
  message: string;
  assets?: Asset[];
}

export interface PendingType {
  id: number;
  amount: number;
  url: string;
  project: string;
}

export enum NetworkOptions {
  Devnet = 1,
  Testnet = 2,
  Mainnet = 3,
}

export enum SortType {
  CreatedAt_ASC = "CreatedAt_ASC",
  CreatedAt_DESC = "CreatedAt_DESC",
  InterestRate_ASC = "InterestRate_ASC",
  InterestRate_DESC = "InterestRate_DESC",
  Number_ASC = "Number_ASC",
  Number_DESC = "Number_DESC",
  Principal_ASC = "Principal_ASC",
  Principal_DESC = "Principal_DESC",
  Term_ASC = "Term_ASC",
  Term_DESC = "Term_DESC",
  ME_Number_ASC = "inscriptionNumberAsc",
  ME_Number_DESC = "inscriptionNumberDesc",
  ME_Price_ASC = "priceAsc",
  ME_Price_DESC = "priceDesc",
}

export type RequestType = {
  userId?: string;
  take?: number;
  sortType?: SortType;
  lastDoc?: DocumentSnapshot | null;
};

export type OfferType =
  | "pending"
  | "activeBorrowings"
  | "activeLendings"
  | "offer";

export type Offer = {
  borrower?: {
    assets: Asset[];
    id: string;
  };
  collection: Collection;
  collectionSymbol: string;
  createdDate: {
    _nanoseconds: number;
    _seconds: number;
  };
  endDate?: number | string | null;
  id: string;
  inscriptionId: string;
  interestRate: number;
  lender: {
    id: string;
  };
  ltv: number;
  number: number;
  principal: number;
  startDate?: number | string | null;
  status: LoanStatus;
  term: number;
  collateral?: Collateral;
  type?: "Borrower" | "Lender";
  completedDate?: string;
  collectionUpdatedDate?: string;
  feeRateOption?: FeeRateOptions;
  lastUpdatedBy?: Users | null;
  offerExpirationDate?: string;
  bundleSize?: number;
  collateralType?: "BRC20" | "ORDINAL";
  transactions?: {
    loan: string;
    repay: string;
  }
};

type Asset = {
  action: {
    type: string;
  };
  addresses: Address[];
  content?: {
    meta?: {
      amount: number;
    };
    node?: {
      value: string;
    };
  };
  type: string;
};

type Address = {
  publicKey: string;
  type: string;
  value: string;
};

type Collection = {
  createdAt: string;
  description: string;
  discordLink: string;
  floorPrice: number;
  imageURI: string;
  inscriptionIcon: string;
  marketCap?: string;
  name: string;
  owners: string;
  supply: number;
  totalVolume: string | number;
  twitterLink: string;
  updatedAt: {
    _nanoseconds: number;
    _seconds: number;
  };
  websiteLink: string;
  collateralType?: "BRC20" | "ORDINAL";
  bundleSize?: number;
};

interface Collateral {
  assets: Asset[];
  id: string;
}

interface Person {
  id: string;
}

export enum Users {
  Borrower = "borrower",
  Lender = "lender",
}

type LoanStatus =
  | "canceled"
  | "requested"
  | "pending"
  | "rebuilt"
  | "active"
  | "activating"
  | "repaying"
  | "paid"
  | "unlocking"
  | "unlocked"
  | "liquidating"
  | "liquidated";

type CollateralType = "BRC20" | "ORDINAL" | "RUNE";

export type LoanType = {
  interestRate: number;
  collectionSymbol: string | null;
  endDate: string | null;
  borrower: Person | null;
  principal: number;
  lender: Person | null;
  ltv: number;
  createdDate?: Timestamp;
  term: number;
  id: string;
  collateral: Collateral;
  startDate: string | null;
  status: LoanStatus;
  collection: Collection | null;
  number?: number;
  inscriptionId?: string;
  feeRateOption?: FeeRateOptions;
  lastUpdatedBy?: Users | null;
  isBRC20?: boolean;
  bundleSize?: number;
  ticker?: string;
  collateralType: CollateralType;
};

export type GetOffersBestResponse = {
  offers: LoanType[];
  hasMore: boolean;
  lastDoc: DocumentSnapshot | null;
  error: string | null;
};

export type GetMyOffersResponse = {
  borrowings: Offer[];
  lendings: Offer[];
  historicalLendings: Offer[];
  historicalBorrowings: Offer[];
  offers: Offer[];
  pending: Offer[];
  hasMore: boolean;
  lastDoc: DocumentSnapshot | null;
  error: string | null;
};

export type repaymentInfo = {
  totalRepayment: number;
  liquidiumFee: number;
  lenderRepayment: number;
  totalInterestAmount: number;
  lenderInterestAmount: number;
};

export type InviteInput = {
  id: number;
  title: string;
  type: string;
  isSubmitted: boolean;
  isSubmitting: boolean;
  email: string;
  submittedMessage?: string;
  placeholder?: string | undefined;
};

export type UserAnalytics = {
  singleValues: {
    activeBorrowingLoansValue: number;
    activeLendingLoansValue: number;
    activeLoansCount: number;
    completedLoansCount: number;
    rank: number;
    totalInterestEarned: number;
    totalLeaderboardEntries: number;
    totalPoints: number;
  };
} | null;

export type Airdrop = {
  id: string;
  collateralType: CollateralType;
  escrowAddress: string;
  item?: string;
}

export type UserData = {
  avatarURI?: string;
  email: string;
  isEmailNotif: boolean;
  isWhiteList: boolean;
  stats: userStats;
  telegramUsername?: string;
  telegramChatId?: string;
  invitees?: string[];
  name?: string;
  analytics?: UserAnalytics | null;
  lastAirdropCheck: string;
  airdrops: Airdrop[];
};

export enum FeeRateOptions {
  Low = "low",
  Medium = "medium",
  High = "high",
}

export type FeeObject = {
  label: FeeRateOptions;
  value: number;
};

export type featuredCollection = {
  img: string;
  collectionName: string;
  collectionSymbol: string;
};
