import { FC, useCallback, useEffect, useState } from "react";
import {
  ActiveBorrowingLendingsStats,
  Offer,
  OfferStats,
  PendingStats,
} from "../../../types";
import NFTInfo from "../NFTInfo/NFTInfo";
import InlineCardStats from "../InlineCardStats/InlineCardStats";
import PercentageBar from "../PercentageBar/PercentageBar";
import cancelIcon from "../../../assets/icons/x.svg";
import {
  calculateAccruedInterestPercentage,
  checkExpiryAndDaysRemaining,
  convertSatToBtc,
} from "../../../utils";
import greenCheck from "../../../assets/icons/green-check.svg";
import Tooltip from "../Tooltip/Tooltip";
import {
  defaultBorrowed,
  defaultFloorPrice,
  defaultAPY,
  defaultDuration,
  defaultInterest,
  defaultRemainingDetailed,
  defaultLTVRatio,
  defaultOffered,
  defaultValue,
} from "../../../constants";
import { useErrorStore, useUserStore } from "../../../store";
import Loading from "../Loading";
import infoIcon from "../../../assets/icons/info_icon_filled.svg";
import Big from "big.js";

type rowType =
  | "activeBorrowings"
  | "activeLendings"
  | "offers"
  | "borrow"
  | "lend"
  | "pending"
  | "rebuild"
  | "rebuilt";

interface RowCardProps {
  disabled?: boolean;
  onClick: Function;
  onAcceptPendingSignatureClick?: Function;
  type: rowType;
  offer: Offer;
  signatureType?: "auto" | "manual";
  isLoading?: boolean;
  index?: number;
  bestOffer?: number;
  isBestOffer?: boolean;
  isBRC20?: boolean;
}

const RowCard: FC<RowCardProps> = ({
  disabled,
  onClick,
  type,
  offer,
  onAcceptPendingSignatureClick,
  signatureType,
  isLoading,
  index,
  bestOffer,
  isBestOffer,
  isBRC20,
}) => {
  const [computedType, setComputedType] = useState(type);
  const btcAddress = useUserStore((state) => state.btcAddress);
  const ordAddress = useUserStore((state) => state.ordAddress);
  const invalidTrxErrorId = useErrorStore((state) => state.invalidTrxErrorId);
  const floorPrice = offer.collection.floorPrice;
  const [stats, setStats] = useState<
    OfferStats | ActiveBorrowingLendingsStats | PendingStats
  >();
  const { isExpired, daysRemaining, hoursRemaining, remainingHoursWithinDay } =
    checkExpiryAndDaysRemaining(offer.endDate);

  const isCurrentUser = useCallback(
    (id: string | undefined) => {
      return id && ordAddress ? ordAddress === id : false;
    },
    [ordAddress],
  );

  // Check if we need to display that terms have changed
  // Convert Firestore Timestamp to JavaScript Date object
  const createdDate = offer.createdDate
    ? new Date(offer.createdDate._seconds * 1000)
    : null;
  // Parse collectionUpdatedDate if it's not undefined, otherwise set to null
  const collectionUpdatedDate = offer.collectionUpdatedDate
    ? new Date(offer.collectionUpdatedDate)
    : null;
  // Check if collectionUpdatedDate is after createdDate
  const isOfferTermsUpdated =
    collectionUpdatedDate && createdDate && collectionUpdatedDate > createdDate;

  useEffect(() => {
    if (invalidTrxErrorId === offer.id) {
      setComputedType("rebuild");
    } else {
      setComputedType(type);
    }
  }, [invalidTrxErrorId, offer.id, type]);

  const hoursUntil = (dateString: string): number => {
    const now = new Date();
    const targetDate = new Date(dateString);
    const differenceInMilliseconds = targetDate.getTime() - now.getTime();

    if (differenceInMilliseconds <= 0) {
      return 0;
    }

    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
    return Math.round(differenceInHours * 100) / 100;
  };

  const percentageSection = useCallback(() => {
    if (!offer.endDate) return null;
    // Labels
    if (offer.status === "repaying") {
      return (
        <a
          href={`https://mempool.space/tx/${offer?.transactions?.repay}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className="sm:text-base text-sm font-extrabold text-green-500 text-center">
            REPAYING
          </p>
        </a>
      );
    } else if (offer.status === "activating") {
      return (
        <a
          href={`https://mempool.space/tx/${offer?.transactions?.loan}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className="sm:text-base text-sm font-extrabold text-green-500 text-center">
            ACTIVATING
          </p>
        </a>
      );
    } else if (offer.status === "paid") {
      return (
        <a
          href={`https://mempool.space/tx/${offer?.transactions?.repay}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className="sm:text-base text-sm font-extrabold text-green-500 text-center">
            PAID
          </p>
        </a>
      );
    } else if (
      (offer.status === "liquidating" && offer.lender.id === ordAddress) ||
      (offer.status === "unlocking" && offer?.borrower?.id === ordAddress)
    ) {
      return (
        <a
          href={`https://mempool.space/address/${ordAddress}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className="sm:text-base text-sm font-extrabold text-green-500 text-center">
            CLAIMING
          </p>
        </a>
      );
    } else if (isExpired) {
      return (
        <p className="sm:text-base text-sm font-extrabold text-red-500 text-center">
          EXPIRED
        </p>
      );
    }

    return (
      <PercentageBar startDate={offer.startDate} endDate={offer.endDate} />
    );
  }, [btcAddress, ordAddress, offer, daysRemaining, isExpired]);

  const getSignatureTooltipContent = () => {
    if (signatureType === undefined) return "";
    if (signatureType === "manual") {
      return "Once a borrower accepts your offer you must countersign manually";
    }
    if (signatureType === "auto") {
      return "DeepLake’s Oracle will countersign automatically once a borrower accepts.";
    }
    return "";
  };

  const NFTInfoSection = () => {
    return (
      <>
        <div className="justify-self-start sm:block hidden">
          <NFTInfo
            collectionName={offer.collection.name}
            img={
              offer.status === "requested" ||
              offer.collection.collateralType === "BRC20"
                ? offer.collection.imageURI
                : `https://ord-mirror.magiceden.dev/content/${offer.inscriptionId}`
            }
            NFTindex={offer.status === "requested" ? null : offer.number}
            collectionVerified={true}
          />
        </div>
        <div className="justify-self-start sm:hidden">
          <NFTInfo
            small={true}
            collectionName={offer.collection.name}
            img={
              offer.status === "requested" ||
              offer.collection.collateralType === "BRC20"
                ? offer.collection.imageURI
                : `https://ord-mirror.magiceden.dev/content/${offer.inscriptionId}`
            }
            NFTindex={offer.status === "requested" ? null : offer.number}
            collectionVerified={true}
          />
        </div>
      </>
    );
  };

  //Set stats for card depending on type
  useEffect(() => {
    const tempFloor = convertSatToBtc(false, floorPrice);
    const principal = convertSatToBtc(false, offer.principal);

    switch (computedType) {
      case "offers":
      case "pending":
      case "rebuilt":
        setStats({
          bestOffer: { ...defaultOffered, value: principal },
          ...(isBRC20
            ? { value: { ...defaultValue, value: tempFloor } }
            : { floorPrice: { ...defaultFloorPrice, value: tempFloor } }),
          LTV: { ...defaultLTVRatio, value: offer.ltv },
          duration: { ...defaultDuration, value: offer.term },
          APY: { ...defaultAPY, value: offer.interestRate },
        });
        break;
      case "activeBorrowings":
      case "activeLendings":
        setStats({
          borrowed: { ...defaultBorrowed, value: principal },
          ...(isBRC20
            ? { value: { ...defaultValue, value: tempFloor } }
            : { floorPrice: { ...defaultFloorPrice, value: tempFloor } }),
          remaining:
            isExpired || offer.status === "paid"
              ? undefined
              : {
                  ...defaultRemainingDetailed,
                  value: daysRemaining,
                  ...(remainingHoursWithinDay
                    ? {
                        remainingHoursWithinDay: remainingHoursWithinDay,
                      }
                    : { remainingHoursWithinDay: 0 }),
                  showOnMobile: true,
                },
          duration: {
            ...defaultDuration,
            value: offer.term,
            showOnMobile: false,
          },
          interest: {
            ...defaultInterest,
            value: calculateAccruedInterestPercentage(
              offer.interestRate,
              offer.term,
            ).toFixed(2),
          },
        });
        break;
      default:
      // Handle or ignore default case
    }
  }, [computedType, offer, floorPrice, remainingHoursWithinDay, isExpired]);

  const renderContentBasedOnType = () => {
    switch (computedType) {
      case "offers":
      case "pending":
      case "rebuild":
      case "rebuilt":
        let showCheck = false;

        // Condition for showing the green check icon.
        if (
          (computedType === "pending" && isCurrentUser(offer.lender.id)) ||
          (computedType === "rebuilt" && isCurrentUser(offer?.borrower?.id))
        ) {
          showCheck = true;
        }

        return (
          <div className="grid grid-flow-row gap-[10px] sm:gap-3 ">
            <div className="grid lg:grid-cols-3 grid-cols-2 justify-items-around">
              {NFTInfoSection()}
              <div className="hidden lg:block mt-[10px] sm:min-w-[500px] z-10">
                <InlineCardStats stats={stats} />
              </div>
              <div className="flex justify-end gap-3">
                {computedType === "rebuild" && onAcceptPendingSignatureClick ? (
                  <button
                    className="bg-gray-500 rounded-2xl text-gray-50 text-xs sm:text-sm font-black hover:bg-opacity-70 disabled:hover:bg-opacity-100 p-3 border border-white/20"
                    disabled={disabled}
                    onClick={() =>
                      onAcceptPendingSignatureClick(
                        { ...offer, isBRC20 },
                        index,
                      )
                    }
                  >
                    {isLoading ? (
                      <div className="min-w-[100px]">
                        <Loading />
                      </div>
                    ) : (
                      "Rebuild"
                    )}
                  </button>
                ) : null}

                {showCheck && onAcceptPendingSignatureClick && (
                  <button
                    className="justify-self-end hover:opacity-80 transition-opacity"
                    onClick={() =>
                      onAcceptPendingSignatureClick(
                        { ...offer, isBRC20 },
                        index,
                      )
                    }
                  >
                    <img
                      src={greenCheck}
                      alt="Accept Icon"
                      className="h-6 mr-2"
                    />
                  </button>
                )}

                {computedType !== "rebuild" && (
                  <button
                    className="justify-self-end hover:opacity-80 transition-opacity"
                    onClick={() => onClick(offer)}
                  >
                    <img
                      src={cancelIcon}
                      alt="Cancel Icon"
                      className="h-6 mr-2"
                    />
                  </button>
                )}
              </div>
            </div>
            <div className="block lg:hidden mt-[10px]">
              <InlineCardStats stats={stats} />
            </div>
            {type === "pending" &&
              isCurrentUser(offer.lender.id) &&
              isOfferTermsUpdated && (
                <div className="w-full bg-gray-600 border border-white/20 rounded-md  flex justify-center items-center text-gray-50 sm:text-sm text-xs font-semibold py-1 px-3  gap-2 mt-1">
                  <img alt="warning-info-icon" src={infoIcon}></img>
                  The terms of this offer, including the interest rate and
                  duration, have been updated since you created it. Please
                  review the new terms carefully before confirming or cancelling
                  the offer.
                </div>
              )}
            {type === "pending" && (
              <div className="flex w-full justify-content">
                <Tooltip
                  content={`The loan will begin once ${
                    isCurrentUser(offer.lender.id)
                      ? "you sign"
                      : "the Lender signs"
                  }.`}
                >
                  <p
                    className={`rounded-md w-full text-center border mt-2 p-1 px-3 sm:text-sm text-xs font-semibold
                    ${
                      isCurrentUser(offer.lender.id)
                        ? "border-green-400 text-green-400 bg-green-900/10"
                        : "border-orange-400 text-orange-400 bg-orange-900/10"
                    }`}
                  >
                    Awaiting{" "}
                    {isCurrentUser(offer.lender.id) ? "Your" : "Lender's"}{" "}
                    Signature
                  </p>
                </Tooltip>
              </div>
            )}
            {/* TODO: keep rebuilt separate from pending bc 
              rebuilt will require more logic once implemented for both
              borrower and lender.
            */}
            {type === "rebuilt" && (
              <div className="flex w-full justify-content">
                <Tooltip
                  content={`The loan will begin once ${
                    isCurrentUser(offer.borrower?.id)
                      ? "you sign"
                      : "the Borrower signs"
                  }.`}
                >
                  <p
                    className={`rounded-md w-full text-center border mt-2 p-1 px-3 sm:text-sm text-xs font-semibold ${
                      isCurrentUser(offer.borrower?.id)
                        ? "border-green-400 text-green-400 bg-green-900/10"
                        : "border-orange-400 text-orange-400 bg-orange-900/10"
                    }`}
                  >
                    Awaiting{" "}
                    {isCurrentUser(offer.borrower?.id) ? "Your" : "Borrower's"}{" "}
                    Signature
                  </p>
                </Tooltip>
              </div>
            )}
            {type === "offers" && (bestOffer || isBestOffer) && (
              <div className="flex w-full justify-center ">
                <Tooltip
                  content={
                    isBestOffer
                      ? "Your offer is currently the highest offer or equal to the other highest offers."
                      : "Your current offer is less than the best offer. Consider making a new offer with an increased loan amount."
                  }
                >
                  <p
                    className={`rounded-md w-full text-center ${
                      isBestOffer
                        ? "border-green-400 text-green-400 bg-green-900/10"
                        : "border-orange-400 text-orange-400 bg-orange-900/10"
                    }  border mt-2 p-1 px-3 sm:text-sm text-xs font-semibold`}
                  >
                    {isBestOffer
                      ? "Currently the Best Offer!"
                      : bestOffer
                      ? "Below Best Offer by  -₿" +
                        convertSatToBtc(
                          false,
                          Big(bestOffer).minus(offer.principal).toNumber(),
                        )
                      : "Below Best Offer"}
                  </p>
                </Tooltip>
              </div>
            )}
          </div>
        );
      case "activeLendings":
      case "activeBorrowings":
        return (
          <>
            <div className="flex justify-between items-center">
              {NFTInfoSection()}
              <div className="hidden lg:block mt-[10px]">
                <InlineCardStats stats={stats} />
              </div>
              <div
                className={` flex-grow max-w-[140px] hidden lg:flex ${
                  computedType === "activeBorrowings"
                    ? "justify-end"
                    : "justify-center"
                }`}
              >
                {percentageSection()}
              </div>
              {computedType === "activeBorrowings" &&
                offer.status === "active" &&
                !isExpired && (
                  <button
                    className="bg-gray-500 rounded-2xl text-gray-50 text-xs sm:text-sm font-black hover:bg-opacity-70 disabled:hover:bg-opacity-100 p-3 border border-white/20"
                    disabled={disabled}
                    onClick={() => {
                      onClick(offer, stats);
                    }}
                  >
                    Repay
                  </button>
                )}
              {((computedType === "activeLendings" &&
                offer.status === "active" &&
                isExpired) ||
                (computedType === "activeBorrowings" &&
                  offer.status === "paid")) && (
                <button
                  className="bg-gray-500 rounded-2xl text-gray-50 text-xs sm:text-sm font-black hover:bg-opacity-70 disabled:hover:bg-opacity-100 p-3 border border-white/20"
                  disabled={disabled}
                  onClick={() => onClick(offer, undefined, index)}
                >
                  {isLoading ? (
                    <div className="min-w-[100px]">
                      <Loading />
                    </div>
                  ) : (
                    "Claim Ordinal"
                  )}
                </button>
              )}
            </div>
            <div className="block lg:hidden mt-[10px]">
              <InlineCardStats stats={stats} />
            </div>
            {/* move % bar to bottom on mobile */}
            <div className="block lg:hidden mt-[10px]">
              {percentageSection()}
            </div>
            <div></div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={`bg-gray-800 rounded-xl border border-white/20 sm:flex `}
      key={offer.id}
    >
      <div className="flex-grow p-[10px]">{renderContentBasedOnType()}</div>
      {/* {computedType === "offers" && (
        <>
          <Tooltip content={getSignatureTooltipContent()}>
            <p
              className={` sm:flex hidden justify-center items-center  w-10 cursor-default ${
                signatureType === "auto" && " bg-purple-700 "
              } ${
                signatureType === "manual" && "bg-gray-500"
              } transform rotate-180 rounded-l-[11px] text-xs sm:text-sm font-extrabold text-gray-50`}
              style={{ writingMode: "vertical-rl" }}
            >
              {signatureType === "manual" && "Manual"}
              {signatureType === "auto" && "Auto"}
            </p>
          </Tooltip>
          <Tooltip content={getSignatureTooltipContent()}>
            <p
              className={`flex sm:hidden justify-center items-center  w-full rounded-b-[11px] h-5 text-xs sm:text-sm font-extrabold text-gray-50 cursor-default ${
                signatureType === "auto" && " bg-purple-700 "
              } ${signatureType === "manual" && "bg-gray-500"}  `}
            >
              {signatureType === "manual" && "Manual"}
              {signatureType === "auto" && "Auto"}
            </p>
          </Tooltip>
        </>
      )} */}
    </div>
  );
};

export default RowCard;
