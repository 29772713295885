import { FC } from "react";
import styles from "./loadingBar.module.css";

interface LoadingBarProps {
  white?: boolean;
}

const LoadingBar: FC<LoadingBarProps> = ({ white = false }) => {
  return (
    <div className="w-full bg-gray-900 overflow-hidden rounded-lg ">
      <div
        className={`bg-gradient-to-r from-black ${
          white ? "via-white h-1" : "via-red-500 h-1.5 "
        }  to-gray-900 animate-gradient-slide ${styles.animateGradientSlide}`}
      ></div>
    </div>
  );
};

export default LoadingBar;
