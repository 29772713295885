import { FC } from "react";
import searchIcon from "../../../assets/icons/search.svg";
import sortIcon from "../../../assets/icons/sort.svg";

interface ITitleWithSearchSort {
  title: string;
  // function to be called when the user clicks Sort
  onSortToggle?: () => void;
  ascending: boolean;
  onSearchInput?: (input: string) => void;
}

const TitleWithSearchSort: FC<ITitleWithSearchSort> = ({
  title,
  onSortToggle,
  ascending,
  onSearchInput,
}) => {
  const titleSection = () => {
    return (
      <p className="text-white sm:text-2xl text-xl font-bold mb-7">{title}</p>
    );
  };

  const inputSection = () => {
    return (
      <div className="md:w-52 w-full relative flex">
        <img
          src={searchIcon}
          alt="Search"
          className="absolute w-4 left-2 top-1/2 transform -translate-y-1/2 h-5"
        />
        <input
          onChange={(event) => onSearchInput?.(event.target.value)} // Optional Chaining Here
          className="bg-gray-600 rounded-xl p-2 pl-8 text-white font-bold focus:outline-none focus:ring-1 focus:ring-white w-full text-xs sm:text-sm border border-white/20"
          placeholder="Search..."
        />
      </div>
    );
  };

  const orderSection = () => {
    return (
      <button
        onClick={onSortToggle}
        className="bg-gray-600 rounded-xl hover:bg-gray-500 text-gray-100 py-1 h-9 px-2 font-bold flex items-center min-w-[84px] max-w-[84px] border border-white/20"
      >
        {ascending! ? (
          <img src={sortIcon} alt="Sort" className="h-[17px] mr-2" />
        ) : (
          <img src={sortIcon} alt="Sort" className="h-[17px] mr-2 rotate-180" />
        )}
        Sort
      </button>
    );
  };

  return (
    <>
      <div className="justify-self-start">{titleSection()}</div>
      {/* <div className="md:grid grid-cols-3 items-center mb-8 hidden ">
        
        <div className="justify-center flex">{inputSection()}</div>
        <div className="flex justify-end">{orderSection()}</div>
      </div>
      <div className="grid grid-rows-2 items-center mb-8 md:hidden ">
        <div className="justify-self-start">{titleSection()}</div>
        <div className="flex justify-between mt-2 gap-3">
          {inputSection()}
          <div className="flex justify-end">{orderSection()}</div>
        </div>
      </div> */}
    </>
  );
};

export default TitleWithSearchSort;
