import { FC, useEffect, useState } from "react";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import TabSelector from "../../components/Reusable/TabSelector/TabSelector";
import SettingsSection from "./SettingsSection/SettingsSection";
import { useParams } from "react-router-dom";
import { useUserStore } from "../../store";
import { Offer } from "../../types";
import { useGetMyHistoricalOffers } from "../../hooks/liquidium/useGetMyHistoricalOffers";
import { setNotificationState } from "../../notification";
import GeneralNotification from "../../notification/Notifications/GeneralNotification";
import V3Notification from "../../components/V3Notification/V3Notification";

interface SettingsProps {}

const tabs = ["Deactivate Account", "Loan History"];

const Settings: FC<SettingsProps> = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { page } = useParams<{ page?: string }>();
  const ordAddress = useUserStore((state) => state.ordAddress);
  const {
    loadingHistoricalLoans,
    historicalLendings,
    getHistoricalLoansError,
    historicalBorrowings,
    fetchMyHistoricalOffers,
  } = useGetMyHistoricalOffers();
  const [historicalLoans, setHistoricalLoans] = useState<Offer[]>([]);

  useEffect(() => {
    fetchMyHistoricalOffers({
      userId: ordAddress,
    });
    return () => {};
  }, []);

  //merge historical lendings and borrowings and order by most recently ended
  useEffect(() => {
    if (historicalBorrowings && historicalLendings) {
      // map through each array and add the type property
      const borrowingsWithType = historicalBorrowings.map((loan) => ({
        ...loan,
        type: "Borrower" as const,
      }));
      const lendingsWithType = historicalLendings.map((loan) => ({
        ...loan,
        type: "Lender" as const,
      }));
      // Now merge the modified arrays
      const loans = [...borrowingsWithType, ...lendingsWithType];
      loans.sort((a, b) => {
        if (a.endDate && b.endDate) {
          if (a.endDate > b.endDate) return -1;
          if (a.endDate < b.endDate) return 1;
        }
        return 0;
      });
      setHistoricalLoans(loans);
    }
    return () => {};
  }, [loadingHistoricalLoans]);

  useEffect(() => {
    if (getHistoricalLoansError)
      setNotificationState(() => (
        <GeneralNotification
          text={"Error fetching past loans."}
          variant="error"
        />
      ));
  }, [getHistoricalLoansError]);

  //updates Url to corelate with the selected tab
  useEffect(() => {
    window.history.pushState(
      {},
      "",
      "/settings/" + selectedTab.toLowerCase().replace(/ /g, "-"),
    );
  }, [selectedTab]);

  // Checks if url has a page param and sets the selected tab to that page
  useEffect(() => {
    if (!page) return;
    // eslint-disable-next-line array-callback-return
    tabs.map((tab) => {
      window.history.pushState({}, "", "/contact");
      if (tab.toLowerCase().replace(/ /g, "-") === page.toLowerCase()) {
        setSelectedTab(tab);
      }
    });
  }, [page]);

  return (
    <div className={"flex justify-center sm:mt-10 mt-6 "}>
      <div className="max-w-[1400px] w-full">
        <V3Notification />

        <SectionTitle
          title="Settings"
          alwaysShowInfo
          infoText={
            <p className="text-gray-400 font-semibold text-xs sm:text-sm mt-2">
              Manage your account settings and notification preferences
            </p>
          }
        />
        <div className="w-full flex flex-col lg:flex-row justify-start bg-black border border-white/20 rounded-xl sm:p-5 p-3 mt-8 sm:gap-5 gap-0 min-h-[48vh] ">
          <div className="lg:block md:hidden sm:hidden">
            <TabSelector
              tabs={tabs}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          </div>
          {/* Move to horizontal configuration on mobile */}
          <div className="lg:hidden md:block sm:block hidden">
            <TabSelector
              horizontal
              tabs={tabs}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          </div>
          <SettingsSection
            page={selectedTab}
            historicalLoans={historicalLoans}
            loadingHistoricalLoans={loadingHistoricalLoans}
          />
        </div>
      </div>
    </div>
  );
};

export default Settings;
