import { FC } from "react";
import Tooltip from "../../../../components/Reusable/Tooltip/Tooltip";

interface ValueCardProps {
  title: string;
  value: any;
  tooltip?: string;
  denomination?: string;
  secondValue?: string;
}

const ValueCard: FC<ValueCardProps> = ({
  title,
  value,
  denomination,
  tooltip,
  secondValue,
}) => {
  return (
    <Tooltip content={tooltip}>
      <div className="flex w-full flex-col border border-white/20 rounded-lg p-3 sm:py-2 py-2 hover:text-zinc-300 text-zinc-500">
        <p className="  font-semibold sm:text-base text-sm text-zinc-300">
          {title}
        </p>
        <div className=" flex justify-between items-end">
          <p className=" transition-colors font-bold sm:text-xl text-lg">
            <span className="pr-1">
              {(denomination === "$" || denomination === "₿") && denomination}
            </span>
            {value}
            {denomination === "%" && denomination}
          </p>
          {secondValue && (
            <div className="bg-zinc-900 border-white/20 text-xs sm:text-sm text-zinc-400 font-medium p-1 rounded-md sm:block hidden">
              {secondValue}
            </div>
          )}
        </div>
        {secondValue && (
          <div className="w-full flex">
            <div className="bg-zinc-900 border-white/20 text-xs sm:text-sm text-zinc-400 font-medium p-1 py-[1px] rounded-md sm:hidden">
              {secondValue}
            </div>
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default ValueCard;
