import { Notification } from "../../types";
import styles from "./NotificationItem.module.css";
import AssetCard from "../Reusable/AssetCard";
import Text from "../Reusable/Text";
import Tooltip from "../Reusable/Tooltip/Tooltip";
import arrowDown from "../../assets/icons/dropdown_icon.svg";
import { useState } from "react";
import { useNavigate } from "react-router";

const NotificationItem = ({ notif }: { notif: Notification }) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <div className="flex items-center ">
      {/* <div className="pr-1 ">
        <AssetCard
          assets={[notif]}
          className={"max-w-[55px] max-h-[55px]"}
          hideId
        />
      </div> */}
      <button
        className=" text-left bg-gray-800 border-white/20 border w-full p-3 rounded-lg hover:bg-gray-900"
        onClick={() => navigate("/portfolio")}
      >
        <div className="flex justify-between gap-1">
          <div className="sm:text-xs text-xs text-gray-50 font-bold line">
            {notif.title}
          </div>
          <button
            className={`${dropdownOpen ? " rotate-180" : ""} hover:opacity-80`}
            onClick={(e) => {
              e.stopPropagation(); // Prevents the event from bubbling up
              setDropdownOpen(!dropdownOpen);
            }}
          >
            <img src={arrowDown}></img>
          </button>
        </div>
        <p
          className={`sm:text-xs text-xs text-gray-400  pt-2 font-bold ${
            dropdownOpen ? "block" : "hidden"
          }`}
        >
          {notif.message}
        </p>
      </button>
    </div>
  );
};

export default NotificationItem;
