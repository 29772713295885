import { StatItem } from "./types";
import BTCMachines from "./assets/assets/featuredCollections/BTCMachines/BTCMachines.jpg";
import BTCMutantMachines from "./assets/assets/featuredCollections/BTCMachines/BTCMutantMachines.jpg";
import BTCVirus from "./assets/assets/featuredCollections/BTCMachines/BTCVirus.jpg";
import BTCMutantVirus from "./assets/assets/featuredCollections/BTCMachines/BTCMutantVirus.jpg";
import BTCMutantOperator from "./assets/assets/featuredCollections/BTCMachines/BTCMutantOperator.jpg";
import BTCCards from "./assets/assets/featuredCollections/BTCMachines/BTCCards.jpg";

export interface WalletsProps {
  XVERSE: "xverse";
  HIRO: "hiro";
  LEATHER: "leather";
  UNISAT: "unisat";
  MAGICEDEN: "magic_eden";
  UNKNOWN: "";
}

interface ConstantsProps {
  ApiRefreshInterval: number;
  LowLiquidityThreshold: number;
  ExpiryThreshold: number;
  Wallets: WalletsProps;
  TAKE_COUNT: number;
}

const Constants: ConstantsProps = {
  ApiRefreshInterval: 60000, // 1 minute
  LowLiquidityThreshold: 0.3, // 30% LTV
  ExpiryThreshold: 864, // 864 blocks ~ 6 days
  Wallets: {
    XVERSE: "xverse",
    HIRO: "hiro",
    LEATHER: "leather",
    UNISAT: "unisat",
    MAGICEDEN: "magic_eden",
    UNKNOWN: "",
  },
  TAKE_COUNT: 500, // Number of items per request to Firebase
};

export const ACTIONS_TYPES = {
  AIRDROP: "airdrop",
  SWAP: "swap",
  LOCK: "lock",
  UNLOCK: "unlock",
  FEE: "fee",
};

export enum ADDRESS_TYPES {
  FEE = "fee",
  CHANGE_LENDER = "change.lender",
  CHANGE_BORROWER = "change.borrower",
  CHANGE = "change",
  RECEIVE = "receive",
  REPAYMENT = "repayment",
  LIQUIDATION = "liquidation",
  FUNDING = "funding",
  ISSUING = "issuing",
  SWAP = "swap",
}

export const ASSET_TYPES = {
  BTC_ADDRESS: "btc.address",
  BTC_UTXO: "btc.utxo",
};

export const FNS = {
  IDENTITY: "identity",
  TIME: "time",
};

export const defaultBorrowed: StatItem = {
  value: "",
  name: "Borrowed",
  btcDenomination: true,
  showOnMobile: true,
  toolTip: "The total amount of BTC being borrowed",
};

export const defaultOffered: StatItem = {
  value: "",
  name: "Offer",
  btcDenomination: true,
  showOnMobile: true,
  toolTip: "The total amount of BTC you offered",
};

export const defaultBestOffer: StatItem = {
  value: "",
  name: "Best Offer",
  btcDenomination: true,
  showOnMobile: true,
  toolTip: "Highest offer by a lender",
};

export const defaultFloorPrice: StatItem = {
  value: "",
  name: "Floor",
  btcDenomination: true,
  showOnMobile: true,
  toolTip: "Floor price of the collection",
};

export const defaultValue: StatItem = {
  //FOR BRC20 instead of floor
  value: "",
  name: "Value",
  btcDenomination: true,
  showOnMobile: true,
  toolTip: "Summed value of the tokens",
};

export const defaultLTVRatio: StatItem = {
  value: "",
  percent: true,
  name: "LTV Ratio",
  toolTip:
    "Loan-to-Value ratio is calculated by dividing the borrowed amount by the floor price of the collection, indicating how much of the collateral's value is being used as the basis for the loan",
};

export const defaultDuration: StatItem = {
  value: "",
  name: "Duration",
  showOnMobile: true,
  duration: true,
  toolTip:
    "The period over which the loan must be repaid. If the loan is not repaid within this period, the loan will default and the lender will get the collateral",
};

export const defaultRemaining: StatItem = {
  hoursRemaining: 0,
  value: "",
  name: "Remaining",
  duration: true,
  toolTip: "The amount of time left before the loan must be repaid",
};

export const defaultRemainingDetailed: StatItem = {
  hoursRemaining: 0,
  value: 0,
  name: "Remaining",
  duration: false,
  toolTip: "The amount of time left before the loan must be repaid",
};

export const defaultInterest: StatItem = {
  value: "",
  name: "Interest",
  percent: true,
  showOnMobile: true,
  toolTip:
    "The percentage of the loan that is charged as interest over a specific period",
};

export const defaultAPY: StatItem = {
  value: "",
  name: "APY",
  percent: true,
  showOnMobile: true,
  toolTip:
    "The yearly rate of return on your loan, considering compounding interest. It shows how much you'll earn over a year (including the Liquidium fee).",
};

export const defaultPortfolioStat = {
  value: "",
  btcDenomination: false,
  percent: false,
};

export const btcMachineCollections = [
  {
    collectionName: "BTC Machines",
    img: BTCMachines,
    collectionSymbol: "btc-machines",
  },
  {
    collectionName: "BTC Mutant Machines",
    img: BTCMutantMachines,
    collectionSymbol: "btc-mutant-machine",
  },
  {
    collectionName: "BTC Virus",
    img: BTCVirus,
    collectionSymbol: "btc-virus",
  },
  {
    collectionName: "BTC Mutant Virus",
    img: BTCMutantVirus,
    collectionSymbol: "btc-mutant-virus",
  },
  {
    collectionName: "BTC Mutant Operator",
    img: BTCMutantOperator,
    collectionSymbol: "btc-mutant-operator",
  },
  {
    collectionName: "BTC Cards",
    img: BTCCards,
    collectionSymbol: "btc-cards",
  },
];

export default Constants;
