import { useCallback, useState, useEffect, useRef } from "react";

const useComponentVisible = ({ onBlur }: { onBlur?: Function }) => {
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const ref = useRef<any>(null);
  const ref2 = useRef<any>(null);

  const toggle = useCallback(() => {
    setIsComponentVisible(false);
    if (!isComponentVisible) setIsComponentVisible(true);
  }, [isComponentVisible, setIsComponentVisible]);

  const clickOutside = useCallback(
    (event: any) => {
      if (ref2.current && ref2.current.contains(event.target)) return;
      if (ref.current && !ref.current.contains(event.target)) {
        setIsComponentVisible(false);
        onBlur && onBlur();
      }
    },
    [onBlur]
  );

  const handleEscKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") setIsComponentVisible(false);
    },
    [setIsComponentVisible]
  );

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    document.addEventListener("keydown", handleEscKey, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
      document.removeEventListener("keydown", handleEscKey, true);
    };
  }, [clickOutside, handleEscKey]);

  return { ref, ref2, isComponentVisible, setIsComponentVisible, toggle };
};

export default useComponentVisible;
