import { useCallback, useEffect, FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import userIcon from "../../assets/icons/user_icon.svg";
import copyIcon from "../../assets/icons/copy_icon.svg";
import switchIcon from "../../assets/icons/switch_icon.svg";
import useAuth from "../../auth";
import { useUserStore } from "../../store";
import { convertSatToBtc, formatAddress } from "../../utils";
import Big from "big.js";
import useBtcPrice from "../../hooks/api/useBtcPrice";
import useComponentVisible from "../../hooks/react/useComponentVisible";
import { setNotificationState } from "../../notification";
import CopyNotification from "../../notification/Notifications/CopyNotification";
import styles from "./ProfileMenu.module.css";
import settingsIcon from "../../assets/icons/settings-icon.svg";
import btcSymbol from "../../assets/assets/btcSymbol.svg";
import { UserData } from "../../types";
// import { httpsCallable } from "firebase/functions";
// import { functions } from "../../firebaseConfig";
import GeneralNotification from "../../notification/Notifications/GeneralNotification";
// import axios from "axios";
import Config from "../../config";
import SelectWalletModal from "../../modal/Modals/SelectWalletModal/SelectWalletModal";
import { setModalState } from "../../modal";

interface ProfileMenuProps {
  userData: UserData | null;
  usdBalance: number;
}

const ProfileMenu: FC<ProfileMenuProps> = ({ userData, usdBalance }) => {
  const { ref, ref2, isComponentVisible, setIsComponentVisible } =
    useComponentVisible({});
  const navigate = useNavigate();
  const isSat = useUserStore((state) => state.isSat);
  const btcBalance = useUserStore((state) => state.btcBalance);
  const identity = useUserStore((state) => state.btcAddress);
  const userAnalytics = useUserStore((state) => state.userAnalytics);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(identity);
    setNotificationState(() => <CopyNotification />);
  }, [identity]);

  const handleSettingsClick = useCallback(() => {
    setIsComponentVisible(false);
    navigate("/settings");
  }, [setIsComponentVisible, navigate]);

  const { onConnectClick, logout } = useAuth();
  const handleSwitchAccount = useCallback(async () => {
    setModalState(
      "Select wallet modal",
      () => {},
      () => <SelectWalletModal />,
      false,
      false,
    );
  }, [setModalState]);

  return (
    <div>
      <div className="relative ">
        <button
          ref={ref2}
          onClick={() => setIsComponentVisible((prev) => !prev)}
        >
          <img
            className={styles.icon + " h-6 md:h-7"}
            src={userIcon}
            alt="Profile Icon"
          />
        </button>
        <div
          className={`${styles.triangle} ${isComponentVisible ? "" : "hidden"}`}
        />
      </div>
      <div
        className={`blurBackgroundXL border border-white/20 p-4 flex flex-col sm:gap-5 gap-4 rounded-xl w-64 ${
          userData?.name ? "sm:h-[372px] h-[332px]" : "sm:h-[352px] h-[322px]"
        } absolute z-10 sm:top-[82px] top-[78px] -translate-x-[180px] `}
        style={isComponentVisible ? {} : { display: "none" }}
        ref={ref}
      >
        <div className="flex justify-between ">
          <div className="flex items-center">
            {userData?.avatarURI && (
              <div className="h-8 w-8 mr-2 bg-white rounded-3xl"></div>
            )}
            <div className="flex flex-col">
              <p className="sm:text-sm text-xs font-extrabold text-gray-50">
                {userData?.name}
              </p>
              <div
                className="flex hover:opacity-80 cursor-pointer"
                onClick={handleCopy}
              >
                <p className="sm:text-sm text-xs font-extrabold text-gray-400">
                  {formatAddress(identity)}
                </p>
                <img className={"w-4 ml-1"} src={copyIcon} alt="Copy Icon" />
              </div>
            </div>
          </div>
          <img
            className="w-5 opacity-80 hover:opacity-60 cursor-pointer"
            onClick={handleSettingsClick}
            src={settingsIcon}
            alt="Settings Icon"
          ></img>
        </div>
        <div className="flex flex-col">
          <p className="text-xl font-black text-gray-50 flex">
            <img src={btcSymbol} className="w-3 mr-1" alt="BTC Symbol"></img>
            {convertSatToBtc(isSat, btcBalance)}{" "}
          </p>
          <p className="font-bold sm:text-sm text-xs text-zinc-200 mt-1">
            ${usdBalance.toFixed(2)}
          </p>
        </div>

        <div className={`p-3 rounded-lg border border-white/20 bg-zinc-950 `}>
          <div className="flex justify-between w-full">
            <p className="font-semibold text-sm sm:text-base text-zinc-100">
              Points
            </p>
          </div>
          <p className="font-extrabold text-2xl  text-orange-400 ">
            {userAnalytics ? userAnalytics.singleValues.totalPoints : "0"}
          </p>
          <p className="font-semibold sm:text-sm text-xs text-zinc-500">
            Ranked #{userAnalytics ? userAnalytics?.singleValues.rank : "-"}/
            {userAnalytics
              ? userAnalytics.singleValues.totalLeaderboardEntries
              : "-"}
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <button
            className="border border-white/20 bg-white text-base sm:text-lg font-extrabold rounded-md p-1 hover:bg-opacity-80 flex justify-center items-center"
            onClick={handleSwitchAccount}
          >
            Switch
            <img
              className={styles.iconMedium + " w-4"}
              src={switchIcon}
              alt="Switch Icon"
            />
          </button>
          <button
            className="border border-gray-400 bg-none hover:bg-gray-400/10 text-base text-gray-400 sm:text-lg font-extrabold rounded-md p-1 hover:bg-opacity-80"
            onClick={() => logout()}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileMenu;
