import { FC } from "react";

interface ValueTagProps {
  leftDotColor?: "green" | "orange" | "red";
  value: string;
}

const ValueTag: FC<ValueTagProps> = ({ leftDotColor, value }) => {
  const colorMapping: Record<string, string> = {
    green: "bg-green-500",
    orange: "bg-orange-500",
    red: "bg-red-500",
  };

  const color = colorMapping[leftDotColor || "green"];

  return (
    <div className="bg-gray-500 rounded-lg flex justify-center items-center text-gray-50 text-xs sm:text-sm font-semibold p-1">
      {leftDotColor && (
        <div className={`rounded-xl h-1 w-1 ${color} mr-[5px] ml-[2px]`}></div>
      )}
      {value}
    </div>
  );
};

export default ValueTag;
