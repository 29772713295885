import magicEden from "../../../assets/assets/magic-eden-logo.png";
import bestInSlot from "../../../assets/logos/best_in_slot.webp";

const ModalTitle = ({
  collectionSymbol,
  title,
  subtitle,
  NFTSubtitle,
  img,
  isBRC20,
}: {
  collectionSymbol: string | null;
  img?: string;
  title: string;
  subtitle?: string;
  NFTSubtitle?: string;
  isBRC20?: boolean;
}) => {
  return (
    <div className="flex gap-4 ">
      {img && (
        <>
          <img src={img} alt="" className="w-[66px] h-[66px] rounded-xl" />
        </>
      )}
      <div
        className={`flex flex-col w-full ${
          subtitle ? "justify-between" : "justify-center"
        } " ${subtitle && NFTSubtitle ? "py-0 -mt-1 -mb-1" : "py-1"}`}
      >
        <div className="flex items-center gap-1 justify-between w-full ">
          <p className="sm:text-2xl text-xl font-extrabold text-gray-50 leading-5">
            {title}
          </p>
          {isBRC20 ? (
            <a
              className="cursor-pointer rounded-md"
              target="_blank"
              rel="noreferrer"
              href={"https://bestinslot.xyz/ordinals/brc20/" + collectionSymbol}
            >
              <img
                src={bestInSlot}
                className="w-11 h-11 opacity-80 hover:opacity-100 transition-opacity"
              ></img>
            </a>
          ) : (
            <a
              className="bg-[#f72c87] flex justify-center items-center p-[6px] rounded-md hover:opacity-80 cursor-pointer"
              target="_blank"
              rel="noreferrer"
              href={`https://magiceden.io/ordinals/marketplace/${collectionSymbol}`}
            >
              <img className="h-3" src={magicEden} alt="" />
            </a>
          )}
        </div>
        <div>
          {NFTSubtitle && (
            <p className="text-sm font-semibold text-gray-100">{NFTSubtitle}</p>
          )}
          {subtitle && (
            <p className="text-sm font-semibold text-gray-100">{subtitle}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalTitle;
