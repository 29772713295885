import { FC, useEffect, useState } from "react";
import LoadingBar from "../../../components/Reusable/LoadingBar/Loading";
import { Offer } from "../../../types";
import { calculateRepaymentInfo, convertSatToBtc } from "../../../utils";
import openLink from "../../../assets/icons/open_link.svg";
import Tooltip from "../../../components/Reusable/Tooltip/Tooltip";
import { useNavigate } from "react-router";

interface LoanHistory {
  historicalLoans: Offer[];
  loading: boolean;
}

type FormattedLoan = {
  startDate: string;
  endDate: string;
  amount: string;
  duration: string;
  apy: string;
  interest: string;
  type: "Borrower" | "Lender" | undefined;
  status: string;
  ordinal: { link: string; collection: string };
  txnLink?: string;
  collateralType?: "BRC20" | "ORDINAL" | undefined;
  collectionSymbol?: string;
};

const LoanHistory: FC<LoanHistory> = ({ historicalLoans, loading }) => {
  const [formattedLoans, setFormattedLoans] = useState<FormattedLoan[]>([]);
  const navigate = useNavigate();

  //Creates CSV download of offers
  const createCSV = () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    const header = [
      "Start Date",
      "End Date",
      "Amount",
      "Duration",
      "APY",
      "Interest",
      "Type",
      "Status",
      "Collateral",
      // TODO:add txn link",
      // "TxnLink",
    ];
    csvContent += header.join(",") + "\r\n";

    formattedLoans.forEach((item) => {
      const row = [
        item.startDate,
        item.endDate,
        item.amount,
        item.duration,
        item.apy,
        item.interest,
        item.type,
        item.status,
        item.ordinal.link,
        // TODO:add txn link",
        // item.txnLink,
      ];
      csvContent += row.join(",") + "\r\n";
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "loan_history.csv");
    document.body.appendChild(link);
    link.click();
  };

  //Convert the offer into a format that can be used by the table and CSV
  useEffect(() => {
    const formattedData = historicalLoans.map((offer) => {
      let link =
        "https://magiceden.io/ordinals/item-details/" + offer.inscriptionId;
      if (offer.collection.collateralType === "BRC20") {
        link =
          "https://bestinslot.xyz/ordinals/brc20/" + offer.collectionSymbol;
      }
      console.log(link);
      return {
        startDate: offer.startDate
          ? new Date(offer.startDate).toLocaleDateString()
          : "-",
        endDate: offer.completedDate
          ? new Date(offer.completedDate).toLocaleDateString()
          : offer.endDate
          ? new Date(offer.endDate).toLocaleDateString()
          : "-",
        amount: `₿${convertSatToBtc(false, offer.principal)}`,
        duration: `${offer.term} Days`,
        apy: `${offer.interestRate}%`,
        interest: `₿${convertSatToBtc(
          false,
          calculateRepaymentInfo(
            offer.principal,
            offer.interestRate,
            offer.term,
          ).lenderInterestAmount,
        )}`,
        type: offer.type,
        status: `${
          offer.status.charAt(0).toUpperCase() + offer.status.slice(1)
        }`,
        ordinal: {
          link: link,
          collection: offer.collection.name,
        },
        //TODO: add txn link
        // txnLink: "https://google.com",
      };
    });
    setFormattedLoans(formattedData);
  }, [historicalLoans]);

  return (
    <div className="w-full">
      {loading ? (
        <LoadingBar />
      ) : (
        <>
          {historicalLoans.length > 0 ? (
            <div className="border border-white/20 rounded-md w-full md:px-3  p-2 overflow-x-auto max-h-[240px] overflow-y-scroll scrollbar-thin">
              <table className=" w-full  min-w-[700px] ">
                <thead className="text-xs 2xl:text-sm text-gray-400/80 text-left">
                  <tr>
                    <th>
                      <Tooltip content="Date the loan was activated">
                        <p>Start Date</p>
                      </Tooltip>
                    </th>
                    <th>
                      <Tooltip content="Date the loan was repaid or liquidated">
                        <p>End Date</p>
                      </Tooltip>
                    </th>
                    <th>
                      <Tooltip content="The total amount of BTC borrowed">
                        <p>Amount</p>
                      </Tooltip>
                    </th>
                    <th>
                      <Tooltip content="The period over which the loan had to be repaid.">
                        <p>Duration</p>
                      </Tooltip>
                    </th>
                    <th>
                      <Tooltip content="The yearly rate of return on your loan, considering compounding interest. It shows how much you'll earn over a year">
                        <p>APY</p>
                      </Tooltip>
                    </th>
                    <th>
                      <Tooltip content="The total interest accumulated on the loan (excluding fees) ">
                        <p>Interest</p>
                      </Tooltip>
                    </th>
                    <th>
                      <Tooltip content="Whether you were the borrower of lender for the loan">
                        <p>Type</p>
                      </Tooltip>
                    </th>
                    <th>Status</th>
                    <th>
                      <Tooltip content="The ordinal used as collateral">
                        <p>Collateral</p>
                      </Tooltip>
                    </th>
                    {/* TODO: add txn link */}
                    {/* <th>Txn</th> */}
                  </tr>
                </thead>
                <tbody className="text-xs 2xl:text-sm text-gray-50">
                  {formattedLoans.map((loan, index) => (
                    <tr
                      key={index}
                      className="border-t-[1px] border-white/20 hover:bg-white/[7%] h-6"
                    >
                      <td>{loan.startDate}</td>
                      <td>{loan.endDate}</td>
                      <td>{loan.amount}</td>
                      <td>{loan.duration}</td>
                      <td>{loan.apy}</td>
                      <td>{loan.interest}</td>
                      <td>{loan.type}</td>
                      <td>{loan.status}</td>
                      <td>
                        <a
                          className="underline hover:opacity-70 transition-opacity cursor-pointer"
                          target="_blank"
                          href={loan.ordinal.link}
                        >
                          {loan.ordinal.collection}
                        </a>
                      </td>
                      {/* TODO: add txn link */}
                      {/* <td>
                    <a
                      href={loan.txnLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:opacity-70 transition-opacity"
                    >
                      <img
                        src={openLink}
                        alt="Open Txn Icon"
                        className="w-3 h-3"
                      ></img>
                    </a>
                  </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-gray-50 text-sm">
              No completed loans. That's okay, start{" "}
              <a
                className="cursor-pointer font-bold text-white hover:underline"
                onClick={() => navigate("/borrow")}
              >
                borrowing
              </a>{" "}
              or{" "}
              <a
                className="cursor-pointer font-bold text-white hover:underline"
                onClick={() => navigate("/lend")}
              >
                lending
              </a>{" "}
              now!
            </p>
          )}
        </>
      )}
      {!loading && historicalLoans.length > 0 && (
        <div className="flex justify-end">
          <button
            onClick={() => createCSV()}
            className="px-2 py-1 bg-white transition-colors hover:bg-white/70 text-black  text-xs rounded font-semibold mt-4"
          >
            Download CSV
          </button>
        </div>
      )}
    </div>
  );
};

export default LoanHistory;
