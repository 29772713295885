import { useCallback } from "react";
import { InputToSign, signTransaction } from "sats-connect";
import { openPsbtRequestPopup } from "@stacks/connect";
import Config from "../../../config";
import Constants from "../../../constants";
import { useErrorStore, useUserStore } from "../../../store";
import {
  base64ToHex,
  convertBtcToSat,
  extractAccountNumber,
} from "../../../utils";
import { setNotificationState } from "../../../notification";
import GeneralNotification from "../../../notification/Notifications/GeneralNotification";
import { httpsCallable } from "firebase/functions";
import { auth, functions } from "../../../firebaseConfig";
import useAuth from "../../../auth";
import { buildNativeSegwitPsbtRequestOptions1 } from "../../../utils";
import { hexToBytes } from "@stacks/common";
import { useModalStore } from "../../../modal";

const useDeepLake = () => {
  // Store
  const wallet = useUserStore((state: any) => state.wallet);
  const btcAddress = useUserStore((state: any) => state.btcAddress);
  const btcPubKey = useUserStore((state: any) => state.btcPubKey);
  const ordAddress = useUserStore((state: any) => state.ordAddress);
  const ordPubKey = useUserStore((state: any) => state.ordPubKey);
  const derivationPath = useUserStore((state: any) => state.derivationPath);
  const setInvalidTrxErrorId = useErrorStore(
    (state: any) => state.setInvalidTrxErrorId,
  );
  const { close } = useModalStore();

  // Check auth status
  const { authenticateUser, getProvider } = useAuth();
  const checkAuthStatus = useCallback(async () => {
    const user = auth.currentUser;

    if (user) {
      // Check if the user's token has expired
      const token = await user.getIdTokenResult();
      if (+token.expirationTime <= new Date().getTime()) {
        // Token has expired, call your custom authorization function
        await authenticateUser(
          btcAddress,
          ordAddress,
          wallet,
          btcPubKey,
          ordPubKey,
          undefined,
        );
      } else {
        // User is authenticated and token is valid
        return true;
      }
    } else {
      // User is not authenticated, call your custom authorization function
      await authenticateUser(
        btcAddress,
        ordAddress,
        wallet,
        btcPubKey,
        ordPubKey,
        undefined,
      );
    }
    return false;
  }, [authenticateUser, btcAddress, ordAddress]);

  // Reusable Functions
  const handleError = useCallback(
    async (message: string) => {
      setNotificationState(() => (
        <GeneralNotification text={message} link={"https://docs.liquidium.fi/more/common-issues"} variant="error" />
      ));
    },
    [ordAddress],
  );

  // Sign Transaction Click
  const onSignTransactionClick = useCallback(
    async (
      loanType: "request" | "offer",
      psbt: string,
      id: string,
      signer: "borrower" | "lender" | "repay" | "liquidate", // TODO: change this from signer to event
      onFinish: ({ id, hex }: { id: string; hex: string }) => any,
      inputs?: number | undefined,
      sides?: any,
      onCancel?: Function,
      onError?: Function,
    ) => {
      try {
        const result = [];
        let signingIndexes;

        if (loanType === "request") {
          if (inputs) {
            signingIndexes = Array.from(
              { length: inputs - 1 },
              (_, i) => i + 1,
            );
          }
        } else if (loanType === "offer") {
          const btcIndexes: any = [];
          const ordIndexes: any = [];

          sides
            .filter((side: any) => side.movement === "input")
            .forEach((side: any, index: number) => {
              if (side.leaf === btcAddress) {
                btcIndexes.push(index);
              } else if (side.leaf === ordAddress) {
                ordIndexes.push(index);
              }
            });

          if (btcIndexes.length > 0) {
            let btcInputsToSign: InputToSign = {
              address: btcAddress,
              signingIndexes: btcIndexes,
            };
            result.push(btcInputsToSign);
          }
          if (ordIndexes.length > 0) {
            let ordInputsToSign: InputToSign = {
              address: ordAddress,
              signingIndexes: ordIndexes,
            };
            result.push(ordInputsToSign);
          }
        } else return;

        if (wallet === Constants.Wallets.XVERSE || wallet === Constants.Wallets.MAGICEDEN) {
          const provider = getProvider();
          if (!provider) {
            throw new Error("could not get provider");
          }

          const signPsbtOptions = {
            getProvider: wallet === "magic_eden" ? async () => provider : undefined,
            payload: {
              network: {
                type: Config.Network.isMainnet() ? "Mainnet" : "Testnet",
              },
              message: "Sign Transaction",
              psbtBase64: psbt,
              inputsToSign: result,
            },
            onFinish: (response: any) => {
              onFinish({ id: id, hex: response.psbtBase64 });
            },
            onCancel: () => {
              onCancel && onCancel();
            },
            onError: (error: any) => {
              onError && onError();
              handleError("Sign Transaction Error");
            },
          };
          // @ts-ignore
          await signTransaction(signPsbtOptions);
        } else if (wallet === Constants.Wallets.HIRO) {
          openPsbtRequestPopup({
            appDetails: {
              name: "Liquidium",
              icon: "https://media.discordapp.net/attachments/987551093611442227/1091079780625027194/Logo_v2_circle-compressed.png?width=1112&height=1112",
            },
            hex: base64ToHex(psbt),
            network: Config.Network,
            onFinish: async (data) => {
              onFinish({ id: id, hex: data.hex });
            },
          });
        } else if (wallet === Constants.Wallets.LEATHER) {
          let selectedSigningIndexes;

          if (loanType === "request") {
            if (signer === "repay" || signer === "liquidate") {
              selectedSigningIndexes = signingIndexes;
            } else {
              selectedSigningIndexes = inputs ? signingIndexes : [0];
            }
          } else {
            selectedSigningIndexes = result;
          }

          const psbtRequestOptions = buildNativeSegwitPsbtRequestOptions1(
            hexToBytes(btcPubKey),
          );
          const signPsbtRequestParams = {
            publicKey: btcPubKey,
            hex: psbtRequestOptions.hex,
            signAtIndex: selectedSigningIndexes,
            network: Config.Network.isMainnet() ? "Mainnet" : "Testnet",
            account: extractAccountNumber(derivationPath),
          };

          try {
            const result = await (window as any).btc.request(
              "signPsbt",
              signPsbtRequestParams,
            );
            const hexResp = result.hex;
            onFinish({ id: id, hex: hexResp });
          } catch (error) {
            handleError("Leather Wallet Signing Error");
            onError && onError(error);
          }
        }
      } catch (e) {
        handleError("Wallet Error");
        onError && onError();
      }
    },
    [getProvider, wallet, btcAddress, ordAddress, handleError],
  );

  // Functions
  const handleRequestCreation = (
    any1: any,
    any2: any,
    any3: any,
    any4: any,
  ) => {};
  const handlePendingAgreement = (any: any) => {};
  const handleActivateLoan = (any: any) => {};

  // Loan Type Request Repayment and Liquidation
  const handleExecuteOutcome = (
    loan: any,
    outcome: "repay" | "liquidate",
  ) => {};

  //
  //
  //
  //
  //
  // Lender Offers V2
  //
  //
  //
  //
  //

  // Lender creates a loan offer
  const handleCreateOffer = useCallback(
    async (
      collectionSymbol: string | null,
      amount: number,
      numberOfLoans: number,
      collateralType: string,
    ) => {
      await checkAuthStatus();
      try {
        // Convert amount to satoshis
        const principal = convertBtcToSat(false, amount);

        // Create the offer
        const createOffer = httpsCallable(functions, "createOffer");
        const createOfferResponse: any = await createOffer({
          btcPubKey,
          ordPubKey,
          principal,
          collectionSymbol,
          numberOfOffers: numberOfLoans,
          collateralType,
        });

        // Check for errors
        const newLoan = createOfferResponse.data;
        if (newLoan?.data?.error) throw new Error(newLoan.data.error);

        // Notify user & socials
        setNotificationState(() => (
          <GeneralNotification
            text={`${numberOfLoans > 1 ? numberOfLoans + " " : ""}Loan Offer${
              numberOfLoans > 1 ? "s" : ""
            } Created!`}
            link={`${Config.AppUrl}/ordinals/lend/${collectionSymbol}`}
            sameTab
          />
        ));

        return newLoan;
      } catch (error) {
        // @ts-ignore
        if (error?.message.includes("Insufficient BTC balance")) {
          handleError("Borrower/Lender lacks confirmed BTC.");
        } else {
          handleError("Create Offer Error");
        }
      }
    },
    [checkAuthStatus, btcPubKey, ordPubKey, handleError],
  );

  // Borrower accepts offer
  const handleAcceptOffer = useCallback(
    async (
      selectedInscriptions: any[],
      offer: any,
      feeRate: number,
      onSubmit: Function,
    ) => {
      await checkAuthStatus();
      try {
        // Prepare the acceptance
        const prepareAccept = httpsCallable(functions, "prepareAccept");
        const prepareResponse: any = await prepareAccept({
          loanId: offer.id,
          inscriptionId: selectedInscriptions[0].id,
          btcPubKey,
          ordPubKey,
          feeRate,
        });
        if (prepareResponse?.data?.error)
          throw new Error(prepareResponse.data.error);

        const escrow = prepareResponse.data.executeEscrow;
        const signResponse = await onSignTransactionClick(
          "offer",
          escrow.transactions[0].base64,
          offer.id,
          "borrower",
          async ({ id, hex }) => {
            let acceptResponse;
            try {
              // Accept the offer
              const acceptLoan = httpsCallable(functions, "acceptLoan");
              acceptResponse = await acceptLoan({
                loanId: offer.id,
                base64: hex,
              });
              
              // Refresh data
              await onSubmit?.();

              // Notify user & socials
              setNotificationState(() => (
                <GeneralNotification text={"Loan Accepted!"} />
              ));
            } catch (error) {
              handleError("Accept Offer Error");
            }

            // Email Lender
            const sendLoanAccepted = httpsCallable(
              functions,
              "sendLoanAccepted",
              );
              sendLoanAccepted({
                loanId: offer.id,
              });

            return acceptResponse;
          },
          undefined,
          escrow.sides,
        );

        return signResponse;
      } catch (error) {
        // @ts-ignore
        if (error?.message.includes("Insufficient BTC balance")) {
          handleError("Lender's BTC Balance Low");
        } else if (
          // @ts-ignore
          error?.message.includes(
            `Expected property "1" of type Satoshi, got Number -`,
          )
        ) {
          handleError("Borrower/Lender lacks confirmed BTC.");
        } else {
          handleError("Accept Offer Error");
        }
      }
    },
    [
      checkAuthStatus,
      onSignTransactionClick,
      btcPubKey,
      ordPubKey,
      handleError,
    ],
  );

  // Lender activates offer
  const handleActivateOffer = useCallback(
    async (offer: any, onSubmit?: Function, onError?: Function) => {
      await checkAuthStatus();

      // Get the user so we know which side to sign
      let user: "lender" | "borrower" | undefined;
      if (offer.lender.id === ordAddress) {
        user = "lender";
      } else if (offer.borrower.id === ordAddress) {
        user = "borrower";
      }
      if (!user) {
        handleError("User Not Found");
        return;
      }

      let vault = offer.vault;
      if (offer.status === "rebuilt") {
        vault = offer.rebuiltVault;
      }

      try {
        console.log("here");
        await onSignTransactionClick(
          "offer",
          offer.vault.transactions[0].base64,
          offer.id,
          user,
          async ({ id, hex }) => {
            try {
              const activateLoan = httpsCallable(functions, "activateLoan");
              const broadcastResponse: any = await activateLoan({
                loanId: offer.id,
                base64: hex,
              });
              if (broadcastResponse?.data?.error)
              throw new Error(broadcastResponse.data.error);

              // Refresh data and notify user
              await onSubmit?.();
              setNotificationState(() => (
                <GeneralNotification
                  text={"Loan Broadcasted!"}
                  link={`https://mempool.space/${
                    Config.Network.isMainnet() ? "" : "testnet/"
                  }address/${btcAddress}`}
                />
              ));

              // Email Borrower
              const sendLoanStarted = httpsCallable(functions, "sendLoanStarted");
              sendLoanStarted({
                loanId: offer.id,
              });
            } catch (error) {
              if (
                // @ts-ignore
                error?.message.includes(
                  `Request failed with status code 400: {\"response\":{\"errors\":[{\"message\":\"Request failed with status code 400\",\"locations\":[{\"line\":3,\"column\":9}],\"path\":[\"broadcastEscrow\"],\"extensions\":{\"code\":\"INTERNAL_SERVER_ERROR\",\"stacktrace\":[\"AxiosError: Request failed with status code 400`
                )
              ) {
                setInvalidTrxErrorId(offer.id);
                close();
                handleError("Transaction invalid. Please rebuild the loan.");
              }
            }
          },
          undefined,
          offer.vault.sides,
          undefined,
          onError,
        );
      } catch (error) {
        // TODO: finish adding this, or add a getBalance api call so user doesn't have to sign xverse if low balance
        // @ts-ignore
        if (error?.message.includes("Insufficient BTC balance")) {
          handleError("Lender's BTC Balance Low");
        } else if (
          // @ts-ignore
          error?.message.includes(
            `Expected property "1" of type Satoshi, got Number -`,
          )
        ) {
          handleError("Borrower/Lender lacks confirmed BTC.");
        } else {
          handleError("Activate Loan Error");
        }
      }
    },
    [
      onSignTransactionClick,
      btcAddress,
      ordAddress,
      handleError,
      checkAuthStatus,
    ],
  );

  // Rebuild and sign transaction
  const handleRebuildOffer = useCallback(
    async (offer: any, feeRate: number, onSubmit?: Function) => {
      await checkAuthStatus();

      try {
        // Get the user so we know which side to sign
        let user: "lender" | "borrower" | undefined;
        if (offer.lender.id === ordAddress) {
          user = "lender";
        } else if (offer.borrower.id === ordAddress) {
          user = "borrower";
        }
        if (!user) {
          handleError("User Not Found");
          return;
        }

        // Prepare the rebuild
        const prepareRebuild = httpsCallable(functions, "prepareRebuild");
        const prepareResponse: any = await prepareRebuild({
          loanId: offer.id,
          feeRate,
        });

        if (prepareResponse?.data?.error)
          throw new Error(prepareResponse.data.error);

        // Get the escrow
        const escrow = prepareResponse.data.executeEscrow;
        await onSignTransactionClick(
          "offer",
          escrow.transactions[0].base64,
          offer.id,
          user,
          async ({ id, hex }) => {
            const rebuildLoan = httpsCallable(functions, "rebuildLoan");
            const rebuildResponse: any = await rebuildLoan({
              loanId: offer.id,
              base64: hex,
            });

            // Refresh data and notify user
            await onSubmit?.();
            setNotificationState(() => (
              <GeneralNotification text={"Loan Rebuilt!"} />
            ));
          },
          undefined,
          escrow.sides,
        );
      } catch (error) {
        if (
        // @ts-ignore
          error?.message.includes(
            `Expected property "1" of type Satoshi, got Number -`,
          )
        ) {
          handleError("Borrower/Lender lacks confirmed BTC.");
        } else {
          handleError(
            "Problem rebuilding loan, cancel & recreate.",
          );
        }
      }
    },
    [onSignTransactionClick, handleError, checkAuthStatus, ordAddress],
  );

  // Borrower repays loan
  const handleRepayOffer = useCallback(
    async (loan: any, feeRate: number, onSubmit?: Function) => {
      await checkAuthStatus();
      try {
        const prepareRepay = httpsCallable(functions, "prepareRepay");
        const prepareResponse: any = await prepareRepay({
          loanId: loan.id,
          feeRate,
        });

        const escrow = prepareResponse.data.executeEscrow;
        const signResponse = await onSignTransactionClick(
          "offer",
          escrow.transactions[0].base64,
          loan.id,
          "repay",
          async ({ id, hex }) => {
            try {
              const broadcastRepay = httpsCallable(functions, "broadcastRepay");
              const broadcastResponse: any = await broadcastRepay({
                loanId: loan.id,
                base64: hex,
              });

              // Refresh data and notify user
              await onSubmit?.();
              setNotificationState(() => (
                <GeneralNotification
                  text={"Loan Repaid!"}
                  link={`https://mempool.space/${
                    Config.Network.isMainnet() ? "" : "testnet/"
                  }address/${btcAddress}`}
                />
              ));
            } catch (error) {
              handleError("Repay Loan Error");
            }
          },
          undefined,
          escrow.sides,
        );
      } catch (error) {
        if (
          // @ts-ignore
          error?.message.includes(
            `Expected property "1" of type Satoshi, got Number -`,
          )
        ) {
          handleError("You are low on confirmed BTC.");
        } else {
          handleError("Repay Loan Error");
        }
      }
    },
    [btcAddress, onSignTransactionClick, handleError, checkAuthStatus],
  );

  // Borrower unlocks collateral
  const handleUnlock = useCallback(
    async (
      loan: any,
      feeRate: number,
      type: "liquidate" | "unlock",
      onSubmit: Function,
      onError: Function,
    ) => {
      await checkAuthStatus();

      try {
        // Prepare the unlock
        const prepareUnlock = httpsCallable(functions, "prepareUnlock");
        const prepareResponse: any = await prepareUnlock({
          loanId: loan.id,
          feeRate,
        });

        // Get the escrow and sign the transaction
        const escrow = prepareResponse.data.executeEscrow;
        const signatureResponse = await onSignTransactionClick(
          "offer",
          escrow.transactions[0].base64,
          loan.id,
          "liquidate",
          async ({ id, hex }) => {
            try {
              const broadcastUnlock = httpsCallable(
                functions,
                "broadcastUnlock",
              );
              const broadcastResponse = await broadcastUnlock({
                loanId: loan.id,
                base64: hex,
              });

              // Refresh data and notify user
              await onSubmit?.();
              setNotificationState(() => (
                <GeneralNotification
                  text={"Collateral Unlocked!"}
                  link={`https://mempool.space/${
                    Config.Network.isMainnet() ? "" : "testnet/"
                  }address/${ordAddress}`}
                />
              ));
            } catch (error) {
              await onError?.();
              // @ts-ignore
              if (error?.message.includes("Outcome is not valid")) {
                handleError(
                  "Error unlocking, please try again later.",
                );
              } else {
                handleError("Claim Collateral Error");
              }
            }
          },
          undefined,
          escrow.sides,
          onError,
          onError,
        );
      } catch (error) {
        await onError?.();
        // @ts-ignore
        if (error?.message.includes("Outcome is not valid")) {
          handleError(
            "Error unlocking, please try again later.",
          );
        } else if (
          // @ts-ignore
          error?.message.includes(
            `Expected property "1" of type Satoshi, got Number -`,
          )
        ) {
          handleError("You are low on confirmed BTC.");
          // @ts-ignore
        } else if (error?.message.includes("No valid transfers found.")) {
          handleError("Escrow balance is too low. Please inscribe a transfer.");
        } else {
          handleError("Claim Collateral Error");
        }
      }
    },
    [checkAuthStatus, ordAddress, onSignTransactionClick, handleError],
  );

  return {
    handleRequestCreation,
    handlePendingAgreement,
    handleActivateLoan,
    handleExecuteOutcome,
    handleCreateOffer,
    handleAcceptOffer,
    handleActivateOffer,
    handleRepayOffer,
    handleUnlock,
    handleRebuildOffer,
  };
};

export default useDeepLake;
