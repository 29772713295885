import copyIcon from "../../../assets/icons/copy_icon.svg";
import Text from "../../../components/Reusable/Text";
import styles from "./CopyNotification.module.css";

const CopyNotification = () => {
  return (
    <Text variant="Body2" color="White">
      <img className={styles.icon} src={copyIcon} alt="Copy Icon" />
      Address Copied
    </Text>
  );
};

export default CopyNotification;
