import { useState, useCallback } from "react";
import { httpsCallable } from "@firebase/functions";
import { functions } from "../../../../firebaseConfig";
import { HttpsCallableResult } from "@firebase/functions-types";

type ChartFormat = Array<{ name: string; value: number }>;

type SingleValues = {
  avgLoanAmount: number;
  activeLoans: number;
  totalOffers: number;
  TALV: number;
  avgActiveOfferAmount: number;
  defaultRate: number;
  completedLoansCount: number;
  totalVolume: number;
  cancelledOffersCount: number;
  activeOffersCount: number;
};

interface ChartDataResponse {
  volume: ChartFormat;
  offers: ChartFormat;
  cumulativeVolume: ChartFormat;
  singleValues: SingleValues;
}

export const useGetMyCharts = () => {
  const [loadingMyCharts, setLoadingMyCharts] = useState<boolean>(false);
  const [myChartsError, setMyChartsError] = useState<string | null>(null);
  const [volume, setVolume] = useState<ChartFormat>([]);
  const [offers, setOffers] = useState<ChartFormat>([]);
  const [cumulativeVolume, setCumulativeVolume] = useState<ChartFormat>([]);
  const [singleValues, setSingleValues] = useState<SingleValues | null>(null);

  const fetchAnalyticsData = httpsCallable(functions, "fetchAnalyticsData");

  const fetchMyCharts = useCallback(
    async (request: { period: string }) => {
      setLoadingMyCharts(true);
      try {
        const response: HttpsCallableResult = await fetchAnalyticsData(request);
        const responseData = response.data as ChartDataResponse;
        setVolume(responseData.volume);
        setCumulativeVolume(responseData.cumulativeVolume);
        setOffers(responseData.offers);
        setSingleValues(responseData.singleValues);
        setMyChartsError(null);
      } catch (err) {
        if (err instanceof Error) {
          setMyChartsError(err.message);
        } else {
          setMyChartsError("An unexpected error occurred.");
        }
        setVolume([]);
        setOffers([]);
      } finally {
        setLoadingMyCharts(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    fetchMyCharts,
    loadingMyCharts,
    volume,
    cumulativeVolume,
    offers,
    myChartsError,
    singleValues,
  };
};
