import { useCallback, useState } from "react";
import { useModalStore } from "../..";
import StepButtons from "../../components/StepButtons";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebaseConfig";
import { useUserStore } from "../../../store";
import { setNotificationState } from "../../../notification";
import GeneralNotification from "../../../notification/Notifications/GeneralNotification";

const CancelLoanRequestModal = ({ request }: { request: any }) => {
  const { close, onSubmit } = useModalStore();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const ordAddress = useUserStore((state) => state.ordAddress);
  const btcAddress = useUserStore((state) => state.btcAddress);

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    const cancelLoan = httpsCallable(functions, "cancelLoan");
    try {
      await cancelLoan({ loanId: request.id });
      
      setSubmitting(false);
      setNotificationState(() => (
        <GeneralNotification text={"Loan request canceled"} variant="success" />
      ));
    } catch (error) {
      setSubmitting(false);
      setNotificationState(() => (
        <GeneralNotification
          text={"Error canceling loan"}
          variant="error"
        />
      ));
    }

    if (onSubmit) onSubmit();
    close();
    setSubmitting(false);
  }, [request.id, ordAddress, btcAddress, onSubmit, close]);

  const details = useCallback(() => {
    if (request.status === "requested") {
      return "The loan has not started yet because no one has accepted it.";
    } else if (request.lender.id === ordAddress) {
      return "The loan has not started yet because you still have to sign.";
    } else if (request.borrower.id === ordAddress) {
      return "The loan has not started yet because the Lender still has to sign.";
    }
  }, [ordAddress]);

  return (
    <div className="border border-white/20  sm:w-[85vw] w-[100vw] max-w-[660px] bg-gray-800 sm:p-7 p-4 rounded-2xl flex flex-col sm:mx-3 mx-0">
      <p className="sm:text-2xl  text-xl font-extrabold text-gray-50 pb-6">
        {details()} Do you want to cancel?
      </p>
      <StepButtons
        leftText="Cancel"
        rightText="Confirm"
        isSubmitting={submitting}
        leftOnClick={close}
        rightOnClick={handleSubmit}
      />
    </div>
  );
};

export default CancelLoanRequestModal;
