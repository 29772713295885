import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Airdrop, UserAnalytics } from "./types";

//
// USER STORE
//

type UserSettings = {
  isSat: boolean;
  isEarlyAccess: boolean;
  wallet: "hiro" | "xverse" | "leather" | "unisat" | undefined;
  btcBalance: number;
  btcAddress: string;
  btcPubKey: string;
  ordAddress: string;
  ordPubKey: string;
  userAnalytics: UserAnalytics;
  derivationPath: string;
  authenticatingAccount: boolean;
  airdrops: Airdrop[];
  earlyAccessAddresses: string[];
  setIsSat: (newValue: boolean | ((prevValue: boolean) => boolean)) => void;
  setIsEarlyAccess: (newValue: boolean) => void;
  setEarlyAccessAddresses: (newValue: string[]) => void;
  setWallet: (
    newValue: "hiro" | "xverse" | "leather" | "unisat" | undefined,
  ) => void;
  setDerivationPath: (newValue: string) => void;
  setBtcBalance: (newValue: number) => void;
  setBtcAddress: (newValue: string) => void;
  setBtcPubKey: (newValue: string) => void;
  setOrdAddress: (newValue: string) => void;
  setUserAnalytics: (newValue: UserAnalytics) => void;
  setOrdPubKey: (newValue: string) => void;
  setAirdrops: (newValue: Airdrop[]) => void;
  setAuthenticatingAccount: (newValue: boolean) => void;
  theme: "light" | "dark";
  toggleTheme: Function;
};

const userStore = (set: any): UserSettings => ({
  isSat: false,
  isEarlyAccess: false,
  wallet: undefined,
  btcBalance: 0,
  btcAddress: "",
  btcPubKey: "",
  ordAddress: "",
  ordPubKey: "",
  derivationPath: "",
  authenticatingAccount: false,
  airdrops: [],
  earlyAccessAddresses: [],
  userAnalytics: null,
  setUserAnalytics: (newValue: UserAnalytics) =>
    set(() => ({
      userAnalytics: newValue,
    })),
  setEarlyAccessAddresses: (newValue: string[]) =>
    set(() => ({
      earlyAccessAddresses: newValue,
    })),
  setIsSat: (newValue: boolean | ((prevValue: boolean) => boolean)) =>
    set((state: UserSettings) => ({
      isSat: typeof newValue === "function" ? newValue(state.isSat) : newValue,
    })),
  setIsEarlyAccess: (newValue: boolean) =>
    set(() => ({
      isEarlyAccess: newValue,
    })),
  setWallet: (newValue: "hiro" | "xverse" | "leather" | "unisat" | undefined) =>
    set(() => ({
      wallet: newValue,
    })),
  setBtcBalance: (newValue: number) =>
    set(() => ({
      btcBalance: newValue,
    })),
  setBtcAddress: (newValue: string) =>
    set(() => ({
      btcAddress: newValue,
    })),
  setBtcPubKey: (newValue: string) =>
    set(() => ({
      btcPubKey: newValue,
    })),
  setOrdAddress: (newValue: string) =>
    set(() => ({
      ordAddress: newValue,
    })),
  setOrdPubKey: (newValue: string) =>
    set(() => ({
      ordPubKey: newValue,
    })),
  setDerivationPath: (newValue: string) =>
    set(() => ({
      ordPubKey: newValue,
    })),
  setAuthenticatingAccount: (newValue: boolean) =>
    set(() => ({
      authenticatingAccount: newValue,
    })),
  setAirdrops: (newValue: Airdrop[]) =>
    set(() => ({
      airdrops: newValue,
    })),
  theme: "dark",
  toggleTheme: () =>
    set((state: any) => ({
      theme: state.theme === "light" ? "dark" : "light",
    })),
});

export const useUserStore = create(
  persist(userStore, { name: "user_settings" }),
);

//
// PAGE STORE
//

type PageStore = {
  lendPage: number;
  borrowPage: number;
  browseActivePage: number;
  browseRequestsPage: number;
  setLendPage: (newValue: number) => void;
  setBorrowPage: (newValue: number) => void;
  setBrowseActivePage: (newValue: number) => void;
  setBrowseRequestsPage: (newValue: number) => void;
};

const pageStore = (set: any): PageStore => ({
  lendPage: 1,
  borrowPage: 1,
  browseActivePage: 1,
  browseRequestsPage: 1,
  setLendPage: (newValue: number) =>
    set(() => ({
      lendPage: newValue,
    })),
  setBorrowPage: (newValue: number) =>
    set(() => ({
      borrowPage: newValue,
    })),
  setBrowseActivePage: (newValue: number) =>
    set(() => ({
      browseActivePage: newValue,
    })),
  setBrowseRequestsPage: (newValue: number) =>
    set(() => ({
      browseRequestsPage: newValue,
    })),
});

export const usePageStore = create(pageStore);

//
// COLLECTION STORE
//

type FloorPriceInfo = {
  price: number;
  expiry: number;
};

type PopularCollectionInfo = {
  data: any[];
  expiry: number;
};

type CollectionStore = {
  floorPrices: { [collectionSymbol: string]: FloorPriceInfo };
  setFloorPrice: (collectionSymbol: string, floorPrice: number) => void;
  popularCollectionsCache: PopularCollectionInfo | null;
  setPopularCollections: (data: any[]) => void;
  selectedCollections: string[];
  setSelectedCollections:
    | ((collections: string[]) => void)
    | ((callback: (prev: string[]) => string[]) => void);
};

const collectionStore = (set: any): CollectionStore => ({
  floorPrices: {},
  setFloorPrice: (collectionSymbol: string, floorPrice: number) =>
    set((state: CollectionStore) => ({
      floorPrices: {
        ...state.floorPrices,
        [collectionSymbol]: {
          price: floorPrice,
          expiry: Date.now() + 24 * 60 * 60 * 1000,
        },
      },
    })),
  popularCollectionsCache: null,
  setPopularCollections: (data: any[]) =>
    set(() => ({
      popularCollectionsCache: {
        data: data,
        expiry: Date.now() + 60 * 60 * 1000, // 1 hour in milliseconds
      },
    })),
  selectedCollections: [],
  setSelectedCollections: (
    collections: string[] | ((prev: string[]) => string[]),
  ) =>
    set((state: CollectionStore) => ({
      selectedCollections: Array.isArray(collections)
        ? collections
        : collections(state.selectedCollections),
    })),
});

export const useCollectionStore = create(collectionStore);

//
// STATS STORE
//

type StatsPlatformInfo = {
  data: any;
  expiry: number;
};

type StatsStore = {
  platformStatsCache: StatsPlatformInfo | null;
  setPlatformStats: (data: any) => void;
};

const statsStore = (set: any): StatsStore => ({
  platformStatsCache: null,
  setPlatformStats: (data: any) =>
    set(() => ({
      platformStatsCache: {
        data: data,
        expiry: Date.now() + 60 * 60 * 1000, // 1 hour in milliseconds
      },
    })),
});

export const useStatsStore = create(statsStore);

//
// ERROR STORE
//

type ErrorStore = {
  invalidTrxErrorId: string | null;
  setInvalidTrxErrorId: (id: string | null) => void;
};

const errorStore = (set: any): ErrorStore => ({
  invalidTrxErrorId: null,
  setInvalidTrxErrorId: (id: string | null) =>
    set(() => ({
      invalidTrxErrorId: id,
    })),
});

export const useErrorStore = create(errorStore);
