import { useState, useCallback } from "react";
import { httpsCallable } from "@firebase/functions";
import { functions } from "../../../firebaseConfig";
import { UserData } from "../../../types";

export const useGetUserData = () => {
  const [loadingUserData, setLoadingUserData] = useState<boolean>(false);
  const [errorUserData, setErrorUserData] = useState<string | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);

  const fetchUserData = useCallback(async (id: string) => {
    if (!id || id === "") {
      return;
    }

    setLoadingUserData(true);
    try {
      const getUser = httpsCallable(functions, "getUser");
      const response = await getUser({ id });
      const responseData = response.data as UserData | { success: false };

      if ("success" in responseData && responseData.success === false) {
        throw new Error("User does not exist.");
      }
      setUserData(responseData as UserData);
      setErrorUserData(null);
    } catch (err) {
      if (err instanceof Error) {
        setErrorUserData(err.message);
      } else {
        setErrorUserData("An unexpected error occurred.");
      }
      setUserData(null);
    } finally {
      setLoadingUserData(false);
    }
  }, []);

  return {
    fetchUserData,
    loadingUserData,
    userData,
    errorUserData,
  };
};
