import React, { useCallback, useEffect, useState } from "react";
import TweetButton from "../../../components/Reusable/TweetButton";
import greenCheck from "../../../assets/icons/green-check.svg";
import twitter from "../../../assets/icons/twitter.svg";
import discord from "../../../assets/icons/discord.svg";
import link from "../../../assets/icons/link-icon.svg";
import { setModalState, useModalStore } from "../..";
import EmailSignup from "../EmailSignup";
import config from "../../../config";
import { useGetUserData } from "../../../hooks/liquidium/useGetUserData";
import { useUserStore } from "../../../store";

type mediaButtonsType = {
  name: string;
  icon: string;
  color: string;
  link?: string;
};

//This method Isn't optimal unless we plan to support more links

const ShareModal = ({
  newLoan,
  type,
  numberOfOffers,
  isBRC20,
}: {
  numberOfOffers?: number;
  newLoan?: any;
  type?: "borrow" | "lend" | "activateLoan";
  isBRC20?: boolean;
}) => {
  const { close } = useModalStore();
  const { fetchUserData, loadingUserData, userData } = useGetUserData();
  const [copied, setCopied] = useState(false);
  const ordAddress = useUserStore((state) => state.ordAddress);

  const openEmailSignupModal = useCallback(() => {
    setModalState(
      "EmailSignup",
      () => {},
      () => <EmailSignup onCustomClose={close} />,
      false,
      false,
    );
  }, [close]);

  const handleCopyLink = useCallback(async (link) => {
    try {
      await navigator.clipboard.writeText(link);
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    } catch (error) {
      console.error("Failed to copy link:", error);
    }
  }, []);

  //Fetches user data
  useEffect(() => {
    if (!userData) fetchUserData(ordAddress);
  }, [userData, ordAddress, fetchUserData]);

  return (
    <div className="bg-gray-800 rounded-2xl  p-7 flex flex-col  w-[90vw] max-w-[620px] justify-center items-center sm:gap-11 gap-7 border border-white/20">
      <p className="text-3xl text-gray-50 font-black">Success!</p>
      <img src={greenCheck} className="w-24 h-24" alt="Green Check"></img>
      {type === "borrow" && (
        <p className=" text-base sm:text-md text-gray-50 font-bold">
          Lender notified, awaiting their signature. The loan will start once
          the Lender signs.
        </p>
      )}
      {(type === "lend" || type === "activateLoan") && (
        <div className="w-full">
          <p className="text-xs sm:text-sm font-semibold text-gray-50 w-full text-left mb-2">
            {type === "lend"
              ? `Share your offer${numberOfOffers ? "s" : ""}`
              : "Share your loan"}
          </p>
          <div
            className={`grid grid-cols-1 sm:grid-cols-${
              type === "activateLoan" ? "2" : "3"
            }  w-full justify-between sm:gap-4 gap-2 `}
          >
            <TweetButton
              loan={newLoan}
              widthFull={true}
              activatedLoan={type === "activateLoan"}
              numberOfOffers={numberOfOffers ? numberOfOffers : undefined}
              isBRC20={isBRC20}
            />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://liquidium.link/discord"
              className={`flex justify-center items-center h-12 text-xs sm:text-sm text-gray-50 font-black w-full rounded-2xl gap-2 cursor-pointer hover:opacity-90 border border-white/20 bg-[#5865F2]`}
            >
              <img src={discord} alt="Discord btn" className="h-4 w-6 "></img>
              Discord
            </a>
            {type === "lend" && (
              <button
                onClick={() =>
                  handleCopyLink(
                    `${config.AppUrl}/${
                      isBRC20 ? "brc20/borrow/" : "ordinals/borrow"
                    }/${newLoan?.collectionSymbol}`,
                  )
                }
                className={`flex justify-center items-center h-12 text-xs sm:text-sm text-gray-50 font-black w-full rounded-2xl gap-2 cursor-pointer hover:opacity-80 border border-white/20 bg-[#52525B]`}
              >
                <img src={link} alt="Copy link btn" className="h-4 w-6"></img>
                {copied ? "Copied!" : "Copy Link"}
              </button>
            )}
          </div>
        </div>
      )}

      {/* display enable notifications if they don't have all notification options linked */}
      {!(
        userData?.telegramChatId &&
        userData.telegramUsername &&
        !userData?.email &&
        !loadingUserData
      ) && (
        <button
          onClick={() => openEmailSignupModal()}
          className="bg-purple-700 rounded-2xl sm:text-2xl text-xl font-extrabold text-gray-50 px-12 py-3 w-full border border-white/20 flex items-center justify-center flex-col text-center hover:bg-opacity-80"
        >
          Get Important Notifications
          <p className="text-xs sm:text-sm">Recommended</p>
        </button>
      )}
      <button
        onClick={close}
        className="bg-gray-500 rounded-2xl  sm:text-2xl text-xl font-extrabold text-gray-50 px-12 py-3 w-full border border-white/20 hover:bg-opacity-80"
      >
        CLOSE
      </button>
    </div>
  );
};

export default ShareModal;
