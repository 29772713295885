import { FC } from "react";
import { Offer } from "../../../../types";
// import Loading from "../../../components/Loading";
import TitleWithSearchSort from "../../../../components/Reusable/TitleWithSearchSort/TitleWithSearchSort";
import Loading from "../../../../components/Reusable/Loading";
import RowCard from "../../../../components/Reusable/PortfolioRowCard/PortfolioRowCard";

interface IActiveBorrowings {
  items: Offer[];
  isLoading?: boolean;
  onClick: Function;
  loadingRowId: number | null;
}

const ActiveBorrowings: FC<IActiveBorrowings> = ({
  items,
  isLoading,
  onClick,
  loadingRowId,
}) => {
  // const [searchInput, setSearchInput] = useState<string>("");

  return (
    <div className="w-full  ">
      <TitleWithSearchSort
        title="Your Active Borrowings"
        onSortToggle={() => {}}
        ascending={false}
        // onSearchInput={setSearchInput}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {items.length === 0 ? (
            <p className="text-xl text-gray-400 font-bold w-full text-center pt-4">
              No active borrowings
            </p>
          ) : (
            <div className="grid grid-flow-row gap-[10px] sm:gap-3">
              {items.map((item, i) => (
                <RowCard
                  type="activeBorrowings"
                  key={"activeBorrowings" + i}
                  isLoading={i === loadingRowId}
                  index={i}
                  offer={item}
                  onClick={onClick}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ActiveBorrowings;
