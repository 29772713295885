import { FC, useEffect, useMemo } from "react";
import GraphCard from "./components/GraphCard/GraphCard";
import { useGetMyCharts } from "../../hooks/liquidium/Analytics/useGeyMyCharts";
import ValueCard from "./components/ValueCard/ValueCard";
import { setNotificationState } from "../../notification";
import GeneralNotification from "../../notification/Notifications/GeneralNotification";
import InformationBar from "../../modal/components/InformationBar";
import Big from "big.js";
import useBtcPrice from "../../hooks/api/useBtcPrice";

const Analytics: FC = () => {
  const {
    fetchMyCharts,
    volume,
    offers,
    cumulativeVolume,
    myChartsError,
    singleValues,
    loadingMyCharts,
  } = useGetMyCharts();
  const { btcPrice, isLoading } = useBtcPrice();

  //Fetch data on page load
  useEffect(() => {
    fetchMyCharts({ period: "all" });
  }, [fetchMyCharts]);

  //Listen for error
  useEffect(() => {
    if (myChartsError) {
      setNotificationState(() => (
        <GeneralNotification text={myChartsError} variant="error" />
      ));
    }
  }, [myChartsError]);

  //Refetch data with time period
  const handleTimeRangeChange = (range: string) => {
    fetchMyCharts({ period: range });
  };

  // Helper to calculate USD value
  const calculateUSDValue = (value?: number) => {
    if (!value) return;
    return `$${Big(!isLoading && btcPrice ? btcPrice : 0)
      .mul(value)
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  const valueCardData = useMemo(() => {
    return [
      {
        value: singleValues?.totalVolume,
        title: "Total Volume",
        denomination: "₿",
        tooltip:
          "Total loan volume for each day. Indicates the sum of all completed and active loan values for the specified day.",
        secondValue: calculateUSDValue(singleValues?.totalVolume),
      },
      {
        value: singleValues?.TALV,
        title: "Available Liquidity",
        denomination: "₿",
        tooltip: "Total amount of value in active offerings.",
        secondValue: calculateUSDValue(singleValues?.TALV),
      },
      {
        value: singleValues?.avgLoanAmount,
        title: "AVG Loan Size",
        denomination: "₿",
        tooltip:
          "Average loan amount for the selected period. Calculated by dividing the total loan volume by the number of active/completed loans.",
        secondValue: calculateUSDValue(singleValues?.avgLoanAmount),
      },
      {
        value: singleValues?.avgActiveOfferAmount,
        title: "AVG Active Offer",
        denomination: "₿",
        tooltip:
          "Average amount for loans that are in an active state. Calculated by dividing the sum of active loan amounts by the number of active loans.",
        secondValue: calculateUSDValue(singleValues?.avgActiveOfferAmount),
      },
      {
        value: singleValues?.completedLoansCount,
        title: "Completed Loans",
        tooltip:
          "Number of loans that have been completed during the selected period. Repaid or liquidated.",
      },
      {
        value: singleValues?.activeLoans,
        title: "Active Loans",
        tooltip:
          "Number of active loans that are currently for the selected period.",
      },
      {
        value: singleValues?.totalOffers,
        title: "Cumulative Offers",
        tooltip:
          "Total number of loan offers made during the selected period. Includes all status types of loans.",
      },
      {
        value: singleValues?.activeOffersCount,
        title: "Active Offers",
        tooltip:
          "Number of loan offers that are currently in a requested state and haven't been accepted.",
      },
      {
        value: singleValues?.cancelledOffersCount,
        title: "Cancelled Offers",
        tooltip:
          "Number of loan offers that have been cancelled during the selected period.",
      },
      {
        value: singleValues?.defaultRate,
        title: "Default Rate",
        denomination: "%",
        tooltip:
          "Percentage of loans that have been defaulted during the selected period. Calculated by dividing the number of defaulted loans by the number of completed loans.",
      },
    ];
  }, [singleValues]);

  return (
    <div className=" flex justify-center w-full">
      <div className="flex w-full flex-col sm:mt-10 mt-6 gap-4  mb-20 max-w-[1400px]">
        <div className="flex justify-between items-center ">
          <p className=" text-zinc-50 font-semibold sm:text-2xl text-xl">
            Analytics
          </p>
          <select
            onChange={(e) => handleTimeRangeChange(e.target.value)}
            className="border rounded p-1 bg-black border-white/40 hover:border-white text-white/40 font-medium sm:text-sm text-xs "
          >
            <option value="all">All</option>
            <option value="30days">30 days</option>
            <option value="7days">7 days</option>
          </select>
        </div>
        <div
          className={`flex w-full flex-col gap-4 ${
            loadingMyCharts && "animate-pulse"
          }`}
        >
          <div className="flex-col gap-4 md:flex-row flex ">
            <GraphCard
              data={volume}
              title="Volume"
              tooltip={
                "Total loan volume for each day. Indicates the sum of all the active and completed loan values"
              }
            />
            <GraphCard
              data={offers}
              title="Offers"
              tooltip="Total number of loan offers made during the selected period. Includes all status types of loans. This includes loans canceled in this period."
            />
          </div>
          <div className="flex gap-4">
            <GraphCard
              data={cumulativeVolume}
              title="Cumulative Volume"
              tooltip="Cumulative loan volume over the selected period. Shows the running total of completed and active loan values up to and including each day."
            />
          </div>
          <div className="gap-4 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 ">
            {valueCardData.map((card, index) => (
              <ValueCard
                key={index}
                value={card.value}
                secondValue={card?.secondValue}
                title={card.title}
                denomination={card.denomination}
                tooltip={card.tooltip}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
