import { StacksTestnet, StacksMainnet } from "@stacks/network";

interface ConfigProps {
  Env: "dev" | "test" | "prod";
  Network: StacksMainnet | StacksTestnet;
  NetworkName: "mainnet" | "testnet";
  AppUrl: string;
}

const Config: ConfigProps = {
  // Mainnet
  Env: "prod",
  Network: new StacksMainnet(),
  NetworkName: "mainnet",
  AppUrl: "https://app.liquidium.fi",

  // Testnet
  // Env: "dev",
  // Network: new StacksTestnet(),
  // NetworkName: "testnet",
  // AppUrl: "http://localhost:3000",
};

export default Config;
