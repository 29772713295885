import { FC, useCallback } from "react";
import { useModalStore } from "../..";
import xverseLogo from "../../../assets/logos/xverse_icon_whitecolor.svg";
import leatherLogo from "../../../assets/logos/leather_icon_black.svg";
import unisatLogo from "../../../assets/logos/unisat_icon_colour.svg";
import magiceden from "../../../assets/featured/magiceden.svg";
import useAuth from "../../../auth";
import { WalletType } from "../../../types";
import InformationBar from "../../components/InformationBar";

interface SelectWalletModalProps {
  onClose?: Function;
  reAuthenticate?: boolean;
}

const SelectWalletModal: FC<SelectWalletModalProps> = ({
  onClose,
  reAuthenticate,
}) => {
  const { onConnectClick, isUserSignedIn } = useAuth();
  const { close } = useModalStore();

  const handleConnect = useCallback(
    async (wallet: WalletType) => {
      if (wallet) {
        onConnectClick(wallet);
        close();
      }
    },
    [onConnectClick]
  );

  return (
    <div className="sm:w-[85vw] w-[100vw] max-w-[450px] bg-gray-800 sm:p-7 p-5 rounded-2xl flex flex-col sm:mx-3 mx-0 border border-white/20 gap-7">
      <p className="sm:text-2xl text-xl font-black text-gray-50">
        {reAuthenticate ? "Re-Authenticate" : "Select Wallet"}
      </p>
      {reAuthenticate && (
        <InformationBar
          text="Your session has expired. Please re-connect and
        authenticate with your wallet."
          gray
        />
      )}
      <div className="grid grid-cols-3">
        <button
          className="flex flex-col justify-center items-center hover:opacity-70 transition-opacity gap-1"
          onClick={() => handleConnect("xverse")}
        >
          <div className="flex justify-center items-center w-14 h-14 border-[1px] border-white/20 bg-[#181818] rounded-xl">
            <img src={xverseLogo} alt="xverse logo" className="w-6 h-6" />
          </div>
          <p className="font-semibold text-gray-50 sm:text-lg text-base ">
            Xverse
          </p>
        </button>
        <button
          className="flex flex-col justify-center items-center hover:opacity-70 transition-opacity gap-1"
          onClick={() => handleConnect("magic_eden")}
        >
          <img
            src={magiceden}
            alt="magic eden logo"
            className="w-14 h-14 border-[1px] border-white/20 rounded-xl"
          />

          <p className="font-semibold text-gray-50 sm:text-lg text-base ">
            Magic Eden
          </p>
        </button>
        {/* <button
          className="flex flex-col justify-center items-center hover:opacity-70 transition-opacity gap-1"
          onClick={() => handleConnect("unisat")}
        >
          <div className="flex justify-center items-center w-14 h-14 border-[1px] border-white/20 bg-[#181818] rounded-xl">
            <img src={unisatLogo} alt="unisats logo" className="w-6 h-8" />
          </div>
          <p className="font-semibold text-gray-50 sm:text-lg text-base ">
            UniSat
          </p>
        </button> */}
      </div>
      <p className="text-xs sm:text-sm text-gray-400 font-normal">
        By connecting a wallet, you agree to Liquidium's{" "}
        <a
          className="font-extrabold hover:underline cursor-pointer text-white"
          target="_blank"
          href="https://liquidium.link/terms"
        >
          Terms of Service
        </a>{" "}
        and consent to its{" "}
        <a
          className="font-extrabold hover:underline cursor-pointer text-white"
          target="_blank"
          href="https://liquidium.link/privacy"
        >
          Privacy Policy
        </a>
        .
      </p>

      <button
        onClick={close}
        className="bg-gray-600 hover:bg-gray-500 transition-all border border-white/20 rounded-xl w-full p-3 font-extrabold text-gray-50 text-2xl"
      >
        Close
      </button>
    </div>
  );
};

export default SelectWalletModal;
