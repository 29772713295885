import { useEffect } from "react";
import styles from "./FeeSelector.module.css";
import useFees from "../../../hooks/api/useFees";
import { FeeObject, FeeRateOptions } from "../../../types";

const FeeSelector = ({
  selectedFeeRate,
  setSelectedFeeRate,
}: {
  selectedFeeRate: FeeObject | undefined;
  setSelectedFeeRate: (fee: FeeObject) => void;
}) => {
  const { fees, loading, error } = useFees();

  useEffect(() => {
    if (fees && !error) {
      setSelectedFeeRate({ label: FeeRateOptions.Medium, value: fees.medium });
    }
  }, [fees, error, setSelectedFeeRate]);

  if (loading || !selectedFeeRate)
    return (
      <p className="text-white text-center w-full font-bold">
        Loading Network Fees...
      </p>
    );
  if (error)
    return (
      <p className="text-white text-center w-full font-bold">Error fetching Network Fees.</p>
    );

  const renderFeeOption = (fee: FeeObject, index: number) => (
    <div
      className={`py-2 border border-white/20 ${
        selectedFeeRate.value === fee.value ? styles.selected : styles.option
      } ${index === 0 ? "rounded-tl-md rounded-bl-md" : ""} ${
        index === 2 ? "rounded-tr-md rounded-br-md" : ""
      }`}
      onClick={() => setSelectedFeeRate(fee)}
      key={fee.label}
    >
      <p className="text-sm text-gray-50 text-center font-black">{fee.label}</p>
      <p className="font-semibold text-center text-sm text-gray-100 whitespace-nowrap">
        ~{fee.value} sats/vB
      </p>
    </div>
  );

  const feeLevels = [
    { label: FeeRateOptions.Low, value: fees.low },
    { label: FeeRateOptions.Medium, value: fees.medium },
    { label: FeeRateOptions.High, value: fees.high },
  ];

  return (
    <div className={styles.component}>
      <p className="text-white text-center w-full font-bold mb-1">
        Network Fee
      </p>
      <div className="flex">
        {feeLevels.map((fee, index) => renderFeeOption(fee, index))}
      </div>
    </div>
  );
};

export default FeeSelector;
