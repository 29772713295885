import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUserStore } from "../../../store";
import styles from "./Loading.module.css";
import loading from "../../../assets/assets/loading-blob.svg";

const Loading = () => {
  const theme = useUserStore((state) => state.theme);
  return (
    <div className="flex w-full justify-center items-center h-full">
      <img src={loading} alt="Loading Animation" className="h-full"></img>
    </div>
  );
};

export default Loading;
