import { FC } from "react";
import { useModalStore } from "../..";

interface SignatureModalProps {
  onSignatureTypeSelect: (type: "Auto" | "Manual") => void;
}

const SignatureModal: FC<SignatureModalProps> = ({ onSignatureTypeSelect }) => {
  const { close } = useModalStore();
  const handleCardClick = (type: "Auto" | "Manual") => {
    onSignatureTypeSelect(type);
    close();
  };

  // TODO:maybe we only close modal after signature processed?
  return (
    <div className="sm:w-[85vw] w-[100vw] max-w-[660px] bg-gray-800 sm:p-8 p-5 rounded-3xl  grid sm:grid-cols-2 grid-cols-1 sm:gap-6 gap-4 ">
      <div
        className="flex flex-col text-gray-50 bg-purple-700 p-5 border border-white/20 rounded-2xl gap-2 cursor-pointer hover:bg-opacity-80 transition-colors"
        onClick={() => handleCardClick("Auto")}
      >
        <p className="sm:text-2xl text-xl font-extrabold">Auto Signature</p>
        <p className="sm:text-sm text-xs font-black">Recommended</p>
        <p className="sm:text-sm text-xs font-semibold">
          DeepLake’s Oracle will countersign automatically once a borrower
          accepts. You don’t have to return to the app and the loan will be
          instant.
        </p>
      </div>
      <div
        className="flex flex-col text-gray-50 bg-gray-600 p-5 border border-white/20 rounded-2xl gap-2 cursor-pointer hover:bg-opacity-80 transition-colors"
        onClick={() => {
          handleCardClick("Manual");
        }}
      >
        <p className="sm:text-2xl text-xl font-extrabold">Manual Signature</p>
        <p className="sm:text-sm text-xs font-semibold">
          Once a borrower accepts your offer you must countersign manually. This
          solution is trust-minimal, but you have to return to the app to
          countersign and need to enable notifications for this.{" "}
        </p>
      </div>
    </div>
  );
};

export default SignatureModal;
