import React, { useCallback, useEffect, useState } from "react";
import infoIcon from "../../../assets/icons/info_icon_filled.svg";
// import useDLMyLoans from "../../../hooks/deeplake/useDLMyLoans";
import useDeepLake from "../../../hooks/deeplake/useDeepLake";
import {
  ActiveBorrowingLendingsStats,
  LoanType,
  Offer,
  SortType,
} from "../../../types";
import ActiveBorrowings from "./ActiveBorrowings/ActiveBorrowings";
import LoanOffers from "./LoanOffers/LoanOffers";
import PendingSignature from "./PendingSignature/PendingSignature";
import ActiveLendings from "./ActiveLendings/ActiveLendings";
import { useGetMyOffers } from "../../../hooks/liquidium/useGetMyOffers";
import LoadingBar from "../../../components/Reusable/LoadingBar/Loading";
import { setModalState } from "../../../modal";
import RepayModal from "../../../modal/Modals/RepayModal/RepayModal";
import LendModal from "../../../modal/Modals/LendModal/LendModal";
import Constants, {
  defaultAPY,
  defaultBestOffer,
  defaultDuration,
  defaultFloorPrice,
  defaultLTVRatio,
} from "../../../constants";
import { checkExpiryAndDaysRemaining, convertSatToBtc } from "../../../utils";
import NoContentMessage from "../../../components/Reusable/NoContentMessage";
import CancelLoanRequestModal from "../../../modal/Modals/CancelLoanRequestModal";
import { useErrorStore, useUserStore } from "../../../store";
import { setNotificationState } from "../../../notification";
import GeneralNotification from "../../../notification/Notifications/GeneralNotification";
import Config from "../../../config";
import history from "../../../assets/icons/history.svg";
import { useNavigate } from "react-router";
import InformationBar from "../../../modal/components/InformationBar";
import useFees from "../../../hooks/api/useFees";
import UserStats from "./UserStats/UserStats";
import { useGetOffersBest } from "../../../hooks/liquidium/useGetOffersBest";
import ClaimOrdinalModal from "../../../modal/Modals/ClaimOrdinalModal/Index";
import V3Notification from "../../../components/V3Notification/V3Notification";

const Portfolio = () => {
  const ordAddress = useUserStore((state) => state.ordAddress);
  const invalidTrxErrorId = useErrorStore((state) => state.invalidTrxErrorId);
  const setInvalidTrxErrorId = useErrorStore(
    (state) => state.setInvalidTrxErrorId,
  );
  const [isActionInProgress, setActionInProgress] = useState(false);
  const [showTitleSubText, setShowTitleSubText] = useState<boolean>(false);
  const {
    handleExecuteOutcome,
    handleUnlock,
    handleRebuildOffer,
    handleActivateOffer,
  } = useDeepLake();

  // const [stats, setStats] = useState<StatItem[]>([]);
  const [offers, setOffers] = useState<Offer[]>([]);
  const [pending, setPending] = useState<Offer[]>([]);
  const [lendings, setLendings] = useState<Offer[]>([]);
  const [borrowings, setBorrowings] = useState<Offer[]>([]);
  const navigate = useNavigate();
  const [loadingRowIdPending, setLoadingRowIdPending] = useState<number | null>(
    null,
  );
  const [loadingRowIdBorrowing, setLoadingRowIdBorrowing] = useState<
    number | null
  >(null);
  const [loadingRowIdLending, setLoadingRowIdLending] = useState<number | null>(
    null,
  );
  const [fetchMyOffersRequest, setFetchMyOffersRequest] = useState({
    userId: ordAddress,
    take: Constants.TAKE_COUNT,
    sortType: SortType.CreatedAt_DESC,
    lastDoc: null,
  });
  const { loadingOffers, offersBest, error, fetchOffersBest } =
    useGetOffersBest();

  const { loading, data, fetchMyOffers } = useGetMyOffers();
  const onSubmit = useCallback(
    () => fetchMyOffers(fetchMyOffersRequest),
    [fetchMyOffers, fetchMyOffersRequest],
  );
  const [fetchOffersBestRequest, setFetchOffersBestRequest] = useState({
    take: Constants.TAKE_COUNT,
    sortType: SortType.ME_Number_ASC,
  });

  //Cancel Loan Offer
  const handleCancel = useCallback(
    async (offer: Offer) => {
      setModalState(
        "cancel",
        onSubmit,
        () => <CancelLoanRequestModal request={offer} />,
        false,
        false,
      );
    },
    [onSubmit],
  );

  const { fees, loading: loadingFees, error: feeError } = useFees();
  const handleActivate = useCallback(
    async (request: LoanType, rowId: number) => {
      setLoadingRowIdPending(rowId);
      const stats = {
        bestOffer: { ...defaultBestOffer, value: request.principal },
        floorPrice: {
          ...defaultFloorPrice,
          value: convertSatToBtc(false, request?.collection?.floorPrice),
        },
        LTV: { ...defaultLTVRatio, value: request.ltv },
        duration: { ...defaultDuration, value: request.term },
        APY: { ...defaultAPY, value: request.interestRate },
      };

      if (invalidTrxErrorId === request.id) {
        if (!fees || loadingFees || feeError) {
          setNotificationState(() => (
            <GeneralNotification text="Still loading fees." variant="error" />
          ));
          setLoadingRowIdPending(null);
        }
        handleRebuildOffer(
          request,
          fees[request.feeRateOption ?? "medium"],
          async () => {
            setInvalidTrxErrorId(null);
            await onSubmit?.().then(() => {
              setLoadingRowIdPending(null);
            });
          },
        );
      } else if (request.status === "rebuilt") {
        handleActivateOffer(
          request,
          async () => {
            await onSubmit?.().then(() => {
              setLoadingRowIdPending(null);
            });
          },
          async () => {
            setInvalidTrxErrorId(request.id);
            setLoadingRowIdPending(null);
          },
        );
      } else {
        setLoadingRowIdPending(null);
        setModalState(
          "Lend",
          () => {},
          () => (
            <LendModal
              type="reviewAndSign"
              request={request}
              stats={stats}
              onSubmit={onSubmit}
              isBRC20={request.collateralType === "BRC20"}
            />
          ),
        );
      }
    },
    [
      invalidTrxErrorId,
      onSubmit,
      handleRebuildOffer,
      feeError,
      fees,
      handleActivateOffer,
      loadingFees,
      setInvalidTrxErrorId,
    ],
  );

  // Lender Claim Ordinal if Loan Expired
  const handleLendingClick = useCallback(
    async (
      request: Offer,
      stats: ActiveBorrowingLendingsStats,
      rowId: number,
    ) => {
      // If loan is not expired, do nothing
      const { isExpired } = checkExpiryAndDaysRemaining(request.endDate);
      if (!isExpired) return;

      // If
      if (rowId > -1) {
        setLoadingRowIdLending(rowId);
      }

      if (!loadingFees && fees) {
        setModalState(
          "Unlock",
          () => {},
          () => (
            <ClaimOrdinalModal
              request={request}
              claimType="liquidate"
              onSubmit={onSubmit}
              setLoadingRow={setLoadingRowIdLending}
              handleUnlock={handleUnlock}
            />
          ),
          false,
          false,
        );
      }
    },
    [fees, loadingFees, handleUnlock, onSubmit],
  );

  // Repay or Unlock Active Borrowing
  const handleBorrowingClick = useCallback(
    async (request, stats: ActiveBorrowingLendingsStats, rowId: number) => {
      if (rowId > -1) {
        setLoadingRowIdBorrowing(rowId);
      }
      if (request.status === "active") {
        setModalState(
          "Repay",
          () => {},
          () => (
            <RepayModal request={request} stats={stats} onSubmit={onSubmit} />
          ),
          false,
          false,
        );
        setLoadingRowIdBorrowing(null);
      } else if (request.status === "paid" && !loadingFees && fees) {
        setModalState(
          "Unlock",
          () => {},
          () => (
            <ClaimOrdinalModal
              request={request}
              claimType="unlock"
              onSubmit={onSubmit}
              setLoadingRow={setLoadingRowIdBorrowing}
              handleUnlock={handleUnlock}
            />
          ),
          false,
          false,
        );
      }
    },
    [fees, loadingFees, handleUnlock, onSubmit],
  );

  // Legacy Version Repay & Liquidate // TODO: remove when final loans are paid
  const handleRepayClick = useCallback(
    async (request: any) => {
      if (isActionInProgress || request.status === "repaying") return;
      setActionInProgress(true);
      await handleExecuteOutcome(request, "repay");
      setActionInProgress(false);
    },
    [handleExecuteOutcome, isActionInProgress],
  );

  const handleLiquidateClick = useCallback(
    async (request: any) => {
      if (isActionInProgress) return;
      if (request.status === "active") {
        // only allow liquidate if active and not expired
        if (request.endDate > Date.now()) {
          setNotificationState(() => (
            <GeneralNotification text="Loan is not expired." variant="error" />
          ));
        } else {
          setActionInProgress(true);
          await handleExecuteOutcome(request, "liquidate");
          setActionInProgress(false);
        }
      } else {
        window.open(
          `https://mempool.space/${
            Config.Network.isMainnet() ? "" : "testnet/"
          }address/${request.lender.id}`,
        );
      }
    },
    [handleExecuteOutcome, isActionInProgress],
  );

  const handleLoanHistoryClick = useCallback(() => {
    navigate("/settings/loan-history");
  }, [navigate]);

  useEffect(() => {
    onSubmit();
    return () => {
      setInvalidTrxErrorId(null);
    };
  }, [onSubmit, ordAddress, setInvalidTrxErrorId]);

  const [rebuiltOffer, setRebuiltOffer] = useState<Offer>();
  useEffect(() => {
    if (!data) return;
    if (data.offers) setOffers(data.offers);
    if (data.borrowings) setBorrowings(data.borrowings);
    if (data.lendings) setLendings(data.lendings);
    if (data.pending) {
      // Hide the expired loans so nobody can sign them
      const filteredPending = data.pending.filter((loan) => {
        const { isExpired } = checkExpiryAndDaysRemaining(loan.endDate);
        return !isExpired;
      });

      // If offer was rebuilt, grab it and set notification
      setRebuiltOffer(data.pending.find((item) => item.status === "rebuilt"));

      setPending(filteredPending);
    }
  }, [data]);

  //Fetch offers
  useEffect(() => {
    fetchOffersBest(fetchOffersBestRequest);
  }, [fetchOffersBest, fetchOffersBestRequest]);

  return (
    <div className="w-full m:mt-10 mt-10 mb-10 flex justify-center">
      <div className="max-w-[1400px] w-full">
        <V3Notification />
        <div className="w-full flex justify-between items-center">
          <p className="text-gray-50 font-extrabold sm:text-2xl text-xl flex items-center gap-2">
            My Portfolio
            <button
              className="bg-gray-600 border border-white/20 text-xs  flex justify-center items-center pl-1 pr-2 py-[2px] font-extrabold rounded-2xl gap-1 hover:bg-opacity-80 transition-color -mb-[2px]"
              onClick={() => setShowTitleSubText(!showTitleSubText)}
            >
              <img src={infoIcon} className="w-[16px] h-[16px]" alt=""></img>
              Info
            </button>
          </p>
          <button
            className="px-2 py-1 text-gray-50 transition-opacity sm:text-sm text-sm rounded-md font-semibold mt-4 flex items-center gap-1 opacity-70 hover:opacity-100"
            onClick={handleLoanHistoryClick}
          >
            <img src={history} className="w-5" alt="history icon"></img>
            Loan History
          </button>
        </div>
        {/* <div className="mt-4">
          <InformationBar text="Borrowing and Lending is temporarily disabled. You can still access and manage your active loans." />
          <br/>
          <InformationBar
            gray
            text="Due to high BTC fees, you may be seeing pending transactions stuck in the mempool, learn how to create a CPFP to speed up a transaction on our FAQ"
            externalLink="https://liquidium.link/faq-cpfp"
            externalLinkName="Learn More"
          />
        </div> */}
        {invalidTrxErrorId ? (
          <>
            <br />
            <InformationBar
              gray
              text={`Wallet stuck loading? Refresh and try again. If it's stuck again, wait for timeout and then click "Rebuild". The UTXOs from the transaction may have been spent.`}
            />
          </>
        ) : (
          rebuiltOffer && (
            <>
              <br />
              <InformationBar
                gray
                text={`The UTXOs from the transaction have been spent. Sign the green check if it's visible. Otherwise, wait for the other person to activate.`}
              />
            </>
          )
        )}
        {/* If trx was rebuilt */}
        {showTitleSubText && (
          <p className="text-gray-400 font-semibold text-xs sm:text-sm mt-2">
            Manage your loan requests, active loans, and confirm loans that are
            pending signature.
          </p>
        )}
        <UserStats />
        {!loading && !loadingOffers ? (
          <>
            {/* TODO: we will need to look at the types getting past, maybe using a default type for all the common props makes sense, then a subtype within them for the specializations */}
            {/* <div className="sm:mt-16 mt-12 sm:block hidden mb-4"> 
             <StatsSection
              statItems={stats}
              size="large"
              isLoading={stats.length === 0}
            /> 
          </div> */}
            {pending.length > 0 && (
              <div className="sm:mt-16 mt-14">
                <PendingSignature
                  items={pending}
                  onCancel={handleCancel}
                  onApprove={handleActivate}
                  loadingRowId={loadingRowIdPending}
                />
              </div>
            )}
            <div className="sm:mt-16 mt-12">
              {borrowings.length > 0 && (
                <ActiveBorrowings
                  items={borrowings}
                  onClick={handleBorrowingClick}
                  loadingRowId={loadingRowIdBorrowing}
                />
              )}
            </div>
            <div className="sm:mt-16 mt-12 ">
              {lendings.length > 0 && (
                <ActiveLendings
                  items={lendings}
                  onClick={handleLendingClick}
                  loadingRowId={loadingRowIdLending}
                />
              )}
            </div>
            {offers.length > 0 && (
              <div className="sm:mt-16 mt-14">
                <LoanOffers
                  items={offers}
                  onClick={handleCancel}
                  offersBest={offersBest}
                />
              </div>
            )}
            {pending.length === 0 &&
              borrowings.length === 0 &&
              lendings.length === 0 &&
              offers.length === 0 && (
                <NoContentMessage contentName="Data to Display" />
              )}
          </>
        ) : (
          <div className="mt-10">
            <LoadingBar />
          </div>
        )}
      </div>
    </div>
  );
};

export default Portfolio;
