import { useQuery } from "react-query";

const fetchBtcPrice = async () => {
  try {
    const response = await fetch(
      "https://api.coindesk.com/v1/bpi/currentprice/BTC.json"
    );
    const data = await response.json();
    return data.bpi.USD.rate_float;
  } catch (error) {
    // console.error("Error fetching BTC price:", error);
    throw error;
  }
};

const useBtcPrice = () => {
  const { data, isLoading, error } = useQuery("btcPrice", fetchBtcPrice, {
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    retry: 3,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
  });

  return {
    btcPrice: data ? Number(data.toFixed(2)) : null,
    isLoading,
    error,
  };
};

export default useBtcPrice;
