import infoImg from "../../../assets/icons/info-red.svg";
import infoImgGray from "../../../assets/icons/info_icon_filled.svg";

const InformationBar = ({
  text,
  disabled,
  gray,
  externalLinkName,
  externalLink,
}: {
  text: string | JSX.Element;
  disabled?: boolean;
  gray?: boolean;
  externalLink?: string;
  externalLinkName?: string;
}) => {
  if (disabled) return <></>;
  return (
    <div className="w-full bg-gray-600 rounded-xl px-4 py-3">
      <div className="flex sm:flex-row flex-col items-center gap-2 justify-between ">
        <div className="flex  items-center gap-2">
          {gray ? (
            <img
              className="h-5 w-5 opacity-70"
              src={infoImgGray}
              alt="info icon"
            />
          ) : (
            <img className="h-5" src={infoImg} alt="info icon" />
          )}
          <p className="sm:text-sm text-xs text-gray-50 font-semibold">
            {text}
          </p>
        </div>
        {externalLink && (
          <a
            className="text-xs font-medium text-zinc-900 bg-zinc-300 hover:bg-zinc-50 p-1 rounded-md sm:w-auto w-full text-center whitespace-nowrap px-2"
            href={externalLink}
            target="_blank"
          >
            {externalLinkName}
          </a>
        )}
      </div>
    </div>
  );
};

export default InformationBar;
