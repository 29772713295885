import { FC, useState } from "react";
import { Offer } from "../../../../types";
// import Loading from "../../../components/Loading";
import TitleWithSearchSort from "../../../../components/Reusable/TitleWithSearchSort/TitleWithSearchSort";
import Loading from "../../../../components/Reusable/Loading";
import RowCard from "../../../../components/Reusable/PortfolioRowCard/PortfolioRowCard";

interface ActiveLendingsProps {
  items: Offer[];
  isLoading?: boolean;
  onClick: Function;
  loadingRowId: number | null;
}

const ActiveLendings: FC<ActiveLendingsProps> = ({
  items,
  isLoading,
  onClick,
  loadingRowId,
}) => {
  const [sortOrder, setSortOrder] = useState<boolean>(true); //for sorting order
  // const [searchInput, setSearchInput] = useState<string>("");

  return (
    <div className="w-full  ">
      <TitleWithSearchSort
        title="Your Active Loans"
        onSortToggle={() => setSortOrder(!sortOrder)}
        ascending={sortOrder}
        // onSearchInput={setSearchInput}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {items.length === 0 ? (
            <p className="text-xl text-gray-400 font-bold w-full text-center pt-4">
              No active borrowings
            </p>
          ) : (
            <div className="grid grid-flow-row gap-[10px] sm:gap-3">
              {items.map((item, i) => (
                <RowCard
                  type="activeLendings"
                  key={"activeLendings" + i}
                  isLoading={i === loadingRowId}
                  offer={item}
                  onClick={onClick}
                  index={i}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ActiveLendings;
