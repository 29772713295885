import { FC, ReactElement, ReactHTML } from "react";
import StatusTag from "../StatusTag/StatusTag";
import { StatusTagType } from "../../../types";

interface InputSubmitProps {
  id?: number;
  submittedMessage?: string;
  isSubmitted: boolean;
  onEmailSubmit: Function;
  type: string;
  title: string;
  placeholder: string;
  isSubmitting: boolean;
  handleSetInput: Function;
  description?: string | ReactElement<ReactHTML>;
  status?: StatusTagType;
  value: string;
}

const InputSubmit: FC<InputSubmitProps> = ({
  id,
  onEmailSubmit,
  submittedMessage,
  type,
  placeholder,
  isSubmitted,
  isSubmitting,
  handleSetInput,
  title,
  description,
  status,
  value,
}) => {
  return (
    <div className="" key={"input" + id}>
      <div className="text-gray-400/90 sm:text-sm text-xs font-extrabold mb-2 flex justify-between ">
        {title}
        {status && <StatusTag type={status} small />}
      </div>
      {!isSubmitted ? (
        <form className="relative" onSubmit={(e) => onEmailSubmit(e, id)}>
          <input
            className="bg-black rounded-xl p-2 pl-2 text-white font-bold focus:outline-none focus:ring-1 focus:ring-white w-full text-xs sm:text-sm border border-white/20 mb-2 placeholder:text-gray-50/50"
            onChange={(e) => handleSetInput(e.target.value, id)}
            placeholder={placeholder}
            type={type}
            required
          />
          <button
            type="submit"
            className="absolute top-0 sm:text-sm text-xs font-semibold text-gray-50 bg-gray-500 disabled:bg-gray-600 disabled:text-gray-400 rounded-lg sm:px-4 px-3 h-[28px] sm:mt-[5px] mt-[3px]  sm:right-[5px] right-[3px] hover:bg-opacity-80 "
            disabled={value ? false : true}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </form>
      ) : (
        <input
          className="bg-gray-500/20 rounded-xl p-2 pl-2 text-white font-bold focus:outline-none w-full text-xs sm:text-sm border border-white/20 mb-2"
          placeholder={submittedMessage ? submittedMessage : "Submitted"}
          type={type}
          required
          disabled
        />
      )}
      {description && (
        <p className="text-[10px] sm:text-xs text-gray-400/80 font-semibold">
          {description}
        </p>
      )}
    </div>
  );
};

export default InputSubmit;
