import { useCallback, useEffect, useState } from "react";
import Notifications from "../../../pages/Settings/Notifications/Notifications";
import { useModalStore } from "../..";
import { useGetUserData } from "../../../hooks/liquidium/useGetUserData";
import { useUserStore } from "../../../store";
import LoadingBar from "../../../components/Reusable/LoadingBar/Loading";

interface EmailSignupProps {
  onCustomClose?: Function;
}

const EmailSignup = ({ onCustomClose }: EmailSignupProps) => {
  const { close } = useModalStore();
  const ordAddress = useUserStore((state) => state.ordAddress);
  const { fetchUserData, loadingUserData, userData } = useGetUserData();
  const [fetchingData, setFetchingData] = useState(true);

  const handleClose = useCallback(() => {
    if (onCustomClose) onCustomClose();
    close();
  }, [close, onCustomClose]);

  const refreshUserData = useCallback(() => {
    fetchUserData(ordAddress);
  }, [ordAddress]);

  useEffect(() => {
    fetchUserData(ordAddress);
  }, [fetchUserData, ordAddress]);

  useEffect(() => {
    if (!loadingUserData) {
      setFetchingData(false);
    } else if (!userData) {
      // Only trigger when userData is not populated yet
      setFetchingData(true);
    }
  }, [userData, loadingUserData]);

  return (
    <div className="border border-white/20 w-screen max-w-[450px] bg-gray-800 sm:p-7 p-4 rounded-2xl flex flex-col sm:mx-3 mx-0">
      <p className="font-extrabold sm:text-2xl text-xl text-gray-50 flex items-center mb-5">
        Get Notified
      </p>
      {fetchingData ? (
        <LoadingBar />
      ) : (
        <Notifications userData={userData} refreshUserData={refreshUserData} />
      )}
      <button
        onClick={() => handleClose()}
        className="bg-gray-600 transition-all hover:bg-gray-500 py-3 px-8 flex items-center flex-col justify-center sm:text-2xl text-xl font-extrabold text-gray-50 rounded-2xl border border-white/20 w-full mt-8"
      >
        Close
      </button>
    </div>
  );
};

export default EmailSignup;
