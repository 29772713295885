import React, { useEffect } from "react";
import Checkbox from "../../../components/Reusable/Checkbox";
import Loading from "../../../components/Reusable/Loading";
import useToggle from "../../../hooks/react/useToggle";
import styles from "./StepButtons.module.css";

const StepButtons = ({
  leftText,
  leftOnClick,
  rightText,
  rightOnClick,
  checkbox,
  disabled,
  isSubmitting = false,
  leftSubtitle,
  rightSubtitle,
}: {
  leftText?: string;
  leftOnClick?: Function;
  rightText?: string | React.ReactNode;
  rightOnClick?: Function;
  checkbox?: boolean;
  disabled?: boolean;
  isSubmitting?: boolean;
  leftSubtitle?: string;
  rightSubtitle?: string;
}) => {
  const [isChecked, handleToggle] = useToggle(false);

  useEffect(() => {
    if (rightOnClick) {
      const handleKeyDown = (event: any) => {
        if (event.key === "Enter" && !disabled) rightOnClick();
      };
      window.addEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [rightOnClick]);

  if (isSubmitting)
    return (
      <div className="h-[50px]">
        <Loading />
      </div>
    );
  else
    return (
      <div className={styles.component}>
        {checkbox && (
          <div className={styles.terms}>
            <Checkbox
              isChecked={isChecked}
              onClick={handleToggle}
              disabled={disabled}
            >
              Accept ToS and User Agreement
            </Checkbox>
          </div>
        )}
        <div className="grid grid-cols-2 sm:gap-10 gap-3 w-full ">
          <button
            onClick={() => leftOnClick && leftOnClick()}
            className="bg-gray-600 transition-all hover:bg-gray-500 py-3 px-8 flex items-center flex-col justify-center sm:text-2xl text-lg font-extrabold text-gray-50 rounded-2xl border border-white/20"
          >
            {<p>{leftText}</p>}
            {leftSubtitle && <p>{leftSubtitle}</p>}
          </button>
          {rightText && (
            <button
              disabled={disabled || (checkbox && !isChecked)}
              onClick={() => {
                rightOnClick && rightOnClick();
              }}
              className={`bg-gray-100 transition-all py-3 px-8 flex items-center flex-col justify-center sm:text-2xl text-lg font-extrabold text-black rounded-2xl ${
                disabled || (checkbox && !isChecked)
                  ? "opacity-50"
                  : "hover:bg-white"
              }`}
            >
              {<p>{rightText}</p>}
              {rightSubtitle && (
                <p className="sm:text-sm text-xs text-gray-500">
                  {rightSubtitle}
                </p>
              )}
            </button>
          )}
        </div>
      </div>
    );
};

export default StepButtons;
