import Big from "big.js";
import twitter from "../../../assets/icons/twitter.svg";
import Config from "../../../config";
import { convertSatToBtc } from "../../../utils";

const TweetButton = ({
  loan,
  type = "request",
  widthFull,
  activatedLoan,
  numberOfOffers,
  isBRC20,
}: {
  loan: any;
  type?: "live" | "request";
  widthFull?: boolean;
  activatedLoan?: boolean;
  numberOfOffers?: number;
  isBRC20?: boolean;
}) => {
  const collectionName = loan?.collectionName;
  const collectionHandle = loan?.collection?.twitterLink
    ? `@${loan?.collection?.twitterLink?.split("/").pop()}`
    : collectionName;
  const floor = +convertSatToBtc(false, loan?.collection.floorPrice) || 0;
  const amount = +convertSatToBtc(false, loan?.principal) || 0;
  const term = loan?.term;
  const apr = loan?.interestRate;

  let tweetContent = "";

  if (activatedLoan) {
    const loanStats = `${amount} BTC for ${term} Days at ${apr}% APR`;
    tweetContent = `I just lent ${loanStats} on @LiquidiumFi for the collection: ${collectionHandle}\n\nCheck out more offers at ${Config.AppUrl}`;
  } else {
    const loanStats = `Amount: ${amount} BTC | ${
      floor ? `Floor: ${floor} BTC | ` : ""
    }${
      floor ? `LTV: ${Big(amount).div(floor).mul(100).toFixed(2)}% | ` : ""
    }${term} Days | ${apr}% APR`;

    if (numberOfOffers && type !== "live") {
      tweetContent = `Just created ${numberOfOffers} Loan Offers on @LiquidiumFi${
        collectionHandle ? ` for any ${collectionHandle}` : ""
      }\n\n${loanStats}\n\nBorrow on Liquidium here: ${Config.AppUrl}/${
        isBRC20 ? "brc20" : "ordinals"
      }/borrow/${loan?.collectionSymbol}`;
    } else {
      tweetContent = `Just ${
        type === "live" ? "saw this" : "created a"
      } Loan Offer on @LiquidiumFi${
        collectionHandle ? ` for any ${collectionHandle}` : ""
      }\n\n${loanStats}\n\nBorrow on Liquidium here: ${Config.AppUrl}/${
        isBRC20 ? "brc20" : "ordinals"
      }/borrow/${loan?.collectionSymbol}`;
    }
  }

  const handleTweet = () => {
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetContent,
    )}`;
    window.open(tweetUrl, "_blank");
  };

  return (
    <button
      onClick={handleTweet}
      className={`${
        widthFull && "w-full"
      } flex justify-center items-center h-12 text-xs sm:text-sm text-gray-50 font-black px-12 rounded-2xl gap-2 cursor-pointer hover:bg-opacity-80 bg-[#00acee] border border-white/20`}
    >
      <img alt="twitter" src={twitter} className="h-4" />

      {type === "live" ? "Share" : "Tweet!"}
    </button>
  );
};

export default TweetButton;
