import { FC } from "react";
import { LoanType, Offer } from "../../../../types";
import TitleWithSearchSort from "../../../../components/Reusable/TitleWithSearchSort/TitleWithSearchSort";
import Loading from "../../../../components/Reusable/Loading";
import RowCard from "../../../../components/Reusable/PortfolioRowCard/PortfolioRowCard";

interface ILoanOffers {
  items: Offer[];
  loading?: boolean;
  onClick: Function;
  offersBest?: LoanType[] | null;
}

const LoanOffers: FC<ILoanOffers> = ({
  items,
  loading,
  onClick,
  offersBest,
}) => {
  // const [search, setSearchInput] = useState<string>("");

  //TODO: sort items

  return (
    <div className="">
      <TitleWithSearchSort
        title="Your Loan Offers"
        onSortToggle={() => {}}
        ascending={true}
        // onSearchInput={setSearchInput}
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          {items.length === 0 ? (
            <p className="text-xl text-gray-400 font-bold w-full text-center pt-4">
              No active loan offers
            </p>
          ) : (
            <div className="grid grid-flow-row gap-[10px] sm:gap-3 ">
              {items.map((item, i) => {
                if (offersBest && item.collection) {
                  // Find the best offer collection
                  const bestOffer = offersBest.find(
                    (offer) =>
                      offer.collection &&
                      offer.collection.name === item.collection.name,
                  );

                  // If current offer is lower than best offer, pass it to RowCard
                  const bestOfferAmount =
                    bestOffer && item.principal < bestOffer.principal
                      ? bestOffer.principal
                      : undefined;

                  // Check current offer is the best offer
                  const isBestOffer =
                    bestOffer && item.principal === bestOffer.principal;

                  return (
                    <RowCard
                      offer={item}
                      bestOffer={bestOfferAmount}
                      key={"loanOffer" + i}
                      type="offers"
                      onClick={onClick}
                      isBestOffer={isBestOffer}
                    />
                  );
                }
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LoanOffers;
