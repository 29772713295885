// NavigationBar.tsx
import x from "../../assets/icons/x.svg";
import { useCallback, useEffect, useState } from "react";
import styles from "./NavigationBar.module.css";
import { RouteType } from "../../types";
import useRoutes from "../../hooks/react/useRoutes";
import logo from "../../assets/logo-new.svg";
import logoText from "../../assets/logo-text-bottom-new.svg";
import hamburger from "../../assets/hamburger.svg";
import ProfileMenu from "../ProfileMenu";
import useAuth from "../../auth";
import NotificationsMenu from "../NotificationsMenu";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetUserData } from "../../hooks/liquidium/useGetUserData";
import { useUserStore } from "../../store";
import Config from "../../config";
import { setNotificationState } from "../../notification";
import GeneralNotification from "../../notification/Notifications/GeneralNotification";
import useBtcPrice from "../../hooks/api/useBtcPrice";
import Big from "big.js";
import axios from "axios";
import { setModalState } from "../../modal";
import SelectWalletModal from "../../modal/Modals/SelectWalletModal/SelectWalletModal";
import Loading from "../Reusable/Loading";

const NavigationBar = () => {
  const { ordinalRoutes } = useRoutes();
  const { fetchUserData, loadingUserData, userData } = useGetUserData();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const ordAddress = useUserStore((state) => state.ordAddress);
  const { onConnectClick, isUserSignedIn } = useAuth();
  const authenticatingAccount = useUserStore(
    (state) => state.authenticatingAccount,
  );
  const setAuthenticatingAccount = useUserStore(
    (state) => state.setAuthenticatingAccount,
  );
  const location = useLocation();
  const navigate = useNavigate();
  const handleNav = useCallback(
    (route: string, mobile?: boolean) => {
      if (mobile) {
        setDrawerOpen(false);
      }
      navigate(route);
    },
    [navigate],
  );
  const handleFaqNav = useCallback(() => {
    window.open(
      "https://liquidium.notion.site/Liquidium-FAQ-ad75c66cebac45f0a3a8b5dd0ad27175",
    );
  }, []);

  const btcAddress = useUserStore((state) => state.btcAddress);
  const btcBalance = useUserStore((state) => state.btcBalance);
  const setBtcBalance = useUserStore((state) => state.setBtcBalance);
  const setUserAnalytics = useUserStore((state) => state.setUserAnalytics);
  const setAirdrops = useUserStore((state) => state.setAirdrops);
  const { btcPrice } = useBtcPrice();
  const [usdBalance, setUsdBalance] = useState(0);

  const handleConnect = useCallback(async () => {
    // await onConnectClick();
    setModalState(
      "Select wallet modal",
      () => {},
      () => <SelectWalletModal />,
      false,
      false,
    );
  }, [setModalState]);

  //Fetches user data
  useEffect(() => {
    fetchUserData(ordAddress);
  }, [ordAddress, fetchUserData]);

  //Fetch BTC balance
  useEffect(() => {
    let isMounted = true; // keep track if the component is still mounted

    const source = axios.CancelToken.source(); // Axios cancellation token
    const fetchBtcBalance = async (address: string) => {
      const url = Config.Network.isMainnet()
        ? `https://blockchain.info/rawaddr/${address}`
        : `https://api.blockcypher.com/v1/btc/test3/addrs/${address}`;
      try {
        if (!address) return setBtcBalance(0);
        const response = await axios.get(url, { cancelToken: source.token });
        if (!isMounted) return;
        setBtcBalance(response.data.final_balance);
        // setBtcBalance(0);
      } catch (error) {
        setBtcBalance(0);
        if (axios.isCancel(error)) return; // If the request was cancelled, exit without doing anything
        setNotificationState(() => (
          <GeneralNotification
            text="Error fetching BTC balance"
            variant="error"
          />
        ));
      }
    };
    fetchBtcBalance(btcAddress);

    return () => {
      isMounted = false;
      source.cancel();
    };
  }, [btcAddress]);

  useEffect(() => {
    if (!btcBalance) {
      setUsdBalance(0);
      return;
    }
    setUsdBalance(
      Big(btcPrice ? btcPrice : 0)
        .mul(btcBalance ? btcBalance : 0)
        .div(1e8)
        .toNumber(),
    );
  }, [btcPrice, btcBalance]);

  useEffect(() => {
    if (!userData?.analytics) return;
    setUserAnalytics(userData?.analytics);

    if (userData.airdrops) {
      setAirdrops(userData.airdrops);
    }
  }, [userData]);

  const Icons = () => {
    return (
      <div className="flex items-center justify-self-end md:gap-6 gap-4">
        {authenticatingAccount ? (
          <div className="flex justify-center items-center text-gray-50 text-xs sm:text-sm font-black">
            Authenticating
            <div className="w-5 h-5 ml-2">
              <Loading />
            </div>
            <div className="w-5 h-5 ml-2">
              <img
                src={x}
                alt="cancel"
                onClick={() => setAuthenticatingAccount(false)}
              />
            </div>
          </div>
        ) : (
          <>
            <div className=" md:h-8 h-6">
              {isUserSignedIn && (
                <ProfileMenu userData={userData} usdBalance={usdBalance} />
              )}
            </div>
            <div className="md:h-8 h-6">
              {isUserSignedIn ? (
                <NotificationsMenu
                  userData={userData}
                  loadingUserData={loadingUserData}
                />
              ) : (
                <button
                  onClick={handleConnect}
                  className="bg-white/0 rounded-2xl transition-colors text-gray-50 text-xs sm:text-sm font-black hover:bg-white hover:text-gray-800  px-3  border border-white md:h-[30px] h-[26px] md:translate-y-0 -translate-y-[1px]"
                >
                  Connect
                </button>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const DesktopNav = ({ ordinalRoutes }: { ordinalRoutes: RouteType[] }) => {
    return (
      <div className="hidden md:flex items-center justify-between w-full">
        <div className="flex items-center">
          <button
            onClick={() => navigate("/ordinals")}
            className="flex-shrink-0"
          >
            <img className="h-14 w-14 p-1" src={logo} alt="Logo" />
          </button>
          <nav className="ml-10 space-x-5 ">
            {ordinalRoutes.map((route) => (
              <button
                key={route.href}
                onClick={() => handleNav(route.href)}
                className={`leading-6 ${
                  location.pathname.startsWith(route.href)
                    ? "text-gray-50 font-black"
                    : "text-gray-400/80 font-semibold "
                }`}
              >
                {route.name}
              </button>
            ))}
          </nav>
        </div>
        <Icons />
      </div>
    );
  };

  const MobileNav = ({
    ordinalRoutes,
    isDrawerOpen,
    toggleDrawer,
  }: {
    ordinalRoutes: RouteType[];
    isDrawerOpen: boolean;
    toggleDrawer: () => void;
  }) => {
    return (
      <div className="md:hidden flex items-center justify-between w-full">
        <button onClick={toggleDrawer} className="-ml-2">
          <img src={hamburger} alt="hamburger" />
        </button>
        <Icons />
      </div>
    );
  };

  return (
    <div className="relative bg-black  px-[5%]">
      <div className="mx-auto max-w-[1400px]">
        <div className="flex justify-between items-center py-6  md:justify-start md:space-x-10">
          <DesktopNav ordinalRoutes={ordinalRoutes} />
          <MobileNav
            ordinalRoutes={ordinalRoutes}
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={() => setDrawerOpen(!isDrawerOpen)}
          />
        </div>
      </div>
      {/* Nav Drawer */}

      <>
        <div
          className={`${styles.drawerBackdrop} ${
            isDrawerOpen ? styles.drawerBackdropOpen : ""
          }`}
          onClick={() => setDrawerOpen(false)}
        ></div>
        <div
          style={{
            transform: isDrawerOpen ? "translateX(0%)" : "translateX(-100%)",
          }}
          className={
            isDrawerOpen
              ? styles.drawerContent +
                " drop-shadow-[0_35px_35px_rgba(264,264,264,0.15)] "
              : styles.drawerContent
          }
        >
          <img src={logoText} alt="logoText" className="w-28 mb-8 mt-3"></img>
          <nav className="gap-3 flex flex-col items-center">
            {ordinalRoutes.map((route) => {
              const shouldCloseDrawer = true;
              return (
                <button
                  key={route.href}
                  onClick={() => handleNav(route.href, shouldCloseDrawer)}
                  className={`leading-6 ${
                    location.pathname.startsWith(route.href)
                      ? "text-gray-50 font-black"
                      : "text-gray-400/80 font-semibold "
                  }`}
                >
                  {route.name}
                </button>
              );
            })}
          </nav>
        </div>
      </>
    </div>
  );
};

export default NavigationBar;
