import { RefObject, Suspense, useEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Navigate } from "react-router";
import { ApolloProvider } from "@apollo/client";
import Modal, { setModalState } from "./modal";
import Notification from "./notification";
import client from "./graphql/client";
import Footer from "./components/Footer";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import styles from "./App.module.css";
import Portfolio from "./pages/OrdP2PPortfolio/Portfolio";
import Settings from "./pages/Settings";
import Analytics from "./pages/Analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import SelectWalletModal from "./modal/Modals/SelectWalletModal/SelectWalletModal";
import Leaderboard from "./pages/Leaderboard";
// import BRC20 from "./pages/BRC20";
import Ordinals from "./pages/Ordinals";
import AirdropClaim from "./pages/RsicClaim";
import { useIsOnBRC20Whitelist } from "./hooks/liquidium/useIsOnBRC20Whitelist/useIsOnBRC20Whitelist";
import { useUserStore } from "./store";
import useIsOnRsicWhitelist from "./hooks/liquidium/useIsOnRsicWhitelist";
import V3Notification from "./components/V3Notification/V3Notification";

const ScrollToTop = ({
  scrollRef,
}: {
  scrollRef: RefObject<HTMLDivElement>;
}) => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (scrollRef.current) scrollRef.current.scrollTop = 0;
  }, [pathname, scrollRef]);

  return null;
};

const useAuthStateListener = () => {
  const [wasPreviouslyAuthenticated, setWasPreviouslyAuthenticated] =
    useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        setWasPreviouslyAuthenticated(true);
      } else {
        // User is signed out.
        if (wasPreviouslyAuthenticated) {
          setTimeout(() => {
            setModalState(
              "Select wallet modal",
              () => {},
              () => <SelectWalletModal reAuthenticate />,
              false,
              false,
            );
          }, 500);
        }
      }
    });
    return () => unsubscribe();
  }, [wasPreviouslyAuthenticated]);
};

const App = () => {
  useAuthStateListener();
  const pageRef = useRef<HTMLDivElement>(null);
  const queryClient = new QueryClient();
  const myOrdAddress = useUserStore((state) => state.ordAddress);
  const myBtcAddress = useUserStore((state) => state.btcAddress);
  // const { isLoadingIsOnBRC20Whitelist, isOnBRC20Whitelist, isBRC20error } =
  //   useIsOnBRC20Whitelist({
  //     btcAddress: myBtcAddress,
  //     ordAddress: myOrdAddress,
  //   });
  // const { isLoadingIsOnRsicWhitelist, isOnRsicWhitelist, isRsicError } =
  //   useIsOnRsicWhitelist({
  //     btcAddress: myBtcAddress,
  //     ordAddress: myOrdAddress,
  //   });

  useEffect(() => {
    window.addEventListener("beforeunload", () => sessionStorage.clear());
    return () => {
      window.removeEventListener("beforeunload", () => sessionStorage.clear());
    };
  }, []);

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client}>
          <div className="absolute top-0 flex flex-col w-full z-20">
            <NavigationBar />
          </div>

          <Modal />
          <Notification />
          <ScrollToTop scrollRef={pageRef} />
          <div
            className={`${styles.component} overflow-y-scroll h-[100vh] px-[5%] md:pt-[80px] pt-[40px] `}
          >
            {/* <div className="mt-4">
              <InformationBar text="Liquidium is scheduled for maintenance today from 8:30 PM ET to 10:30 PM ET. All functionality will be disabled during this time." />
            </div> */}
            <Routes>
              {/* <Route
                path="/ordinals/borrow/:collectionSymbol"
                element={<Ordinals />}
              />
              <Route
                path="/ordinals/lend/:collectionSymbol"
                element={<Ordinals />}
              />

              <Route
                path="/ordinals"
                element={
                  <Suspense fallback={<></>}>
                    <Ordinals />
                  </Suspense>
                }
              /> */}

              <Route
                path="/portfolio"
                element={
                  <Suspense fallback={<></>}>
                    <Portfolio />
                  </Suspense>
                }
              />

              <Route
                path="/airdrop-claim"
                element={
                  <Suspense fallback={<></>}>
                    <AirdropClaim />
                  </Suspense>
                }
              />

              {/* {isOnBRC20Whitelist && (
                <Route
                  path="/brc20"
                  element={
                    <Suspense fallback={<></>}>
                      <BRC20 />
                    </Suspense>
                  }
                />
              )} */}
              {/* <Route
                path="/leaderboard"
                element={
                  <Suspense fallback={<></>}>
                    <Leaderboard />
                  </Suspense>
                }
              /> */}
              <Route
                path="/settings/:page"
                element={
                  <Suspense fallback={<></>}>
                    <Settings />
                  </Suspense>
                }
              />
              <Route
                path="/settings"
                element={
                  <Suspense fallback={<></>}>
                    <Settings />
                  </Suspense>
                }
              />
              <Route
                path="/analytics"
                element={
                  <Suspense fallback={<></>}>
                    <Analytics />
                  </Suspense>
                }
              />
              {/* {allRoutes.map((route: RouteType) => (
                  <Route
                    path={route.href}
                    key={route.href}
                    element={
                      <Suspense fallback={<></>}>
                        <route.component />
                      </Suspense>
                    }
                  />
                ))} */}
              <Route path="*" element={<Navigate to="/portfolio" />} />
            </Routes>
            <Footer />
          </div>
        </ApolloProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
