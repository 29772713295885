import { FC, useState, useEffect } from "react";
import verifiedCheck from "../../../assets/icons/verified.svg";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebaseConfig";

interface INFTInfo {
  img: string;
  collectionName: string;
  NFTindex?: number | null;
  collectionVerified?: boolean;
  small?: boolean;
}

const NFTInfo: FC<INFTInfo> = ({
  img,
  collectionName,
  NFTindex,
  collectionVerified,
  small,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [useIframe, setUseIframe] = useState(false);

  const handleError = (isIframeError: boolean) => {
    if (isIframeError) {
      setError("Error loading content");
    } else {
      setUseIframe(true);
    }
  };

  return (
    <div className="flex items-center gap-3 sm:gap-6">
      <div
        className={`relative  rounded-lg overflow-hidden ${
          small ? "h-12 w-12" : "h-16 w-16"
        } ${loading || error ? "hidden" : ""} `}
      >
        {useIframe ? (
          <iframe
            title="content"
            src={img}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            sandbox="allow-scripts allow-same-origin"
            onError={() => handleError(true)}
            onLoad={() => {
              setLoading(false);
            }}
          />
        ) : (
          <img
            onLoad={() => setLoading(false)}
            onError={() => handleError(false)}
            src={img}
            alt="content"
            className="w-full h-full object-cover rounded-lg"
          />
        )}
      </div>
      <div
        className={`${
          small
            ? "h-12 w-12 min-w-[48px] rounded-md"
            : "h-16 w-16 min-w-[64px] rounded-xl"
        } ${
          loading && !error ? "block" : "hidden"
        } animate-pulse bg-orange-400/20 z-10`}
      ></div>
      {/* Error message */}
      <div
        className={`${
          small
            ? "h-12 w-12 min-w-[48px] rounded-md"
            : "h-16 w-16 min-w-[64px] rounded-xl"
        } ${
          error ? "block" : "hidden"
        } bg-gray-800 border border-white/20 z-10 text-[10px] font-semibold text-gray-400 flex items-center justify-center text-center`}
      >
        {error}
      </div>
      <div className="max-w-[200px]">
        <div className="flex min-w-[135px] max-w-[160px]">
          <p className="text-sm text-white font-extrabold">{collectionName}</p>
        </div>
        {NFTindex && (
          <p className="sm:text-sm text-xs text-gray-400 font-medium mt-1">
            {"#" + NFTindex}
          </p>
        )}
      </div>
    </div>
  );
};

export default NFTInfo;
