import { FC, useEffect, useState } from "react";
import StatusTag from "../../../components/Reusable/StatusTag/StatusTag";
import InputSubmit from "../../../components/Reusable/InputSubmit/InputSubmit";
import { useUserStore } from "../../../store";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebaseConfig";
import { setNotificationState } from "../../../notification";
import GeneralNotification from "../../../notification/Notifications/GeneralNotification";
import { UserData } from "../../../types";

interface NotificationsProps {
  userData: UserData | null;
  refreshUserData: Function;
}
const Notifications: FC<NotificationsProps> = ({
  userData,
  refreshUserData,
}) => {
  //we can maybe move this to useState objs
  const [email, setEmail] = useState("");
  const [telegramUsername, setTelegramUsername] = useState("");

  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false);
  const [isSubmittingTelegram, setIsSubmittingTelegram] = useState(false);
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const [isTelegramSubmitted, setIsTelegramSubmitted] = useState(false);
  const ordAddress = useUserStore((state) => state.ordAddress);

  const handleSubmitEmail = async (e: any) => {
    e.preventDefault();
    setIsSubmittingEmail(true);
    setIsEmailSubmitted(false);
    try {
      // TODO: change this to update user!!!!
      const addUser = httpsCallable(functions, "updateUser");
      const response: any = await addUser({
        id: ordAddress,
        email: email,
        isEmailNotif: true,
        isWhiteList: true,
      });
      if (response.data.success) {
        setNotificationState(() => (
          <GeneralNotification
            text={"Email successfully added."}
            variant="success"
          />
        ));
        setIsSubmittingEmail(false);
        setIsEmailSubmitted(true);
      }
    } catch {
      setNotificationState(() => (
        <GeneralNotification text={"Error adding email."} variant="error" />
      ));
      setIsSubmittingEmail(false);
      setIsEmailSubmitted(false);
    } finally {
      setIsSubmittingEmail(false);
    }
  };

  const handleSubmitTelegram = async (e: any) => {
    e.preventDefault();
    setIsSubmittingTelegram(true);
    setIsTelegramSubmitted(false);
    try {
      // TODO: change this to update user!!!!
      const setTelegramUsername = httpsCallable(
        functions,
        "setTelegramUsername",
      );
      const response: any = await setTelegramUsername({
        id: ordAddress,
        telegramUsername: telegramUsername,
      });
      if (response.data.success) {
        setNotificationState(() => (
          <GeneralNotification
            text={"Username successfully added."}
            variant="success"
          />
        ));
        setIsSubmittingTelegram(false);
        setIsTelegramSubmitted(true);
      }
    } catch {
      setNotificationState(() => (
        <GeneralNotification
          text={"Error adding telegram username."}
          variant="error"
        />
      ));
      setIsSubmittingTelegram(false);
      setIsTelegramSubmitted(false);
    } finally {
      setIsSubmittingTelegram(false);
    }
  };

  useEffect(() => {
    if (isTelegramSubmitted || isEmailSubmitted) {
      refreshUserData();
    }
  }, [isTelegramSubmitted, isEmailSubmitted]);

  return (
    <div className=" flex flex-col gap-5 lg:max-w-[500px] lg:w-full">
      <div>
        <InputSubmit
          status={userData?.email ? "Active" : "Not Activated"}
          key={"1email"}
          value={email}
          id={1}
          title={"Email"}
          onEmailSubmit={handleSubmitEmail}
          placeholder={userData?.email ? userData.email : "Email"}
          type={"email"}
          isSubmitted={isEmailSubmitted}
          isSubmitting={isSubmittingEmail}
          handleSetInput={setEmail}
          submittedMessage={"Email successfully added"}
          description="Receive email notifications, this is separate from your whitelist email"
        />
      </div>
      <div>
        {!isTelegramSubmitted ? (
          <InputSubmit
            value={telegramUsername}
            status={
              userData?.telegramChatId && userData?.telegramUsername
                ? "Active"
                : !userData?.telegramChatId && userData?.telegramUsername
                ? "Bot Not Linked"
                : !userData?.telegramChatId && !userData?.telegramUsername
                ? "Not Activated"
                : "Not Activated" // This is a fallback in case both are false.
            }
            key={"1telegram"}
            id={2}
            title={"Telegram"}
            onEmailSubmit={handleSubmitTelegram}
            placeholder={
              userData?.telegramUsername
                ? userData?.telegramUsername
                : "Telegram username"
            }
            type={"text"}
            isSubmitted={isTelegramSubmitted}
            isSubmitting={isSubmittingTelegram}
            handleSetInput={setTelegramUsername}
            submittedMessage={"Telegram username successfully added"}
            // description="Receive Telegram notifications"
            description={
              <>
                {userData?.telegramUsername && !userData?.telegramChatId ? (
                  <p className="text-[10px] sm:text-xs text-gray-400/80 font-semibold ">
                    Activate the{" "}
                    <a
                      href="https://t.me/Liquidium_Loan_Updates_bot"
                      className="text-purple-700 hover:underline hover:text-purple-600"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      @Liquidium_Loan_Updates_bot{" "}
                    </a>
                    to link it to your telegram username
                  </p>
                ) : (
                  <>
                    Receive Telegram notifications from{" "}
                    <a
                      href="https://t.me/Liquidium_Loan_Updates_bot"
                      className="text-purple-700 hover:underline hover:text-purple-600"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      @Liquidium_Loan_Updates_bot
                    </a>
                  </>
                )}
              </>
            }
          />
        ) : (
          <div className="w-full">
            <div className="text-gray-400/90 sm:text-sm text-xs font-extrabold mb-2 flex justify-between ">
              Telegram
              <StatusTag
                type={
                  userData?.telegramChatId && userData?.telegramUsername
                    ? "Active"
                    : !userData?.telegramChatId && userData?.telegramUsername
                    ? "Bot Not Linked"
                    : !userData?.telegramChatId && !userData?.telegramUsername
                    ? "Not Activated"
                    : "Not Activated" // This is a fallback in case both are false.
                }
                small
              />
            </div>
            <a
              href="https://t.me/Liquidium_Loan_Updates_bot"
              target="_blank"
              className=" sm:text-sm text-xs font-extrabold text-gray-50 bg-blue-500 rounded-xl sm:px-4 px-3 py-2  hover:bg-opacity-80 border border-white/20 text-center cursor-pointer w-full flex  justify-center"
              rel="noopener noreferrer"
            >
              Activate Telegram Notifications Bot →
            </a>
            <p className="text-[10px] sm:text-xs text-gray-400/80 font-semibold pt-2">
              Receive Telegram notifications
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
