import { FC } from "react";

const V3Notification: FC = () => {
  return (
    <div className="p-5 rounded-lg w-full flex sm:text-sm text-xs items-center gap-3 border border-orange-600 text-orange-600 font-medium mb-5 bg-orange-50/5 ">
      <p>
        The new version of Liquidium is live at{" "}
        <strong>
          <a
            href="https://app.liquidium.fi"
            className="underline"
            target="_blank"
          >
            app.liquidium.fi
          </a>
        </strong>
        . You can still manage your active legacy loans here on{" "}
        <strong>
          <a
            href="https://legacy.liquidium.fi"
            className="underline"
            target="_blank"
          >
            legacy.liquidium.fi
          </a>
          . Points are migrated to the new version
        </strong>
      </p>
    </div>
  );
};

export default V3Notification;
