import { FC, useCallback, useEffect, useState } from "react";
import ValueTag from "../../../components/Reusable/ValueTag/ValueTag";
import PercentageBar from "../../../components/Reusable/PercentageBar/PercentageBar";

interface LoanDurationProps {
  startDate: number | undefined; // Unix epoch time in milliseconds
  endDate: number | undefined; // Unix epoch time in milliseconds
  currentBlock?: number;
  endBlock?: number;
  remaining: number | string;
}

const LoanDuration: FC<LoanDurationProps> = ({
  startDate,
  endDate,
  currentBlock,
  endBlock,
  remaining,
}) => {
  const [blocksRemaining, setBlocksRemaining] = useState<number>(0);
  const [percentageRemaining, setPercentageRemaining] = useState<number>(0);

  // Calculate the percentage of time passed between the start date and current date
  const calculatePercentagePassed = useCallback(() => {
    if (!startDate || !endDate) return 0;
    const totalDuration = endDate - startDate;
    const passedDuration = new Date().getTime() - startDate;
    return (passedDuration / totalDuration) * 100;
  }, [startDate, endDate]);

  const blockRemaining = (currentBlock: number, endBlock: number) => {
    return endBlock - currentBlock;
  };

  useEffect(() => {
    const percentageRemaining = 100 - calculatePercentagePassed();
    setPercentageRemaining(percentageRemaining);
    if (currentBlock && endBlock) {
      setBlocksRemaining(blockRemaining(currentBlock, endBlock));
    }
  }, [currentBlock, calculatePercentagePassed, endBlock]);

  const formatRemainingTime = () => {
    const currentTime = new Date().getTime();
    if (!endDate) return;
    let remainingTime = endDate - currentTime; // in milliseconds

    if (remainingTime < 0) {
      return `Loan period has ended`;
    }

    remainingTime /= 1000; // convert to seconds
    const seconds = Math.floor(remainingTime % 60);
    const minutes = Math.floor((remainingTime / 60) % 60);
    const hours = Math.floor((remainingTime / (60 * 60)) % 24);
    const days = Math.floor(remainingTime / (60 * 60 * 24));

    if (days > 0) {
      return `${days}D ${hours}H remaining`;
    } else if (hours > 0) {
      return `${hours}H ${minutes}Min remaining`;
    } else if (minutes >= 10) {
      return `${minutes} Minutes remaining`;
    } else {
      return `< 10 mins remaining`;
    }
  };

  return (
    <div
      className={` rounded-xl p-3 border border-white/20 ${
        currentBlock && endBlock && "pb-[1px]"
      } flex flex-col gap-0`}
    >
      <div className="flex justify-between">
        <p className="text-gray-400 text-xs sm:text-sm font-semibold">
          Loan Duration
        </p>
        <p className="text-gray-400 text-xs sm:text-sm font-semibold">
          <strong className="text-gray-50">{formatRemainingTime()}</strong>
        </p>
      </div>
      {/* Percentage bar */}
      <div className="mt-1">
        <PercentageBar startDate={startDate} endDate={endDate} />
      </div>
      {endBlock && currentBlock && (
        <div className="sm:flex sm:flex-row justify-between items-center flex-col pt-1 mb-1 gap-2">
          <ValueTag
            value={`Current Block: ${currentBlock}`}
            leftDotColor="green"
          />
          <div className="sm:mt-0 mt-1">
            <ValueTag
              value={`Outstanding Blocks: ${blocksRemaining}`}
              leftDotColor="green"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LoanDuration;
