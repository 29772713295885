import copyIcon from "../../assets/icons/copy_icon.svg";
import Tooltip from "../Reusable/Tooltip/Tooltip";
import InformationBar from "../../modal/components/InformationBar";
import { LoanType, Offer } from "../../types";
import { useCallback } from "react";
import { setNotificationState } from "../../notification";
import CopyNotification from "../../notification/Notifications/CopyNotification";
import { formatAddress } from "../../utils";

const InscribeButton = ({
  request,
  address = "",
}: {
  request: LoanType | Offer;
  address?: string;
}) => {
  const ticker = request.collectionSymbol;
  // const postInscription = httpsCallable(functions, "postInscription");
  const text = request.status === "requested"
    ? `Only transfers of ${request.bundleSize} ${ticker?.toUpperCase()} may be used as collateral. Inscribe one for your wallet by following this link:`
    : `Escrow address DOES NOT have a valid transfer.`;

  const handleSubmit = async () => {
    // const data = { ticker }
    try {
      // await postInscription(data);
      window.open("https://ordinalsbot.com/")
    } catch (error) {
      console.log(error);
    }
  }

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(address);
    setNotificationState(() => <CopyNotification />);
  }, [address]);

  return (
    <div className="mb-7 mt-7">
      <InformationBar text={text} gray />
      {request.status !== "requested" && (
        <>
          <br/>
          <InformationBar text={`Follow this link and inscribe a transfer of ${request?.collection?.bundleSize} ${ticker?.toUpperCase()} for the escrow address below.`} gray />
        <br/>
        <div
          className="flex hover:opacity-80 cursor-pointer"
          onClick={handleCopy}
        >
          <p className="sm:text-sm text-xs font-extrabold text-gray-400">
            Escrow Address: {formatAddress(address)}
          </p>
          <img className={"w-4 ml-1"} src={copyIcon} alt="Copy Icon" />
        </div>
        </>
      )}
      <Tooltip content={`This will open a new tab to an inscription tool.`}>
        <button
          className="w-full border border-white/20  text-xs sm:text-sm font-extrabold rounded-xl bg-orange-700 hover:bg-orange-600 transition-colors mt-5 text-white p-4"
          onClick={() => handleSubmit()}
          >
          Inscribe BRC20 Transfer
        </button>
      </Tooltip>
      <br/>
      <br/>
      <InformationBar text="PLEASE double check all data before inscribing." gray />
    </div>
  );
};

export default InscribeButton;
