import { FC, useState } from "react";
import warningIcon from "../../../assets/icons/warning-white-icon.svg";
import { httpsCallable } from "firebase/functions";
import { useUserStore } from "../../../store";
import { functions } from "../../../firebaseConfig";
import { setNotificationState } from "../../../notification";
import GeneralNotification from "../../../notification/Notifications/GeneralNotification";

interface DeactivateProps {}

const Deactivate: FC<DeactivateProps> = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const ordAddress = useUserStore((state) => state.ordAddress);

  const handleDeactivation = async () => {
    setIsSubmitting(true);
    try {
      const requestDeletion = httpsCallable(
        functions,
        "requestAccountDeletionEmail"
      );
      const response: any = await requestDeletion({
        userOrdAddress: ordAddress,
        toEmail: "support@liquidium.fi",
      });

      if (response.data && response.data.success) {
        setNotificationState(() => (
          <GeneralNotification
            text={"Account deactivation request submitted."}
            variant="success"
          />
        ));
        setIsSubmitted(true);
        setIsSubmitting(false);
      } else {
        setNotificationState(() => (
          <GeneralNotification
            text={"Failed to submit account deactivation request."}
            variant="error"
          />
        ));
        setIsSubmitted(false);
        setIsSubmitting(false);
      }
    } catch (error) {
      setNotificationState(() => (
        <GeneralNotification
          text={"An error occurred. Please try again later."}
          variant="error"
        />
      ));
      setIsSubmitting(false);
      setIsSubmitted(false);
    }
  };

  return (
    <div className="w-full  lg:w-[500px]">
      <p className="text-gray-400/90 sm:text-sm text-xs font-extrabold mb-2">
        Deactivate Account
      </p>
      <button
        type="button"
        onClick={handleDeactivation}
        disabled={isSubmitted || isSubmitting}
        className="rounded-xl bg-red-600 border border-white/20 font-extrabold  text-white text-sm hover:bg-opacity-80 px-3 py-2 flex gap-2  items-center disabled:bg-opacity-80 disabled:hover:bg-opacity-70 "
      >
        <img alt="warningIcon" src={warningIcon} className="w-4"></img>
        {isSubmitted
          ? "Deactivation Requested"
          : isSubmitting
          ? "Submitting..."
          : "Request Deactivation"}
      </button>
      <p className="text-[10px] sm:text-xs text-gray-400/80 mt-2 font-semibold">
        This will notify our team to deactivate your account. This may take up
        to 3-days
      </p>
    </div>
  );
};

export default Deactivate;
