// require("dotenv").config(); // TODO: fix this
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { HttpLink, split } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { from } from "@apollo/client";
import TimeoutLink from "apollo-link-timeout";

const timeoutLink = new TimeoutLink(20000); // 20 seconds

const httpLink = new HttpLink({
  uri: "https://liquidium.api.deeplake.finance/graphql",
  // uri: "https://liquidium.api.test.deeplake.finance/graphql",
});

const REACT_APP_WS_URL = "wss://liquidium.api.deeplake.finance/";
const wsLink = new GraphQLWsLink(
  createClient({
    url: REACT_APP_WS_URL ? REACT_APP_WS_URL : "",
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const retryLink = new RetryLink({
  attempts: (count) => {
    if (count <= 1) {
      return true;
    }
    return false;
  },
  delay: (count) => {
    return Math.min(1000 * 2 ** count, 30000);
  },
});

const client = new ApolloClient({
  link: from([retryLink, timeoutLink, splitLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Loan: {
        fields: {
          addresses: {
            merge(_, incoming) {
              return incoming;
            },
          },
        },
      },
      Collateral: {
        fields: {
          assets: {
            merge(_, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
});

export default client;
