import { forwardRef } from "react";
import InscriptionContent from "../InscriptionContent";
import btcSymbol from "../../../assets/assets/btcSymbol.svg";

// TODO: SELECTED BORDER

interface NFTAssetCardProps {
  variation: "default" | "small";
  assets?: any;
  loan?: any;
  hideHealth?: boolean;
  onClick?: (asset?: any) => void;
  onCancel?: () => void;
  className?: string;
  isSelected?: boolean;
  hideId?: boolean;
  noHover?: boolean;
  landscape?: boolean;
  BRC20CollectionImgURI?: string | null;
}

const NFTAssetCard: React.ForwardRefRenderFunction<
  HTMLAnchorElement | HTMLDivElement,
  NFTAssetCardProps
> = (
  {
    assets,
    loan,
    hideHealth,
    onClick,
    onCancel,
    className,
    isSelected,
    hideId,
    noHover,
    variation,
    landscape,
    BRC20CollectionImgURI,
  },
  ref,
) => {
  const multipleAssets = assets ? assets.length > 1 : false;
  const isLandScape = landscape ? landscape : false;

  return (
    <div ref={ref as any}>
      {variation === "default" ? (
        <div
          className={`w-full cursor-pointer bg-gray-600 flex flex-col pb-1 border border-white/20 rounded-[10px] hover:bg-opacity-70 transition-all gap-3 ${
            isSelected && "ring-gray-50 ring"
          }`}
        >
          <InscriptionContent asset={assets[0]} />
          <div className="grid sm:grid-flow-col grid-flow-row rounded-[10px] border-[1px] border-gray-500  mx-2 ">
            {/* TODO: pass these values */}
            <div className="flex items-center justify-center font-black text-gray-50 text-xs sm:text-sm gap-1">
              <img src={btcSymbol} alt="btcSymbol" className="h-5" />
              0.22
            </div>
            <div className="flex items-center justify-center font-black text-gray-50 text-xs sm:text-sm gap-1  border-gray-500 border-x-0 sm:border-x-[1px] sm:border-y-0 border-y-[1px]">
              22 <p className="font-semibold text-gray-400">D</p>
            </div>
            <div className="flex items-center justify-center font-black text-gray-50 text-xs sm:text-sm gap-1">
              13 <p className="font-semibold text-gray-400">%</p>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex justify-between sm:text-sm text-xs  mx-2 ">
              <p className="text-gray-400 font-semibold">Inscription ID</p>
              <p className="text-gray-50 font-black">
                {multipleAssets
                  ? assets[0]?.inscriptionNumber
                  : assets.inscriptionNumber}
                {assets[0]?.inscriptionNumber}
              </p>
            </div>
            <div className="flex justify-between sm:text-sm text-xs  mx-2 ">
              <p className="text-gray-400 font-semibold">Loan-To-Floor</p>
              <p className="text-gray-50 font-black">{10}%</p>
              {/* TODO: pass loan-to-floor value */}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ position: "relative" }}
          className={`w-full cursor-pointer  bg-gray-600 flex   rounded-2xl hover:bg-opacity-70 transition-all overflow-hidden border border-white/20 ${
            isLandScape ? "flex pb-0 " : "flex-col sm:pb-3 pb-2"
          } ${isSelected && "ring-gray-50 ring"}`}
        >
          <div
            style={{
              position: "absolute",
              zIndex: 900,
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
              backgroundColor: "transparent",
            }}
            onClick={onClick}
          />
          {BRC20CollectionImgURI ? (
            <img src={BRC20CollectionImgURI} />
          ) : (
            <InscriptionContent asset={assets[0]} />
          )}

          <div className="flex flex-col sm:justify-start justify-start  px-3 pt-3 gap-[3px]">
            {assets[0]?.inscriptionNumber && (
              <div className="text-sm">
                <p className="text-gray-400 font-semibold">Inscr.</p>
                <p className="text-gray-50 font-black">
                  {assets[0]?.inscriptionNumber}
                </p>
              </div>
            )}
            {assets[0]?.collection && (
              <div className="text-sm">
                <p className="text-gray-400 font-semibold pt-1">
                  {assets[0]?.collection?.name
                    ? assets[0]?.collection?.name
                    : assets[0]?.collection}
                </p>
                <p className="text-gray-50  font-black">{assets[0]?.number}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default forwardRef(NFTAssetCard);
