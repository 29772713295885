import { FC, useCallback, useEffect, useMemo, useState } from "react";
import ModalTitle from "../../components/ModalTitle";
import StatsSection from "../../../components/Reusable/StatsSection/StatsSection";
import { LendingStats, LoanType } from "../../../types";
import { convertSatToBtc } from "../../../utils";
import AmountsAndInterest from "../../components/AmountsAndInterest/AmountsAndInterest";
import StepButtons from "../../components/StepButtons";
import InformationBar from "../../components/InformationBar";
import { setModalState, useModalStore } from "../..";
import SignatureModal from "../SignatureModal/SignatureModal";
import useDeepLake from "../../../hooks/deeplake/useDeepLake";
import { setNotificationState } from "../../../notification";
import GeneralNotification from "../../../notification/Notifications/GeneralNotification";
import { useUserStore } from "../../../store";
import ShareModal from "../ShareModal";
import useAuth from "../../../auth";
import SelectWalletModal from "../SelectWalletModal/SelectWalletModal";
import NumberIncrementer from "../../../components/Reusable/NumberIncrementer/NumberIncrementer";

interface LendModalProps {
  //TODO: I think createOffer was supposed to be for the custom offers, maybe we need a lend variant
  type: "createOffer" | "reviewAndSign"; //Maybe there is a better name for reviewAndSign
  request: LoanType;
  stats?: LendingStats;
  onSubmit?: Function;
  isBRC20?: boolean;
}

const LendModal: FC<LendModalProps> = ({
  type,
  request,
  stats,
  onSubmit,
  isBRC20,
}) => {
  const { close } = useModalStore();
  const { handleCreateOffer, handleActivateOffer } = useDeepLake();
  const { isUserSignedIn } = useAuth();
  const btcBalance = useUserStore((state) => state.btcBalance);
  const btcBalanceSatoshis = useMemo(
    () => +convertSatToBtc(false, btcBalance),
    [btcBalance],
  );
  const ordAddress = useUserStore((state) => state.ordAddress);
  const floor = request.collection ? request.collection.floorPrice : 0;
  const interestRate = request.interestRate;
  const term = request.term;
  const bestOffer = convertSatToBtc(false, request.principal);
  const [updatedStats, setUpdatedStats] = useState<LendingStats | undefined>();
  const [inputtedAmount, setInputtedAmount] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [numberOfLoans, setNumberOfLoans] = useState<number>(1);
  const [totalOfferAmount, setTotalOfferAmount] = useState<number>(0);
  const tokenAmount = request?.bundleSize;

  //Determines if a loan is risky based on inputtedAmount being a specific percentage or more of the floor value
  const isRiskyLoan = (
    inputtedAmount: number,
    floor: number,
    percentage: number,
  ) => {
    const threshold = floor * (percentage / 100);
    return inputtedAmount > threshold;
  };

  const floorInBtc = parseFloat(convertSatToBtc(false, floor).toString());

  const alerts = useCallback(() => {
    if (btcBalanceSatoshis < totalOfferAmount && numberOfLoans > 1) {
      return (
        <div className="mt-4">
          <InformationBar text="You don’t have enough BTC to make this many offers" />
        </div>
      );
    }
    if (btcBalanceSatoshis < inputtedAmount) {
      return (
        <div className="mt-4">
          <InformationBar text="You don’t have enough BTC to lend this amount." />
        </div>
      );
    }

    if (inputtedAmount > floorInBtc) {
      switch (type) {
        case "reviewAndSign":
          return (
            <div className="mt-4">
              <InformationBar text="This offer is higher than floor price, we don’t recommend starting this loan." />
            </div>
          );
        case "createOffer":
          return (
            <div className="mt-4">
              <InformationBar text="Not possible. Offer must be the floor price or lower." />
            </div>
          );
      }
    } else if (isRiskyLoan(inputtedAmount, floorInBtc, 85)) {
      return (
        <div className="mt-4">
          <InformationBar text="Offer is risky, because it’s close to floor price. Place it at your own risk." />
        </div>
      );
    }
  }, [
    inputtedAmount,
    btcBalanceSatoshis,
    type,
    floorInBtc,
    numberOfLoans,
    totalOfferAmount,
  ]);

  const handleConnect = useCallback(async () => {
    setModalState(
      "Select wallet modal",
      () => {},
      () => <SelectWalletModal />,
      false,
      false,
    );
  }, []);

  //Handle submitting transaction depending on the type, we can do this or pass the request?
  const handleSignatureType = useCallback(
    async (type: "Auto" | "Manual") => {
      setIsSubmitting(true);
      if (type === "Auto") {
        setIsSubmitting(false);
      } else if (type === "Manual") {
        try {
          const newLoan = await handleCreateOffer(
            request.collectionSymbol,
            inputtedAmount,
            numberOfLoans,
            isBRC20 ? "BRC20" : "ORDINAL",
          );
          setIsSubmitting(false);
          if (!newLoan) return;
          setModalState(
            "Share modal",
            () => {},
            () => (
              <ShareModal
                newLoan={newLoan}
                type="lend"
                numberOfOffers={numberOfLoans > 1 ? numberOfLoans : undefined}
                isBRC20={isBRC20}
              />
            ),
            false,
            false,
          );
          await onSubmit?.();
        } catch (error) {
          setIsSubmitting(false);
          setNotificationState(() => {
            //we need to be more specific
            if (error) {
              return (
                <GeneralNotification text={error.toString()} variant="error" />
              );
            } else {
              return (
                <GeneralNotification
                  text="An error occurred while creating the offer."
                  variant="error"
                />
              );
            }
          });
          console.error("Error in handleCreateOffer:", error);
        }
      }
    },
    [request, handleCreateOffer, inputtedAmount, onSubmit, numberOfLoans],
  );

  // Modal to choose between auto and manual signature
  const openSignModal = useCallback(() => {
    // TODO: this modal is not working??
    setModalState(
      "Sign Custom Request",
      () => {},
      () => <SignatureModal onSignatureTypeSelect={handleSignatureType} />,
      false,
      false,
    );
  }, [handleSignatureType]);

  const handleActivate = useCallback(
    async (request: any) => {
      if (new Date(request.endDate).getTime() < Date.now()) {
        setNotificationState(() => (
          <GeneralNotification text="Loan already expired." variant="error" />
        ));
        return;
      }
      // TODO: add loan.tag when ready for requests and offers
      else if (request?.borrower?.id === ordAddress)
        setNotificationState(() => (
          <GeneralNotification
            text="Awaiting lender signature"
            variant="error"
          />
        ));

      setIsSubmitting(true);
      await handleActivateOffer(request, async () => {
        setIsSubmitting(false);
        await onSubmit?.();
        setModalState(
          "Share modal",
          () => {},
          () => <ShareModal newLoan={request} type="activateLoan" />,
          false,
          false,
        );
      })
    },
    [ordAddress, handleActivateOffer, onSubmit],
  );

  useEffect(() => {
    if (stats) {
      const filterStats = ({
        LTV,
        bestOffer,
        ...rest
      }: LendingStats): Omit<LendingStats, "LTV" | "bestOffer"> => rest;
      const updatedStats = filterStats(stats);
      setUpdatedStats(updatedStats);
    }
  }, [stats]);

  useEffect(() => {
    if (type === "reviewAndSign") {
      setInputtedAmount(Number(bestOffer));
    }
  }, [bestOffer, type]);

  useEffect(() => {
    setTotalOfferAmount(inputtedAmount * numberOfLoans);
  }, [numberOfLoans, inputtedAmount]);

  const btcValue = parseFloat(
    convertSatToBtc(
      false,
      parseInt(request?.collection?.totalVolume?.toString() || "0"),
    ).toString(),
  ).toFixed(2);
  const subtitleBase = isBRC20
    ? `Market Cap: ${btcValue} BTC`
    : `Total Volume: ${btcValue} BTC`;

  const additionalProperties =
    type === "reviewAndSign" && !isBRC20 ? `#${request.number}` : "";
  const subtitle = `${subtitleBase}${additionalProperties}`;

  return (
    <div className="sm:w-[85vw] w-[100vw] max-w-[660px] bg-gray-800 sm:p-7 p-5 rounded-2xl flex flex-col sm:mx-3 mx-0 border border-white/20">
      {request.collection && (
        <ModalTitle
          isBRC20={isBRC20}
          collectionSymbol={request.collectionSymbol}
          img={
            isBRC20
              ? request.collection.imageURI
              : type === "reviewAndSign"
              ? `https://ord-mirror.magiceden.dev/content/${request.inscriptionId}`
              : request.collection.imageURI
          }
          title={request.collection.name}
          subtitle={subtitle}
        />
      )}
      <div className="mt-5">
        <StatsSection
          statItems={updatedStats}
          size="small"
          cardMinWidth={120}
        />
      </div>

      <p className="text-2xl font-bold text-gray-50 mt-8">Loan Info</p>
      <div className="mt-4">
        <InformationBar
          text="If the borrower fails to repay, you get this inscription."
          gray
        />
      </div>
      {isBRC20 && type === "createOffer" && (
        <div className="mt-4 ">
          <InformationBar
            text={`You can only make an offer against ${request.collection?.name} on a single loan.`}
            gray
          />
        </div>
      )}
      {alerts()}
      <div className="mt-5 flex justify-between w-full gap-3">
        <AmountsAndInterest
          defaultLoanAmount={bestOffer}
          onAmountChange={setInputtedAmount}
          bestOfferAmount={bestOffer}
          inputtedAmount={inputtedAmount}
          interestRate={interestRate}
          term={term}
          disableInput={type === "reviewAndSign"}
          type={type}
        />
      </div>
      {type === "createOffer" && (
        <div className="mt-4">
          <NumberIncrementer
            value={numberOfLoans}
            setValue={setNumberOfLoans}
            min={1}
            max={10}
          />
        </div>
      )}
      <div className="mt-7">
        <StepButtons
          leftText="Cancel"
          rightText={
            isUserSignedIn
              ? type === "createOffer"
                ? numberOfLoans > 1
                  ? numberOfLoans + "x " + "OFFERS"
                  : "OFFER"
                : "LEND"
              : "CONNECT"
          }
          rightOnClick={() =>
            isUserSignedIn
              ? type === "createOffer"
                ? handleSignatureType("Manual")
                : handleActivate(request)
              : handleConnect()
          }
          // TODO: change when Auto signature is implemented
          // type === "createOffer" ? openSignModal() : {}
          isSubmitting={isSubmitting}
          leftOnClick={() => close()}
          rightSubtitle={
            isUserSignedIn
              ? numberOfLoans > 1
                ? inputtedAmount + " BTC Each"
                : inputtedAmount + " BTC"
              : ""
          }
          disabled={
            isUserSignedIn
              ? inputtedAmount === 0 ||
                isRiskyLoan(inputtedAmount, floor, 85) ||
                inputtedAmount > floorInBtc ||
                inputtedAmount > 2 ||
                inputtedAmount > btcBalanceSatoshis ||
                totalOfferAmount > btcBalanceSatoshis
              : false
          }
        />
      </div>
    </div>
  );
};

export default LendModal;
