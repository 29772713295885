import { useState, useCallback } from "react";
import { httpsCallable } from "@firebase/functions";
import { functions } from "../../../firebaseConfig";
import { RequestType, GetMyOffersResponse } from "../../../types";
import { HttpsCallableResult } from "@firebase/functions-types";
import { useUserStore } from "../../../store";

// Hook to call Liquidium backend for all of a users offers
export const useGetMyHistoricalOffers = () => {
  const ordAddress = useUserStore((state) => state.ordAddress);
  const btcAddress = useUserStore((state) => state.btcAddress);
  const [loadingHistoricalLoans, setLoadingHistoricalLoans] =
    useState<boolean>(false);
  const [getHistoricalLoansError, setGetHistoricalLoansError] = useState<
    string | null
  >(null);
  const [historicalLoans, setHistoricalLoans] =
    useState<GetMyOffersResponse | null>(null);

  const getMyHistoricalOffers = httpsCallable(
    functions,
    "getMyHistoricalOffers"
  );

  const fetchMyHistoricalOffers = useCallback(
    async (request: RequestType) => {
      // Make sure userId is defined
      if (!request.userId || request.userId === "") {
        return;
      }

      setLoadingHistoricalLoans(true);
      try {
        const response: HttpsCallableResult = await getMyHistoricalOffers({
          userId: ordAddress,
          address: btcAddress
        });
        setHistoricalLoans(response.data as GetMyOffersResponse);
        setGetHistoricalLoansError(null);
      } catch (err) {
        if (err instanceof Error) {
          setGetHistoricalLoansError(err.message);
        } else {
          setGetHistoricalLoansError("An unexpected error occurred.");
        }
        setHistoricalLoans(null);
      } finally {
        setLoadingHistoricalLoans(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    fetchMyHistoricalOffers,
    loadingHistoricalLoans,
    historicalLoans,
    historicalLendings: historicalLoans?.historicalLendings || [],
    historicalBorrowings: historicalLoans?.historicalBorrowings || [],
    getHistoricalLoansError,
  };
};
