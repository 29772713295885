import { useCallback, useEffect, useState } from "react";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebaseConfig";
import { useUserStore } from "../../store";
import Loading from "../../components/Reusable/Loading";
import FeeSelector from "../../components/Reusable/FeeSelector";
import { Airdrop, FeeObject, FeeRateOptions } from "../../types";
import { signTransaction } from "sats-connect";
import { setNotificationState } from "../../notification";
import GeneralNotification from "../../notification/Notifications/GeneralNotification";
import useAuth from "../../auth";

type DLResponse = {
  base64: string;
  inputs: number;
} | null;

const AirdropClaim = () => {
  const wallet = useUserStore((state: any) => state.wallet);
  const btcAddress = useUserStore(state => state.btcAddress);
  const ordAddress = useUserStore(state => state.ordAddress);
  const airdrops = useUserStore(state => state.airdrops);
  const [claimEscrow, setClaimEscrow] = useState<Airdrop[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dlResponse, setDlResponse] = useState<DLResponse | null>(null);
  const [selectedLoanId, setSelectedLoanId] = useState(null);
  const [selectedFeeRate, setSelectedFeeRate] = useState<FeeObject>({
    label: FeeRateOptions.Medium,
    value: 0,
  } as FeeObject);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);

  const { getProvider } = useAuth();
  const getRsicClaimLoans = httpsCallable(functions, "getRsicClaimLoans");
  const checkForAirdrops = httpsCallable(functions, "checkForAirdrops")
  const prepareRsicClaim = httpsCallable(functions, "prepareRsicClaim");
  const broadcastRsicClaim = httpsCallable(functions, "broadcastRsicClaim");

  const handleGetLoansClick = useCallback(async () => {
    setIsLoading(true);

    try {
      // const response: any = await getRsicClaimLoans({ id: ordAddress });
      const response: any = await checkForAirdrops({ id: ordAddress });
      // const response: any = await checkForAirdrops({ id: "bc1p5k72p2w0ad5n0n59se7sk5cs86ktl7v7k4953nt3dmseduuldtfsdtq3em" });
      if (!response) {
        setNotificationState(() => (
          <GeneralNotification text={"Error"} variant="error" />
        ));
      } else if (response.data && !response.data.airdrops) {
        setClaimEscrow([]);
      } else {
        setClaimEscrow(response.data.airdrops);
      }

    } catch (e: any) {
      if (e.message === "Too many checks.") {
        setNotificationState(() => (
          <GeneralNotification text={"You can only check once a day"} variant="error" />
        ));
      } else {
        setNotificationState(() => (
          <GeneralNotification text={"Error"} variant="error" />
        ));
      }
    }

    setIsLoading(false);
  }, [ordAddress]);

  const handlePrepareClaim = useCallback(
    async airdrop => {
      if (selectedFeeRate.value === 0) {
        setNotificationState(() => (
          <GeneralNotification text={"Need a fee rate"} variant="error" />
        ));
        return;
      }

      setIsLoading(true);

      try {
        const response: any = await prepareRsicClaim({
          loanId: airdrop.id,
          type: airdrop.collateralType,
          feeRate: selectedFeeRate.value,
          item: airdrop.item,
        });
        const data = response.data;
        if (data.result === "All RSICs Claimed") {
          setNotificationState(() => (
            <GeneralNotification text={"All Airdrops Claimed from Escrow"} variant="error" />
          ));
          setIsLoading(false);
          setSelectedLoanId(null);
          return;
        }
        setDlResponse(data);
        setSelectedLoanId(airdrop.id);
      } catch (error: any) {
        if (error?.message.includes("Insufficient BTC balance")) {
          setNotificationState(() => (
            <GeneralNotification text={"Insufficient Confirmed BTC Balance"} variant="error" />
          ));
        } else {
          setNotificationState(() => (
            <GeneralNotification text={"Error"} variant="error" />
          ));
        }
      }

      setIsLoading(false);
    },
    [selectedFeeRate.value],
  );

  const handleClaim = useCallback(async () => {
    setIsLoading(true);

    if (!dlResponse?.inputs || !dlResponse?.base64) {
      setNotificationState(() => (
        <GeneralNotification text={"Error"} variant="error" />
      ));
      return;
    }

    const signingIndexes = Array.from(
      { length: dlResponse.inputs - 1 },
      (_, i) => i + 1,
    );

    const provider = getProvider();
    if (!provider) {
      throw new Error("could not get provider");
    }

    const signPsbtOptions = {
      getProvider: wallet === "magic_eden" ? async () => provider : undefined,
      payload: {
        network: {
          type: "Mainnet",
        },
        message: "Sign Transaction",
        psbtBase64: dlResponse.base64,
        inputsToSign: [
          {
            address: btcAddress,
            signingIndexes,
          },
        ],
      },
      onFinish: async (result: any) => {
        try {
          const response: any = await broadcastRsicClaim({
            loanId: selectedLoanId,
            base64: result.psbtBase64,
          });
          if (response.data.txid) {
            setNotificationState(() => (
              <GeneralNotification text={"Success"} variant="success" />
            ));
          }
        } catch (e) {
          setNotificationState(() => (
            <GeneralNotification text={"Error broadcasting"} variant="error" />
          ))
        }

        setIsLoading(false);
        setSelectedLoanId(null);
        setDlResponse(null);
        setClaimEscrow(null);
      },
    };

    // @ts-ignore
    await signTransaction(signPsbtOptions);
  }, [getProvider, wallet, selectedLoanId, btcAddress, dlResponse]);

  return (
    <div className={"sm:mt-10 mt-6 justify-center"}>
      <div className="max-w-[1400px] w-full">
        <div className="flex w-full justify-between items-center">
          <SectionTitle
            title="Airdrop Claim"
            learnMore=""
            alwaysShowInfo
            infoText={
              <>
                <p className="text-gray-400 font-semibold text-xs sm:text-sm mt-2">
                  Check for Airdrops that may have been sent to one of
                  your previous loans.
                  <br />
                  Please claim one Airdrop at a time.
                  <br />
                  Wait for the claim transaction to confirm before starting another.
                </p>
              </>
            }
          />
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="sm:mt-7 mt-5 mb-10">
          {isLoading ? (
            <div className="h-[50px]">
              <Loading />
            </div>
          ) : claimEscrow === null ? (
            <button
              onClick={handleGetLoansClick}
              className="bg-orange-500/90 hover:bg-orange-400 transition-colors border border-black/20 text-zinc-800 font-bold p-2 py-1 rounded-md sm:mt-3 mt-2 sm:text-base text-sm cursor-pointer px-4"
            >
              Check My Escrows (May take a while)
            </button>
          ) : claimEscrow.length === 0 ? (
            <p className="text-gray-50 font-extrabold sm:text-2xl text-xl flex items-center gap-2">
              No Airdrops to Claim
            </p>
          ) : dlResponse !== null ? (
            <button
              onClick={handleClaim}
              className="block mx-auto bg-orange-500/90 hover:bg-orange-400 transition-colors border border-black/20 text-zinc-800 font-bold p-2 py-1 rounded-md sm:mt-3 mt-2 sm:text-base text-sm cursor-pointer px-4"
            >
              Sign and Unlock Airdrop!
            </button>
          ) : (
            <div className="mx-auto text-center">
              <p className="text-gray-50 font-extrabold sm:text-2xl text-xl flex items-center gap-2">
                Found Escrows with Airdrops!
              </p>
              <FeeSelector
                selectedFeeRate={selectedFeeRate}
                setSelectedFeeRate={setSelectedFeeRate}
              />
              {selectedFeeRate.value !== 0 &&
                claimEscrow.map((airdrop, i) => (
                  <div>
                    <p className="mt-4 text-gray-50 font-extrabold sm:text-xl text-xl flex items-center gap-2">
                    {i + 1} | {airdrop.collateralType === "RUNE" ? "Rune" : "Ordinal"}
                    </p>
                    <div className="flex items-center justify-center gap-4">
                      <button
                        onClick={() => handlePrepareClaim(airdrop)}
                        className="bg-orange-500/90 hover:bg-orange-400 transition-colors border border-black/20 text-zinc-800 font-bold p-2 py-1 rounded-md sm:mt-3 mt-2 sm:text-base text-sm cursor-pointer px-4"
                      >
                        Prepare Transaction
                      </button>
                      <a
                        href={airdrop.collateralType === "RUNE"
                          ? `https://ordiscan.com/address/${airdrop.escrowAddress}/runes`
                          : `https://ordiscan.com/address/${airdrop.escrowAddress}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-orange-500/90 hover:bg-orange-400 transition-colors border border-black/20 text-zinc-800 font-bold p-2 py-1 rounded-md sm:mt-3 mt-2 sm:text-base text-sm cursor-pointer px-4"
                      >
                        View Escrow
                      </a>

                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AirdropClaim;
