import { useState, useCallback } from "react";
import { httpsCallable } from "@firebase/functions";
import { functions } from "../../../firebaseConfig";
import { RequestType, GetMyOffersResponse } from "../../../types";
import { HttpsCallableResult } from "@firebase/functions-types";
import { setModalState } from "../../../modal";
import SelectWalletModal from "../../../modal/Modals/SelectWalletModal/SelectWalletModal";
import { setNotificationState } from "../../../notification";
import GeneralNotification from "../../../notification/Notifications/GeneralNotification";

// Hook to call Liquidium backend for all of a users offers
export const useGetMyOffers = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<GetMyOffersResponse | null>(null);

  const getMyOffers = httpsCallable(functions, "getMyOffers");

  const fetchMyOffers = useCallback(
    async (request: RequestType) => {
      // Make sure userId is defined
      if (!request.userId || request.userId === "") {
        return;
      }

      setLoading(true);
      try {
        const response: HttpsCallableResult = await getMyOffers(request);
        setData(response.data as GetMyOffersResponse);
        setError(null);
      } catch (err) {
        if (err instanceof Error) {
          if (
            err.message === "The function must be called while authenticated."
          ) {
            setNotificationState(() => (
              <GeneralNotification
                text={"Session expired, please reconnect."}
                variant="error"
              />
            ));
            setModalState(
              "Select wallet modal",
              () => {},
              () => <SelectWalletModal reAuthenticate />,
              false,
              false,
            );
          }
          setError(err.message);
        } else {
          setError("An unexpected error occurred.");
        }
        setData(null);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    fetchMyOffers,
    loading,
    data,
    pending: data?.pending || [],
    borrowings: data?.borrowings || [],
    lendings: data?.lendings || [],
    error,
  };
};
