import { FC, useCallback, useEffect, useState } from "react";
import { useModalStore } from "../..";
import ModalTitle from "../../components/ModalTitle";
import StatsSection from "../../../components/Reusable/StatsSection/StatsSection";
import {
  ActiveBorrowingLendingsStats,
  FeeObject,
  Offer,
  repaymentInfo,
} from "../../../types";
import LoanDuration from "../../components/LoanDuration/LoanDuration";
import StepButtons from "../../components/StepButtons";
import NFTAssetCard from "../../../components/Reusable/NFTAssetCard/NFTAssetCard";
import {
  calculateRepaymentInfo,
  checkExpiryAndDaysRemaining,
  convertSatToBtc,
  roundToDecimalPlaces,
} from "../../../utils";
import useCurrentBlock from "../../../hooks/api/useCurrentBlock/useCurrentBlock";
import useDeepLake from "../../../hooks/deeplake/useDeepLake";
import useBlockTimeDifference from "../../../hooks/api/useBlockTimeDifference";
import FeeSelector from "../../../components/Reusable/FeeSelector";
import { useUserStore } from "../../../store";
import InformationBar from "../../components/InformationBar";

// Define the type for the filtered stats
type FilteredStats = Omit<ActiveBorrowingLendingsStats, "remaining">;

interface RepayModalProps {
  request: Offer;
  stats: ActiveBorrowingLendingsStats;
  onSubmit?: Function;
}

const RepayModal: FC<RepayModalProps> = ({ request, stats, onSubmit }) => {
  const { close } = useModalStore();
  const { handleRepayOffer } = useDeepLake();
  const btcBalance = useUserStore((state) => state.btcBalance);
  const [selectedFeeRate, setSelectedFeeRate] = useState<FeeObject | undefined>(
    undefined,
  );
  const [updatedStats, setUpdatedStats] = useState<
    ActiveBorrowingLendingsStats | undefined
  >();
  const [startDateInMilliseconds, setStartDateInMilliseconds] =
    useState<number>();
  const [endDateInMilliseconds, setEndDateInMilliseconds] = useState<number>();
  // const [endBlock, setEndBlock] = useState<number>();
  const [timeDifference, setTimeDifference] = useState<{
    days: number;
    hours: number;
  }>();
  // const { currentBlock } = useCurrentBlock();
  const [repaymentInfo, setRepaymentInfo] = useState<repaymentInfo>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [remaining, setRemaining] = useState<number | string>(0);

  //Repay Loan Click Handler
  const handleSubmit = useCallback(async () => {
    if (!selectedFeeRate) return;
    setIsSubmitting(true);
    await handleRepayOffer(request, selectedFeeRate?.value, async () => {
      await onSubmit?.();
      close();
    });
  }, [handleRepayOffer, request, onSubmit, close, selectedFeeRate]);

  //format date for footer
  const formatDate = (dateInput: string | number) => {
    let date;
    if (typeof dateInput === "string") {
      date = new Date(dateInput);
    } else if (typeof dateInput === "number") {
      date = new Date(dateInput * 1000); // if it's a UNIX timestamp, it's in seconds, so we convert to milliseconds
    } else {
      return ""; // return an empty string if the input type is not recognized
    }

    // Get the month name
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];

    // Get the day
    const day = date.getDate();

    // Get the hours and minutes
    let hours = date.getHours();
    let year = date.getFullYear();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutes = ("0" + date.getMinutes()).slice(-2);

    return `${hours}:${minutes} ${ampm} on ${month} ${day}, ${year}`;
  };

  //Remove remaining stat from stats array
  useEffect(() => {
    if (stats) {
      if (stats.remaining?.value) {
        setRemaining(stats.remaining.value);
      }
      const filterStats = ({
        remaining,
        ...rest
      }: ActiveBorrowingLendingsStats): Omit<
        ActiveBorrowingLendingsStats,
        "remaining"
      > => rest;
      const updatedStats = filterStats(stats);
      setUpdatedStats(updatedStats);
    }
  }, [stats]);

  //Set start and end date in milliseconds
  useEffect(() => {
    if (!request) return;
    const paymentInfo = calculateRepaymentInfo(
      request.principal,
      request.interestRate,
      request.term,
    );
    setRepaymentInfo(paymentInfo);

    const startDate = request.startDate
      ? new Date(request.startDate).getTime()
      : 0;
    const endDate = request.endDate ? new Date(request.endDate).getTime() : 0;

    setStartDateInMilliseconds(startDate);
    setEndDateInMilliseconds(endDate);

    const { daysRemaining, hoursRemaining } = checkExpiryAndDaysRemaining(
      request.endDate,
    );

    setTimeDifference({ days: daysRemaining, hours: hoursRemaining });

    // const endBlock = (currentBlock ? currentBlock : 0) + daysRemaining * 144; // CALCULATR ENDBLOCK USING time Not DAYS
    // setEndBlock(endBlock);
  }, [request]);

  const modalTitle = () => {
    return (
      <ModalTitle
        collectionSymbol={request.collectionSymbol}
        title={request.collection.name}
        subtitle={`Trading Volume: ${roundToDecimalPlaces(
          convertSatToBtc(
            false,
            parseFloat(request?.collection?.totalVolume?.toString()),
          ),
          2,
        )} BTC`}
      />
    );
  };

  const loadDuration = () => (
    <LoanDuration
      startDate={startDateInMilliseconds}
      endDate={endDateInMilliseconds}
      remaining={remaining}
    />
  );

  const assetsMetaData = request.collateral?.assets
    ?.flatMap((asset: any) => asset.content.meta)
    ?.filter((meta: any) => meta);

  const alerts = useCallback(() => {
    if (!repaymentInfo) return;
    if (btcBalance < repaymentInfo?.totalRepayment) {
      return (
        <div className="mt-4">
          <InformationBar text="Not able to repay. You don't have enough BTC." />
        </div>
      );
    }
  }, [repaymentInfo, btcBalance]);

  return (
    <div className="border border-white/20  sm:w-[85vw] w-[100vw] max-w-[660px] bg-gray-800 sm:p-7 p-4 rounded-2xl flex flex-col sm:mx-3 mx-0">
      <div className="sm:flex-row flex w-full sm:gap-3 gap-2 flex-col sm:items-start items-center ">
        <div className="sm:hidden w-full mb-1">{modalTitle()}</div>
        <div className=" sm:mb-0 mb-3 w-full max-w-[160px] sm:block hidden">
          {/* For desktop */}
          {/* TODO: need to pass same values as in content component */}
          {request.collateral && (
            <NFTAssetCard
              ref={null}
              key={1}
              BRC20CollectionImgURI={
                request.collection.collateralType === "BRC20"
                  ? request.collection.imageURI
                  : null
              }
              assets={assetsMetaData}
              onClick={() => {}}
              isSelected={false}
              variation="small"
            />
          )}
        </div>
        {/* For mobile, sets ordinal card to fill screen horizontally*/}
        <div className=" sm:mb-0 mb-3 w-full  sm:hidden ">
          {/* TODO: need to pass same values as in content component */}
          {request.collateral && (
            <NFTAssetCard
              landscape={true}
              BRC20CollectionImgURI={
                request.collection.collateralType === "BRC20"
                  ? request.collection.imageURI
                  : null
              }
              ref={null}
              key={1}
              assets={assetsMetaData}
              onClick={() => {}}
              isSelected={false}
              variation="small"
            />
          )}
        </div>
        <div className="flex flex-col h-full w-full sm:gap-4 gap-2 justify-around -mt-2">
          <div className="sm:block hidden">{modalTitle()}</div>
          <StatsSection
            statItems={updatedStats}
            size="small"
            cardMinWidth={72}
          />
          <div className="sm:block hidden">{loadDuration()}</div>
        </div>
      </div>
      {/* restructure for mobile */}
      <div className="sm:hidden pt-4">{loadDuration()}</div>
      {alerts()}
      <div className="mt-4">
        <FeeSelector
          selectedFeeRate={selectedFeeRate}
          setSelectedFeeRate={setSelectedFeeRate}
        />
      </div>
      <p className="text-xs sm:text-sm text-gray-50 font-semibold mt-5 text-right w-full">
        You have to repay a total of{" "}
        <strong>
          ₿{" "}
          {roundToDecimalPlaces(
            convertSatToBtc(false, repaymentInfo?.totalRepayment),
            6,
          )}
        </strong>{" "}
        by {request.endDate && formatDate(request.endDate)}
      </p>
      <div className="mt-6">
        <StepButtons
          isSubmitting={isSubmitting}
          leftText="CANCEL"
          rightText="REPAY"
          leftOnClick={() => close()}
          rightOnClick={handleSubmit}
          rightSubtitle={
            roundToDecimalPlaces(
              convertSatToBtc(false, repaymentInfo?.totalRepayment),
              6,
            ) + " BTC"
          }
          disabled={
            !selectedFeeRate ||
            (selectedFeeRate && selectedFeeRate.value === 0) ||
            (repaymentInfo && btcBalance < repaymentInfo?.totalRepayment)
          }
        />
      </div>
    </div>
  );
};

export default RepayModal;
