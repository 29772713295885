import Big from "big.js";
import { repaymentInfo } from "./types";
import * as btc from "@scure/btc-signer";
import { bytesToHex } from "@noble/hashes/utils";

// Function to calculate the virtual size of a SegWit transaction
export const calculateSegWitTxSize = (
  numInputs: number,
  numOutputs: number,
) => {
  try {
    // Constants for byte sizes
    const NON_WITNESS_DATA_PER_INPUT = 41; // non-witness data per input in bytes
    const NON_WITNESS_DATA_PER_OUTPUT = 31; // non-witness data per output in bytes
    const WITNESS_DATA_PER_INPUT = 108; // average witness data per input in bytes

    // Calculate base size (non-witness data)
    const baseSize =
      10 +
      NON_WITNESS_DATA_PER_INPUT * numInputs +
      NON_WITNESS_DATA_PER_OUTPUT * numOutputs;

    // Calculate total size (base size + witness data)
    const totalSize = baseSize + WITNESS_DATA_PER_INPUT * numInputs;

    // Calculate transaction weight
    const txWeight = baseSize * 3 + totalSize;

    // Calculate virtual size in vB (virtual bytes)
    const txSizeVBytes = Math.ceil(txWeight / 4);

    return txSizeVBytes;
  } catch (error) {
    console.error("Error calculating SegWit transaction size:", error);
    return null;
  }
};

// ANY CHANGES HERE NEED TO BE MADE IN /server/functions/utils/deeplake.js TOO
export const calculateRepaymentInfo = (
  principal: number | string,
  apy: number,
  termDays: number,
) => {
  const bigPrincipal = Big(principal);

  // Use calculateAccruedInterestPercentage to get the percentage
  const accruedInterestPercent = calculateAccruedInterestPercentage(
    apy,
    termDays,
  );
  const accruedInterest = bigPrincipal.times(
    Big(accruedInterestPercent).div(100),
  );

  // Calculate total repayment: (Principal + Accrued Interest)
  const totalRepayment = bigPrincipal.plus(accruedInterest);

  // Calculate Liquidium fee: 20% of Accrued Interest
  let liquidiumFee = accruedInterest.times(0.2);

  // Calculate repayment to the lender
  let lenderRepayment;
  if (liquidiumFee.toNumber() < 2000) {
    // if liquidium fee too small, don't collect any fee
    lenderRepayment = totalRepayment;
    liquidiumFee = Big(0);
  } else {
    // else, split interest accrued 80-20
    lenderRepayment = totalRepayment.minus(liquidiumFee);
  }

  // Round up the values to the nearest integer
  const roundedLiquidiumFee = Math.ceil(Number(liquidiumFee));
  const roundedLenderRepayment = Math.ceil(Number(lenderRepayment));
  const roundedTotalRepayment = Big(roundedLiquidiumFee)
    .plus(Big(roundedLenderRepayment))
    .toNumber();

  return {
    totalRepayment: roundedTotalRepayment,
    liquidiumFee: roundedLiquidiumFee,
    lenderRepayment: roundedLenderRepayment,
    totalInterestAmount: accruedInterest.toNumber(),
    lenderInterestAmount: lenderRepayment.minus(bigPrincipal).toNumber(),
  } as repaymentInfo;
};

// Function to check if the offer has expired and to get days/hours remaining
export const checkExpiryAndDaysRemaining = (
  offerEndDate: number | string | null | undefined,
) => {
  if (!offerEndDate)
    return {
      isExpired: false,
      daysRemaining: 0,
      hoursRemaining: 0,
      remainingHoursWithinDay: 0,
    };

  const currentDate = new Date();
  const endDate = new Date(offerEndDate);
  const timeDifference = endDate.getTime() - currentDate.getTime();

  // Days calculation
  let daysRemaining = Math.floor(timeDifference / (1000 * 3600 * 24));

  // Hours calculation: Convert milliseconds to hours
  const hoursRemaining = timeDifference / (1000 * 3600);

  // Remaining hours within the current day
  const remainingHoursWithinDay = Math.floor(
    (timeDifference % (1000 * 3600 * 24)) / (1000 * 3600),
  );

  const isExpired = timeDifference <= 0;

  return {
    isExpired,
    daysRemaining,
    hoursRemaining: parseFloat(hoursRemaining.toFixed(2)),
    remainingHoursWithinDay: remainingHoursWithinDay,
  };
};

// ANY CHANGES HERE NEED TO BE MADE IN /server/functions/utils/deeplake.js TOO
export const calculateAccruedInterestPercentage = (
  apy: number,
  duration: number,
): number => {
  if (!apy || !duration) return 0;

  const base = Big(1).plus(Big(apy).div(100));
  const exponent = Big(duration).div(365).toNumber();

  // Using Math.pow only for the exponentiation part
  const result = Big(Math.pow(base.toNumber(), exponent)).minus(1);

  return parseFloat(result.times(100).toFixed(2));
};

export const convertSatToBtc = (
  isSat: boolean,
  amount: number | undefined | null,
) => {
  if (!amount) return 0;
  return isSat
    ? Math.ceil(amount).toLocaleString()
    : parseFloat(parseFloat(Big(amount).div(100000000).toFixed(8)).toString());
};

// only used in useDeepLake hook for now
export const convertBtcToSat = (isSat: boolean, amount: number | undefined) => {
  if (!amount) return 0;
  if (isSat) {
    // When the amount is already in satoshis, just return it.
    return amount;
  } else {
    // Convert from Bitcoin to satoshis and use Math.floor to avoid decimals.
    return Math.floor(Big(amount).mul(100000000).toNumber());
  }
};

const omitTypename = (key: any, value: any) => {
  return key === "__typename" ? undefined : value;
};

export const omitDeep = (data: any) => {
  if (!data) return data;
  return JSON.parse(JSON.stringify(data), omitTypename);
};

export const base64ToHex = (str: string) => {
  const result = atob(str)
    .split("")
    .map((c) => c.charCodeAt(0).toString(16).padStart(2, "0"))
    .join("");
  return result;
};

export const basisPointsToPercent = (number: number) => {
  return Big(number).div(100).toNumber();
};

export const blockToTime = (
  currentBlockHeight: number,
  compareBlockHeight: number,
  short: boolean = false,
) => {
  const blockHeightDifference =
    Big(compareBlockHeight).minus(currentBlockHeight);
  const blockTimeDifferenceInMinutes = blockHeightDifference.mul(10);
  const weeks = Math.floor(
    blockTimeDifferenceInMinutes.div(60 * 24 * 7).toNumber(),
  );
  const days = Math.floor(
    blockTimeDifferenceInMinutes
      .mod(60 * 24 * 7)
      .div(60 * 24)
      .toNumber(),
  );
  const hours = Math.floor(
    blockTimeDifferenceInMinutes
      .mod(60 * 24)
      .div(60)
      .toNumber(),
  );
  const minutes = Math.floor(blockTimeDifferenceInMinutes.mod(60).toNumber());
  let timeString = "";
  if (weeks > 0) {
    timeString += `${weeks} ${short ? "W" : "Week"}`;
    if (weeks > 1 && !short) {
      timeString += "s";
    }
    timeString += " ";
  }
  if (days > 0) {
    timeString += `${days}${short ? "D" : " Day"}`;
    if (days > 1 && !short) {
      timeString += "s";
    }
    timeString += " ";
  }
  if (hours > 0) {
    timeString += `${hours}${short ? "H" : " Hour"}`;
    if (hours > 1 && !short) {
      timeString += "s";
    }
    timeString += " ";
  }
  if (minutes > 0) {
    timeString += `${minutes}${short ? "M" : " Minute"}`;
    if (minutes > 1 && !short) {
      timeString += "s";
    }
  }
  return timeString.trim();
};

export const estimateInterest = (
  amount: number,
  blocks: number,
  interest: number,
) => {
  return Big(amount)
    .times(interest)
    .times(Big(blocks).div(4380))
    .div(100)
    .round()
    .toNumber();
};

export const formatAddress = (address: string) => {
  const len = address.length;
  return address.substring(0, 5) + ".." + address.substring(len - 5, len);
};

export const microStxToStx = (mSTX: number) => {
  return Big(mSTX).div(1000000).toNumber();
};

export const roundTwo = (number: number) => {
  return Big(number).round(2).toFixed(2);
};

export const roundToDecimalPlaces = (
  value: number | string,
  places: number,
): number => {
  if (typeof value === "string") {
    value = parseFloat(value);
  }

  if (isNaN(value)) {
    throw new Error("The provided value is not a valid number.");
  }

  const multiplier = Math.pow(10, places);
  return parseFloat(
    (Math.round(value * multiplier) / multiplier).toFixed(places),
  );
};

export const stxToMicroStx = (STX: number) => {
  return Big(STX).times(1000000).toNumber();
};

//Leather helper functions

const bitcoinMainnet = {
  bech32: "bc",
  pubKeyHash: 0x00,
  scriptHash: 0x05,
  wif: 0x80,
};

export const buildNativeSegwitPsbtRequestOptions1 = (pubKey: Uint8Array) => {
  const p2wpkh = btc.p2wpkh(pubKey, bitcoinMainnet);
  const tx = new btc.Transaction();

  tx.addInput({
    index: 0,
    txid: "15f34b3bd2aab555a003cd1c6959ac09b36239c6af1cb16ff8198cef64f8db9c",
    witnessUtxo: {
      amount: BigInt(1000),
      script: p2wpkh.script,
    },
  });

  const psbt = tx.toPSBT();
  return { hex: bytesToHex(psbt) };
};

export const extractAccountNumber = (path: string) => {
  const segments = path.split("/");
  const accountNum = parseInt(segments[3].replaceAll("'", ""), 10);
  if (isNaN(accountNum))
    throw new Error("Cannot parse account number from path");
  return accountNum;
};
