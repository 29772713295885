import { lazy, useMemo } from "react";
import { faGavel, faLandmark } from "@fortawesome/free-solid-svg-icons";
import { RouteType, RouteGroupType } from "../../../types";
import { useUserStore } from "../../../store";

// Pages
const Construction = lazy(() => import("../../../pages/Construction"));
// Ordinals
// const OrdP2PBrowse = lazy(() => import("../../../pages/OrdP2PBrowse"));
// const OrdP2PBorrow = lazy(() => import("../../../pages/OrdP2PBorrow"));
// const OrdP2PLend = lazy(() => import("../../../pages/OrdP2PLend"));
const OrdP2PPortfolio = lazy(() => import("../../../pages/OrdP2PPortfolio"));
// const BRC20 = lazy(() => import("../../../pages/BRC20"));
const Ordinals = lazy(() => import("../../../pages/Ordinals"));
const AirdropClaim = lazy(() => import("../../../pages/RsicClaim"));

const useRoutes = (): {
  navRoutes: RouteGroupType[];
  allRoutes: RouteType[];
  ordinalRoutes: RouteType[];
} => {
  const myOrdAddress = useUserStore((state) => state.ordAddress);
  const myBtcAddress = useUserStore((state) => state.btcAddress);

  const ordinalRoutes = useMemo(
    () => [
      // {
      //   name: "Ordinals",
      //   href: "/ordinals",
      //   component: Ordinals,
      //   icon: faLandmark,
      // },
      {
        name: "Airdrop Claim",
        href: "/airdrop-claim",
        component: AirdropClaim,
        icon: faLandmark,
      },
      {
        name: "Portfolio",
        href: "/portfolio",
        component: OrdP2PPortfolio,
        icon: faLandmark,
      },
    ],
    [],
  );

  const allRoutes = useMemo(
    () => [
      {
        name: "Construction",
        href: "/Construction",
        component: Construction,
        icon: faGavel,
      },
      // {
      //   name: "Borrow brc-20 tokens with collection symbol",
      //   href: "/brc20/borrow/:collectionSymbol",
      //   component: BRC20,
      //   icon: faLandmark,
      // },
      // {
      //   name: "Lend brc-20 tokens with collection symbol",
      //   href: "/brc20/lend/:collectionSymbol",
      //   component: BRC20,
      //   icon: faLandmark,
      // },
      ...ordinalRoutes,
    ],
    [ordinalRoutes],
  );

  const navRoutes = useMemo(() => [], [ordinalRoutes]);

  return { navRoutes, allRoutes, ordinalRoutes };
};

export default useRoutes;
