import { initializeApp } from "firebase/app";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import Config from "./config";

const environments = {
  prod: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  },
  test: {
    apiKey: process.env.REACT_APP_FIREBASE_TEST_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_TEST_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_TEST_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_TEST_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_TEST_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_TEST_APP_ID,
  },
  dev: {
    apiKey: process.env.REACT_APP_FIREBASE_DEV_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DEV_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_DEV_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_DEV_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_DEV_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_DEV_APP_ID,
  },
};

const currentEnv = Config.Env || "dev";
const firebaseConfig = environments[currentEnv];

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);

// TODO: add solution for this condition
// if (currentEnv === "dev") {
//   connectFirestoreEmulator(db, "localhost", 8188);
//   connectStorageEmulator(storage, "localhost", 9199);
//   connectFunctionsEmulator(functions, "localhost", 5001);
//   connectAuthEmulator(auth, "http://localhost:9099");
// }

export { auth, db, functions, storage };
