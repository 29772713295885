import React, { FC } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import Tooltips from "../../../../components/Reusable/Tooltip/Tooltip";

interface GraphCardProps {
  title: string;
  data: any;
  tooltip?: string;
}

const GraphCard: FC<GraphCardProps> = ({ title, data, tooltip }) => {
  const ref = React.useRef(null);

  const formatTickItem = (value: number) => {
    return value % 1 !== 0 ? value.toFixed(3) : value.toString();
  };

  const maxValue = Math.max(
    ...data.map((item: { value: number }) => item.value),
  );

  return (
    <Tooltips content={tooltip}>
      <div className="flex w-full flex-col border border-white/20 rounded-lg p-1 gap-3 pr-2 pl-2">
        <div className="flex justify-between items-center  pl-2 pt-2 pr-2">
          <p className=" text-zinc-300 font-semibold sm:text-lg text-sm">
            {title}
          </p>
        </div>
        <div className="w-full h-[200px] ">
          <ResponsiveContainer
            width="100%"
            height="100%"
            className={"-ml-3"}
            ref={ref}
          >
            <LineChart data={data} margin={{ right: 10 }}>
              {" "}
              {/* Adjusted margin */}
              <Line
                type="monotone"
                dataKey="value"
                stroke="#D95228"
                strokeWidth={3}
                dot={false}
              />
              <CartesianGrid stroke="#52525B" strokeDasharray="5 5" />
              <XAxis dataKey="name" />
              <YAxis
                tickFormatter={formatTickItem}
                width={60}
                domain={[0, maxValue]}
              />
              {/* Added width and tickFormatter */}
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Tooltips>
  );
};

export default GraphCard;
