import React, { useEffect, useState } from "react";
import bellIconDark from "../../assets/icons/bell_dark_mode.svg";
import useMyNotifications from "../../hooks/useMyNotifications";
import useComponentVisible from "../../hooks/react/useComponentVisible";
import NotificationItem from "../NotificationItem";
import styles from "./NotificationsMenu.module.css";
import { useNavigate } from "react-router-dom";
import LoadingBar from "../Reusable/LoadingBar/Loading";
import { UserData } from "../../types";

const NotificationsMenu = ({
  userData,
  loadingUserData,
}: {
  userData: UserData | null;
  loadingUserData: boolean;
}) => {
  const notifications = useMyNotifications();
  const { ref, ref2, isComponentVisible, setIsComponentVisible } =
    useComponentVisible({});
  const navigate = useNavigate();

  return (
    <div className="flex items-center mt-[2px]">
      <div className="relative">
        <button
          ref={ref2}
          onClick={() => setIsComponentVisible((prev) => !prev)}
        >
          <img
            className={styles.icon + " h-6 -mt-[1px] md:h-7"}
            src={bellIconDark}
            alt="Notification Icon"
          />
        </button>
        {notifications.length > 0 && (
          <div
            className={
              styles.notificationsNumber + " sm:-ml-1 -ml-1 sm:mt-0 mt-1"
            }
          >
            <p className="text-white font-bold text-xs">
              {notifications.length}
            </p>
          </div>
        )}
        <div
          className={`${styles.triangle} ${isComponentVisible ? "" : "hidden"}`}
        />
      </div>
      <div
        className={`blurBackgroundXL border border-white/20 p-4 flex flex-col  rounded-xl w-[280px] h-[288px] absolute z-10 top-[82px] -translate-x-[240px] `}
        style={isComponentVisible ? {} : { display: "none" }}
        ref={ref}
      >
        {notifications.length !== 0 && (
          <p className="sm:text-sm text-xs font-extrabold text-gray-50 text-center">
            Notifications
          </p>
        )}
        {loadingUserData ? (
          <div className="flex h-full items-center">
            <LoadingBar />
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-2 h-[180px] overflow-scroll custom-scrollbar mt-4">
              {notifications.length === 0 ? (
                <p className="flex h-full w-full justify-center items-center text-base font-extrabold text-gray-400/70">
                  No Notifications
                </p>
              ) : (
                <>
                  {notifications.map((notif, i) => (
                    <NotificationItem notif={notif} key={i} />
                  ))}
                </>
              )}
            </div>
            <div className="w-full h-[1px] bg-gray-500 mb-4"></div>

            <button
              className="border border-white/20 bg-white text-base sm:text-lg font-extrabold rounded-md p-1 hover:bg-opacity-80 flex justify-center items-center"
              onClick={() => navigate("/settings/notifications")}
            >
              {userData?.email || userData?.telegramChatId
                ? "Manage"
                : "Enable"}
            </button>
          </>
        )}
      </div>
      {/* <div
        ref={ref}
        className={`${styles.box} ${isMobile && styles.mobile}`}
        id={styles[`${theme}Box`]}
        style={isComponentVisible ? {} : { display: "none" }}
      >
        <div className="flex flex-col gap-1">
          <div className={styles.notifications + " custom-scrollbar"}>
            {notifications.length === 0 ? (
              <NoContentMessage contentName="Notifications" small icon />
            ) : (
              <>
                {notifications.map((notif, i) => (
                  <NotificationItem notif={notif} key={i} />
                ))}
              </>
            )}
          </div>
          {!isUserLoading && (
            <button
              className="sm:text-xl text-lg font-semibold text-gray-50 bg-gray-500 rounded-xl sm:px-4  py-2 w-full  hover:bg-opacity-80 border border-white/20 z-10 "
              onClick={() => navigate("/settings/notifications")}
            >
              {userData?.email && userData.telegramChatId ? "Manage" : "Enable"}
            </button>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default NotificationsMenu;
