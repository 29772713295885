import { useEffect } from "react";
import { create } from "zustand";
import styles from "./Modal.module.css";

type ModalState = {
  title: string;
  content: () => JSX.Element;
  onSubmit?: () => void;
  close: () => void;
  wide: boolean;
  background: boolean;
  shouldCloseOnOutsideClick: boolean;
};

export const useModalStore = create<ModalState>((set) => ({
  title: "",
  onSubmit: undefined,
  content: () => <></>,
  close: () => set({ onSubmit: undefined }),
  wide: false,
  background: true,
  shouldCloseOnOutsideClick: true, // default value
}));

export const setModalState = (
  title: string,
  onSubmit: () => void,
  content: () => JSX.Element,
  wide?: boolean,
  background?: boolean,
  shouldCloseOnOutsideClick?: boolean, // new optional parameter
) => {
  useModalStore.setState({
    title,
    wide: wide,
    background: background === undefined ? true : background,
    onSubmit,
    content,
    shouldCloseOnOutsideClick:
      shouldCloseOnOutsideClick === undefined
        ? true
        : shouldCloseOnOutsideClick,
  });
};

const Modal = () => {
  const { onSubmit, content, close, shouldCloseOnOutsideClick } =
    useModalStore();

  useEffect(() => {
    close();
  }, [close]);

  if (!onSubmit) return null;

  return (
    <div
      className={`${styles.modalWrapper} fixed inset-0 flex items-center justify-center bg-black/70 z-[1000] overflow-y-auto`}
      onClick={shouldCloseOnOutsideClick ? close : undefined}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`${styles.hideScroll} bg-none rounded-lg shadow-lg z-[1001] box-border max-h-screen  overscroll-x-none py-12 overflow-x-hidden `}
      >
        {content()}
      </div>
    </div>
  );
};

export default Modal;
