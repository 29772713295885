import Text from "../Text";
import styles from "./Checkbox.module.css";

const Checkbox = ({
  isChecked,
  onClick,
  children,
  disabled,
}: {
  isChecked: boolean;
  onClick: Function;
  children: React.ReactNode;
  disabled?: boolean;
}) => {
  return (
    <div className={styles.component}>
      <input
        type="checkbox"
        checked={!disabled && isChecked}
        onChange={() => (disabled ? {} : onClick())}
        className={disabled ? styles.disabled : undefined}
      />
      <Text variant="Subtitle" color="White">
        {children}
      </Text>
    </div>
  );
};

export default Checkbox;
