import { useState, useCallback } from "react";
import { httpsCallable } from "@firebase/functions";
import { functions } from "../../../firebaseConfig";

import { HttpsCallableResult } from "@firebase/functions-types";
import { GetOffersBestResponse, LoanType, RequestType } from "../../../types";

export const useGetOffersBest = () => {
  const [loadingOffers, setLoadingOffers] = useState<boolean>(false);
  const [offersBest, setOffersBest] = useState<LoanType[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  const getOffersBest = httpsCallable(functions, "getOffersBest");

  const fetchOffersBest = useCallback(async (request: RequestType) => {
    // Make sure userId is defined
    if (!request.userId && request.userId === "") {
      return;
    }

    // Make sure you have defined the SortType in the types directory
    setLoadingOffers(true);
    try {
      const response: HttpsCallableResult = await getOffersBest(request);
      setOffersBest(response.data.offers as LoanType[]);
      setError(null);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unexpected error occurred.");
      }
      setOffersBest(null);
    } finally {
      setLoadingOffers(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loadingOffers,
    offersBest,
    error,
    fetchOffersBest,
  };
};
