import React, { FC, FormEvent, useCallback, useEffect, useState } from "react";
import { InviteInput, UserData } from "../../../types";
import { useUserStore } from "../../../store";
import InputSubmit from "../../../components/Reusable/InputSubmit/InputSubmit";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebaseConfig";
import { setNotificationState } from "../../../notification";
import GeneralNotification from "../../../notification/Notifications/GeneralNotification";

interface EarlyAccessInvitesProps {
  userData: UserData | null;
  refreshUserData: Function;
}

const inviteInputs: InviteInput[] = [
  {
    id: 1,
    title: "Invitee #1",
    type: "email",
    isSubmitted: false,
    isSubmitting: false,
    email: "",
  },
  {
    id: 2,
    title: "Invitee #2",
    type: "email",
    isSubmitted: false,
    isSubmitting: false,
    email: "",
  },
  {
    id: 3,
    title: "Invitee #3",
    type: "email",
    isSubmitted: false,
    isSubmitting: false,
    email: "",
    submittedMessage: "Already used",
  },
  {
    id: 4,
    title: "Invitee #4",
    type: "email",
    isSubmitted: false,
    isSubmitting: false,
    email: "",
    submittedMessage: "Already used",
  },
  {
    id: 5,
    title: "Invitee #5",
    type: "email",
    isSubmitted: false,
    isSubmitting: false,
    email: "",
    submittedMessage: "Already used",
  },
];

const EarlyAccessInvites: FC<EarlyAccessInvitesProps> = ({
  userData,
  refreshUserData,
}) => {
  const [invites, setInvites] = useState<InviteInput[]>(inviteInputs);
  const [currentInvitees, setCurrentInvitees] = useState<string[]>([]);
  const ordAddress = useUserStore((state) => state.ordAddress);

  const handleSetEmail = useCallback(
    (value: string, id: number) => {
      const newInvites = invites.map((invite) => {
        if (invite.id === id) {
          return {
            ...invite,
            email: value,
          };
        }
        return invite;
      });
      setInvites(newInvites);
    },
    [invites],
  );

  const updateInviteState = (id: number, newState: Partial<InviteInput>) => {
    setInvites((prev) =>
      prev.map((invite) =>
        invite.id === id ? { ...invite, ...newState } : invite,
      ),
    );
  };

  const submitInvite = useCallback(
    async (e: FormEvent<HTMLFormElement>, id: number) => {
      e.preventDefault();
      updateInviteState(id, { isSubmitting: true });
      try {
        const addInvitee = httpsCallable(functions, "addInvitee");
        const response: any = await addInvitee({
          id: ordAddress,
          inviteeEmail: invites.find((invite) => invite.id === id)?.email,
        });
        if (response.data.success) {
          setNotificationState(() => (
            <GeneralNotification
              text={"User successfully invited."}
              variant="success"
            />
          ));
          updateInviteState(id, { isSubmitting: false, isSubmitted: true });
        } else {
          setNotificationState(() => (
            <GeneralNotification text={response.data.error} variant="error" />
          ));
          updateInviteState(id, { isSubmitting: false });
        }
      } catch (error: any) {
        const errorMessage = error?.message || "Error inviting user.";
        setNotificationState(() => (
          <GeneralNotification text={errorMessage} variant="error" />
        ));
        updateInviteState(id, { isSubmitting: false });
      }
    },
    [invites, ordAddress],
  );

  //Updates current invitees
  useEffect(() => {
    if (userData?.invitees) {
      setCurrentInvitees(userData.invitees);
    }
  }, [userData]);

  useEffect(() => {
    const anySubmitted = invites.some((invite) => invite.isSubmitted);
    if (anySubmitted) {
      refreshUserData();
    }
  }, [invites, refreshUserData]);

  return (
    <div className="gap-3 flex flex-col lg:w-[500px] w-full">
      {invites.map((invite, index) => (
        <InputSubmit
          status={
            currentInvitees[index] || invite.isSubmitted ? "Used" : "Available"
          }
          key={invite.id}
          id={invite.id}
          title={invite.title}
          onEmailSubmit={submitInvite}
          placeholder={"Enter email"}
          type={invite.type}
          isSubmitted={currentInvitees[index]?.length > 0 || invite.isSubmitted}
          isSubmitting={invite.isSubmitting}
          handleSetInput={handleSetEmail}
          submittedMessage={currentInvitees[index]}
          value="/ss"
        />
      ))}
    </div>
  );
};

export default EarlyAccessInvites;
