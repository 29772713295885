import { FC, useEffect, useRef, useState } from "react";
import btcSymbol from "../../../assets/assets/btcSymbol.svg";
import ValueTag from "../../../components/Reusable/ValueTag/ValueTag";
import useBtcPrice from "../../../hooks/api/useBtcPrice";
import circleImg from "../../../assets/icons/circle-loading.svg";
import { calculateRepaymentInfo, roundToDecimalPlaces } from "../../../utils";
import Big from "big.js";

interface AmountsAndInterestProps {
  defaultLoanAmount: number | string;
  onAmountChange: (amount: number) => void;
  bestOfferAmount?: number | string;
  inputtedAmount: number | string;
  interestRate: number;
  term: number;
  disableInput?: boolean;
  type?: "createOffer" | "reviewAndSign";
  isBRC20?: boolean;
}

const AmountsAndInterest: FC<AmountsAndInterestProps> = ({
  defaultLoanAmount,
  onAmountChange,
  bestOfferAmount,
  inputtedAmount,
  interestRate,
  term,
  disableInput,
  type,
  isBRC20,
}) => {
  const inputRefs = [useRef<HTMLInputElement>(null)];
  const { btcPrice, isLoading } = useBtcPrice();
  const [totalInterestAmount, setTotalInterestAmount] = useState<number>(0);
  const [inputValue, setInputValue] = useState<string>();

  const isBtcPriceLoading = isLoading;

  // When card is selected it will select the input field
  const handleDivClick = (index: number) => {
    inputRefs[index].current?.focus();
  };

  const restoreDefaultValueIfEmpty = (
    e: React.FocusEvent<HTMLInputElement>,
    defaultValue: number | string,
  ) => {
    if (e.target.value === "") {
      e.target.value = defaultValue.toString();
      onAmountChange(Number(defaultValue));
    }
  };

  const priceTag = (amount: number | string) => {
    return (
      <>
        {isBtcPriceLoading ? (
          <img src={circleImg} className="h-6" alt="loading" />
        ) : (
          btcPrice && (
            <ValueTag
              value={`$${
                parseFloat(amount.toString()) > 0
                  ? (parseFloat(amount.toString()) * btcPrice).toFixed(2)
                  : 0
              } USD`}
              leftDotColor="green"
            />
          )
        )}
      </>
    );
  };

  const bestOffer = () => {
    return (
      <p className="text-xs sm:text-sm font-semibold text-gray-300 text-center md:text-left flex items-center">
        Best:{" "}
        <img
          src={btcSymbol}
          alt="BTC Symbol"
          className="ml-1 h-4 mt-[1px] mr-[2px] sm:h-5"
        />
        {bestOfferAmount ? bestOfferAmount.toString() : "0"}
      </p>
    );
  };

  // clean up number input
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    val = val.replace(",", ".");
    val = val.replace(/^0+([0-9]+)/, "$1");

    if (parseFloat(val) < 0) return;
    setInputValue(val);
    if (parseFloat(val)) {
      onAmountChange(parseFloat(val));
    } else {
      onAmountChange(0);
    }
  };

  // Calculate total interest amount
  useEffect(() => {
    if (!inputtedAmount) {
      setTotalInterestAmount(0);
      return;
    }
    if ((!disableInput && !inputtedAmount) || !interestRate || !term) return;
    const { totalInterestAmount } = calculateRepaymentInfo(
      disableInput ? +defaultLoanAmount.toString() : inputtedAmount,
      interestRate,
      term,
    );
    setTotalInterestAmount(Big(totalInterestAmount).mul(0.8).toNumber());
  }, [inputtedAmount, interestRate, term, disableInput, defaultLoanAmount]);

  // Set default value if input is disabled
  useEffect(() => {
    if (disableInput) setInputValue(defaultLoanAmount.toString());
  }, [disableInput, defaultLoanAmount]);

  return (
    <div
      className={`w-full grid ${isBRC20 ? "grid-col-1" : "grid-cols-2"} gap-3`}
    >
      {/* Loan Amount Input */}
      {isBRC20 ? (
        <></>
      ) : (
        <div
          onClick={() => handleDivClick(0)}
          className="bg-gray-600 rounded-xl border-2 border-gray-500 focus-within:border-gray-100 flex flex-col justify-between px-3 py-2 gap-1 hover:cursor-pointer"
        >
          <div className="flex justify-between items-center">
            <p className="text-xs sm:text-sm font-semibold text-gray-300">
              {type === "createOffer" && "Enter "}
              Offer Amount
            </p>
            <div className="md:block hidden">{bestOffer()}</div>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex justify-start items-center -ml-1">
              <img
                src={btcSymbol}
                className="h-5 w-5 mt-[1px] sm:h-5 sm:w-5 "
                alt=""
              />
              <input
                min="0"
                type="number"
                disabled={disableInput}
                ref={inputRefs[0]}
                placeholder={defaultLoanAmount.toString()}
                onBlur={(e) => restoreDefaultValueIfEmpty(e, defaultLoanAmount)}
                value={inputValue || ""}
                onChange={handleInputChange}
                className={
                  "focus:border-none bg-gray-600 focus:outline-none font-extrabold text-sm sm:text-base w-full max-w-[70px] min-w-none text-gray-50 placeholder:text-gray-400/40"
                }
              />
            </div>
            <div className="md:block hidden">{priceTag(inputtedAmount)}</div>
          </div>
          <div className="md:hidden block pt-1">{priceTag(inputtedAmount)}</div>
          <div className="md:hidden block pt-1">{bestOffer()}</div>
        </div>
      )}
      {/* Total Interest */}
      <div className="border border-white/20 rounded-xl  flex flex-col justify-center px-3 py-2 gap-1">
        <p className="text-xs sm:text-sm font-semibold text-gray-300">
          Total Interest Amount
        </p>
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center -ml-1 -mb-1 h-[25px]">
            <img src={btcSymbol} className="h-5 w-5 " alt="" />
            <p className=" text-gray-50 font-extrabold text-sm sm:text-base mb-[1px]">
              {roundToDecimalPlaces(totalInterestAmount, 7)}
            </p>
          </div>
          <div className="md:block hidden">{priceTag(totalInterestAmount)}</div>
        </div>
        <div className="md:hidden block pt-1">
          {priceTag(totalInterestAmount)}
        </div>
      </div>
    </div>
  );
};

export default AmountsAndInterest;
